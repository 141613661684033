import React, { useContext, useState } from "react";
import Appbar from "./Appbar";
import Sidebar from "./Sidebar";
import { AuthContext } from "contexts/AuthContext";
import { Container, MainContainer } from "./styles";

export const LayoutContext = React.createContext({
  isOpenMenu: false,
  toogleIsOpenMenu: () => {},
});

export function useLayoutContext() {
  return useContext(LayoutContext);
}

function Layout({ children }: any) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
 const { state } = useContext(AuthContext);
 if (!state.user)  { return <>Carregando dados do usuário</> }
  return (
    <LayoutContext.Provider
      value={{
        isOpenMenu,
        toogleIsOpenMenu: () => setIsOpenMenu((prev) => !prev),
      }}
    >
      <MainContainer>
        <Sidebar />

        <Appbar />

        <Container>{children}</Container>
      </MainContainer>
    </LayoutContext.Provider>
  );
}

export default Layout;
