import React, { useEffect, useState } from "react";
import ContentScroll from "components/ContentScroll";
import http from "infra/axios";
import { Flex } from "@chakra-ui/react";

let _isLoading = false;

function TabModulos({ value, onChange, cursos, active }: any) {
  const [isLoading, setIsLoading] = useState<any>(false);
  const [data, setData] = useState<any[]>([]);
  const [indexOpen, setIndexOpen] = useState<any>(0);

  useEffect(() => {
    (async () => {
      try {
        setData([]);

        if (!cursos?.length || _isLoading || !active) {
          return;
        }

        _isLoading = true;

        setIsLoading(true);

        const { data: result } = await http.get("api/curso/modulos-cursos", {
          params: {
            cursos: cursos.map((item: any) => item.id),
          },
        });

        setData(result.data);
      } catch (error) {
      } finally {
        _isLoading = false;
        setIsLoading(false);
      }
    })();
  }, [active, cursos]);

  const getModulos = (curso: any) => {
    if (!data?.length) {
      return [];
    }

    const result = data.filter((item) => item.curso_id === curso.id);

    return result;
  };

  if (!active) {
    return null;
  }

  console.log('cursos', cursos);

  return (
    <>
      <Flex fontSize="18px" gridGap={'6px'} color="gray.600">
        Selecione os <strong>Módulos</strong> em que a questão seja inserida
      </Flex>

      <br />

      {cursos?.map((item: any, index: number) => (
        <div style={{ marginBottom: 16 }}>
          <ContentScroll
            isLoading={isLoading}
            title={`(${item.tipo}) ${item.nome}`}
            openTitle={index === indexOpen}
            onCloseTitle={() => {
              setIndexOpen((prev: any) => (prev === index ? null : index));
            }}
            data={getModulos(item)}
            value={value}
            onChange={(v: any) => {
              console.log('on change', v);  
              onChange(v);
            }}
            countCheckeds={
              value.filter(
                (modulo: any) => modulo.curso_id === item.id
              ).length
            }
          />
        </div>
      ))}
    </>
  );
}

export default TabModulos;
