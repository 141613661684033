import styled from "styled-components";

export const Container = styled.div`
  height: 15px;
  width: 15px;
  svg {
    max-width: 15px;
    max-height: 15px;
  }
  display: inline-block;
  margin-right: 12px;
`;
