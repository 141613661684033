import colors from "infra/resources/theme/colors";

function EmptyList() {
  return (
    <div
      style={{
        backgroundColor: colors.black100,
        borderRadius: 4,
        padding: 32,
        textAlign: "center",
      }}
    >
      <span style={{ fontSize: 16 }}>
        Nenhuma Questão foi adicionado
      </span>

    </div>
  );
}

export default EmptyList;
