import React from "react";
import SelectBase from "../SelectBase";

const items: any[] = [
  {
    value: 1,
    label: "Acre",
  },
  {
    value: 2,
    label: "Alagoas",
  },
  {
    value: 3,
    label: "Amazonas",
  },
  {
    value: 4,
    label: "Amapá",
  },
  {
    value: 5,
    label: "Bahia",
  },
  {
    value: 6,
    label: "Ceará",
  },
  {
    value: 7,
    label: "Distrito Federal",
  },
  {
    value: 8,
    label: "Espírito Santo",
  },
  {
    value: 9,
    label: "Goiás",
  },
  {
    value: 10,
    label: "Maranhão",
  },
  {
    value: 11,
    label: "Minas Gerais",
  },
  {
    value: 12,
    label: "Mato Grosso do Sul",
  },
  {
    value: 13,
    label: "Mato Grosso",
  },
  {
    value: 14,
    label: "Pará",
  },
  {
    value: 15,
    label: "Paraíba",
  },
  {
    value: 16,
    label: "Pernambuco",
  },
  {
    value: 17,
    label: "Piauí",
  },
  {
    value: 18,
    label: "Paraná",
  },
  {
    value: 19,
    label: "Rio de Janeiro",
  },
  {
    value: 20,
    label: "Rio Grande do Norte",
  },
  {
    value: 21,
    label: "Rondônia",
  },
  {
    value: 22,
    label: "Roraima",
  },
  {
    value: 23,
    label: "Rio Grande do Sul",
  },
  {
    value: 24,
    label: "Santa Catarina",
  },
  {
    value: 25,
    label: "Sergipe",
  },
  {
    value: 26,
    label: "São Paulo",
  },
  {
    value: 27,
    label: "Tocantins",
  },
];

function SelectEstado({ ...props }) {
  const value: any = props.value
    ? items.find((x) => x.value === props.value)
    : null;

  return (
    <SelectBase
      {...props}
      options={items}
      value={value}
      isMulti={false}
      beforeChange={(v: any) => v.value}
    />
  );
}

export default SelectEstado;
