import { useCallback, useEffect, useState } from "react";
import axios from "infra/axios";
import { showError } from "infra/alerta";

export default function useCategorias() {
  const [data, setData] = useState<any>(null);
  const [params, setParams] = useState<any>({
    page: 1,
    per_page: 15,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [isLoadingExcluir, setIsLoadingExcluir] = useState(false);

  const handleSave = useCallback(async (values) => {
    try {
      setIsLoadingForm(true);

      if (values.id) {
        await axios.put(`api/categoria/${values.id}`, values);

        setParams((prev: any) => ({ ...prev, updatedAt: +new Date() }));
      } else {
        await axios.post(`api/categoria`, values);
        setParams((prev: any) => ({ ...prev, page: 1 }));
      }
      setIsLoadingForm(false);

      return true;
    } catch (error) {
      setIsLoadingForm(false);
      showError();
      return false;
    }
  }, []);

  const handleExcluir = useCallback(async (id) => {
    try {
      setIsLoadingExcluir(true);

      await axios.delete(`api/categoria/${id}`);

      setParams((prev: any) => ({ ...prev, page: 1, updatedAt: +new Date() }));

      setIsLoadingExcluir(false);

      return true;
    } catch (error) {
      setIsLoadingExcluir(false);
      showError();
      return false;
    }
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data } = await axios.get("/api/categoria", {
          params: params,
        });

        setData(data.data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
      // TODO ...
    })();
  }, [params]);

  return {
    handleSave,
    handleExcluir,
    isLoadingExcluir,
    isLoadingForm,
    isLoading,
    data,
    params,
    setParams,
  };
}
