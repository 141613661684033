import React from "react";
import { Redirect, Route } from "react-router-dom";
import AuthClient from "../../../data/auth/authClient";

const AuthRoute: React.FC<any> = ({ ...props }) => {
  if (!AuthClient.isLogged()) {
    return <Redirect to="/auth/login" />;
  }

  return <Route {...props} />;
};

export default AuthRoute;
