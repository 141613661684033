/* eslint-disable jsx-a11y/iframe-has-title */
import { Upload } from "antd";
import { Button } from "rsuite";
import { useEffect, useRef, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { MdFileUpload } from "react-icons/md";
import { Flex } from "@chakra-ui/react";

import http from "infra/axios";
import { showError } from "infra/alerta";
import { BASE_URL_API } from "infra/config";
import colors from "infra/resources/theme/colors";
import { Container } from "./styles";
import AuthClient from "data/auth/authClient";

function InputUploadProfile({ value, onChange }: any) {
  const ref = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    setImageUrl(value);
  }, [value]);

  const beforeUpload = (file: any) => {
    const isJpgOrPngOrGif =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";

    if (!isJpgOrPngOrGif) {
      showError("Selecione uma imagem corretamente!");
    }
    const isLt2M = file.size / 1024 / 1024 <= 10;

    if (!isLt2M) {
      showError("Selecione uma imagem com tamanho inferior a 10MB!");
    }

    return isJpgOrPngOrGif && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const className = "avatar-uploader-" + +new Date();

  return (
    <>
      <Container gap="16px">
        <Flex flex={1}>
          <Upload
            accept="image/*"
            ref={ref}
            name="avatar"
            listType="picture-card"
            className={className}
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={async (action) => {
              try {
                setLoading(true);
                const formData = new FormData();

                formData.append("avatar", action.file);
                const {
                  data: { data },
                } = await http.post(
                  `${BASE_URL_API}/api/arquivo/temp`,
                  formData
                );

                setImageUrl(data.url);

                onChange(data.url);

                return data?.url;
              } catch (err) {
              } finally {
                setLoading(false);
              }
            }}
            headers={{
              authorization: `Bearer ${AuthClient.getAccessToken()}`,
              accept: "application/json",
            }}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Flex>

        <Flex mb={1} gap="4px" justifyContent={"flex-end"} flexDir="column">
          <Flex>
            <Button
              appearance="ghost"
              loading={loading}
              disabled={loading}
              style={{
                color: colors.black700,
                gap: "8px",
                alignItems: "center",
                display: "flex",
                fontSize: "12px",
                borderColor: colors.black700,
              }}
              onClick={() => {
                const element: any = document
                  .querySelector(`.${className}`)
                  ?.querySelector("input[type=file]");

                if (element !== null) {
                  element.click();
                }
              }}
            >
              <MdFileUpload color={colors.black700} />
              ESCOLHER ARQUIVO
            </Button>
          </Flex>

          <Flex style={{ color: "#888", fontSize: "11px" }}>
            {!imageUrl ? "Nenhum arquivo selecionado" : null}
          </Flex>
        </Flex>
      </Container>
    </>
  );
}

export default InputUploadProfile;
