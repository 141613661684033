import Select, { Props } from "react-select";
import chroma from "chroma-js";

import { Container } from "./styles";

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: "white" }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    if (!data?.color) {
      return {
        ...styles,
      };
    }
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: (styles: any, { data }: any) => {
    if (!data?.color) {
      return {
        ...styles,
      };
    }

    return { ...styles, ...dot() };
  },
  placeholder: (styles: any, { data }: any) => {
    if (!data?.color) {
      return {
        ...styles,
      };
    }
    return { ...styles, ...dot() };
  },
  singleValue: (styles: any, { data }: any) => {
    if (!data?.color) {
      return {
        ...styles,
      };
    }

    return {
      ...styles,
      ...dot(data.color),
    };
  },
};

function SelectBase({ isMulti, ...props }: Props) {
  let value: any = undefined;

  if (props.value) {
    if (isMulti) {
      value = props.value;
    } else {
      if (typeof props.value !== "object") {
        value = (props.options as any[]).find(
          (item: any) => item.value === props.value
        );
      } else {
        value = props.value;
      }
    }
  }

  const handleChange = (v: any) => {
    if (typeof props.onChange === "function") {
      let _v: any = v;

      if (typeof props.beforeChange === "function" && v) {
        _v = props.beforeChange(v);
      }

      if (isMulti) {
        props.onChange(_v, v);
      } else {
        if (_v?.value !== undefined) {
          props.onChange(_v.value, v);
        } else {
          props.onChange(_v, v);
        }
      }
    }
  };

  return (
    <Container>
      <Select
        className="select-container"
        classNamePrefix="select-prefix"
        isClearable
        placeholder="Todas"
        noOptionsMessage={() => "Nenhum item encontrado"}
        isMulti={isMulti}
        {...props}
        value={value}
        onChange={handleChange}
        styles={colourStyles}
      />
    </Container>
  );
}

export default SelectBase;
