import { useCallback, useContext } from "react";

import { navigate } from "../../infra/router";
import AuthClient from "../../data/auth/authClient";
// import { showError } from "../../infra/alerta";
import { AuthContext } from "contexts/AuthContext";
import { AuthUser } from "infra/resources/types/auth";

export default function useLogin() {
  const { dispatch, state } = useContext(AuthContext);
  const isLoading = state.loading
  const handleLogin = useCallback(
    async ({ username, password }) => {
      await AuthClient.login(username, password)
        .then(async ({ error, success, data }) => {
          dispatch({ type: "@AUTH_IS_LOADING", payload: true });
          !error
            ? 
           await AuthClient.getServerData().then((data) => dispatch({type: "@AUTH_LOGIN", payload: data as AuthUser}))
            : dispatch({ type: "@AUTH_ERROR" });
        })
        .finally(() => {
         !state.error && navigate("/admin");
          dispatch({ type: "@AUTH_IS_LOADING", payload: false });
        });
    },
    [dispatch, state]
  );

  return {
    handleLogin,
    isLoading,
  };
}
