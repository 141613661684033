import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Divider, Form, Steps } from "rsuite";

import PageContainer from "components/Layout/PageContainer";
import PageHeader from "components/Layout/PageHeader";

import TabCursos from "./components/TabCursos";
import TabModulos from "./components/TabModulos";
import TabAssuntos from "./components/TabAssuntos";

import { StepsContainer } from "./styles";
import { useHistory, useParams } from "react-router";
import cloneDeep from "lodash.clonedeep";
import useQuestaoClassificacao from "data/questao/useQuestaoClassificacao";
import TabProblemas from "./components/TabProblemas";

const MAX_STEPS = 4;

function QuestaoFormularioPage() {
  const params = useParams<any>();
  const history = useHistory();
  const { handleSave, data, isLoading } = useQuestaoClassificacao(params.id);

  const { handleSubmit, getValues, watch, control, reset } = useForm();

  useEffect(() => {
    (() => {
      if (data !== null) {
        reset(cloneDeep({ ...(data as any) }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [step, setStep] = React.useState(0);

  const onChange = (nextStep: number) => {
    setStep(nextStep < 0 ? 0 : nextStep > MAX_STEPS ? MAX_STEPS : nextStep);
  };

  const onNext = useCallback(() => {
    if (step === MAX_STEPS - 1) {
      handleSave(getValues());
      return;
    }

    onChange(step + 1);
  }, [getValues, handleSave, step]);

  const onPrevious = () => onChange(step - 1);

  const handleVoltar = () => {
    history.push({
      pathname: "/admin/administracao/questoes",
      search: window.location.search,
    });
  };

  return (
    <div>
      <Form onSubmit={(_v, ev: any) => handleSubmit(handleSave)(ev)}>
        <PageHeader>
          <PageHeader.Left
            title="Questão"
            subtitle="Classificação da questão"
            icon={<i className="mdi mdi-forum" />}
          />
        </PageHeader>

        <PageContainer>
          <h1 style={{ fontSize: "2.5em", fontWeight: "normal" }}>
            {data?.titulo ?? "---"}
          </h1>

          <br />
          <br />

          <Divider />
          <br />

          <StepsContainer>
            <Steps current={step}>
              <Steps.Item title="Cursos" />
              <Steps.Item title="Módulos" />
              <Steps.Item title="Assuntos" />
              <Steps.Item title="Problema" />
            </Steps>

            <div style={{ marginTop: 48 }}>
              <Controller
                as={TabCursos}
                active={step === 0}
                control={control}
                name="cursos"
              />

              <Controller
                as={TabModulos}
                active={step === 1}
                cursos={watch("cursos")}
                control={control}
                name="modulos"
              />

              <Controller
                as={TabAssuntos}
                control={control}
                active={step === 2}
                cursos={watch("cursos")}
                modulos={watch("modulos")}
                name="assuntos"
              />

              <Controller
                as={TabProblemas}
                control={control}
                active={step === 3}
                name="problemas"
              />
            </div>

            <Divider />

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <Button
                  appearance="ghost"
                  componentClass="a"
                  onClick={() => handleVoltar()}
                >
                  CANCELAR
                </Button>
              </div>

              <div style={{ display: "flex" }}>
                <Button
                  componentClass="a"
                  onClick={onPrevious}
                  style={{ marginRight: 16 }}
                  loading={isLoading}
                  disabled={step === 0 || isLoading}
                >
                  {"< VOLTAR"}
                </Button>
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  color="blue"
                  onClick={onNext}
                >
                  {step < MAX_STEPS - 1 ? "AVANÇAR >" : "FINALIZAR"}
                </Button>
              </div>
            </div>
          </StepsContainer>
        </PageContainer>
      </Form>
    </div>
  );
}

export default QuestaoFormularioPage;
