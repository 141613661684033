import { useEffect, useMemo } from "react";
import { Alert } from "antd";
import { Button, Form } from "rsuite";
import cloneDeep from "lodash.clonedeep";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import PageHeader from "ui/components/Layout/PageHeader";
import PageContainer from "ui/components/Layout/PageContainer";

import useTurma from "data/turma/useTurma";

import InformacoesBasicas from "./components/InformacoesBasicas";
import CalendarioAula from "./components/CalendarioAula";
import { AgendaForm } from "data/turma/typings";

function TurmaFormularioPage() {
  const history = useHistory();
  const params = useParams<any>();

  const { handleSave, data, isLoading } = useTurma(params.id);

  const { control, handleSubmit, reset, watch, setValue, errors } =
    useForm<AgendaForm>({
      defaultValues: {
        calendarios: [
          {
            dia: 0,
            horarios: [{}],
          },
          {
            dia: 1,
            horarios: [{}],
          },
          {
            dia: 2,
            horarios: [{}],
          },
          {
            dia: 3,
            horarios: [{}],
          },
          {
            dia: 4,
            horarios: [{}],
          },
          {
            dia: 5,
            horarios: [{}],
          },
          {
            dia: 6,
            horarios: [{}],
          },
        ],
      },
    });

  useEffect(() => {
    (() => {
      if (data) {
        reset(cloneDeep({ ...data }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const listErrors = useMemo(() => {
    const response = [];

    for (const prop in errors) {
      response.push((errors as any)[prop].message);
    }

    return response;
  }, [errors]);

  const form = watch();

  console.log('form', form);

  return (
    <div>
      <Form onSubmit={(_v, ev: any) => handleSubmit(handleSave)(ev)}>
        <PageHeader>
          <PageHeader.Left
            title="Turmas"
            subtitle={`${params.id ? "Editar" : "Adicionar Nova"} Turma`}
            icon={<i className="mdi mdi-school" />}
          />
          <PageHeader.Right>
            <div>
              <Button
                color="blue"
                disabled={isLoading}
                appearance="ghost"
                componentClass="a"
                style={{ marginRight: 16 }}
                onClick={() => history.push("/admin/entidade/turmas")}
              >
                CANCELAR
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                loading={isLoading}
                color="blue"
              >
                SALVAR
              </Button>
            </div>
          </PageHeader.Right>
        </PageHeader>

        {listErrors.length ? (
          <Alert
            message="Avisos"
            description={
              <div>
                {listErrors.map((error) => (
                  <div>{error}</div>
                ))}
              </div>
            }
            type="error"
            closable
          />
        ) : null}

        <PageContainer style={{ paddingTop: 16 }}>
          <InformacoesBasicas
            watch={watch}
            control={control}
            setValue={setValue}
          />

          <br />
          <hr />
          <br />

          <CalendarioAula control={control} />
        </PageContainer>
      </Form>
    </div>
  );
}

export default TurmaFormularioPage;
