import { useEffect, useState } from "react";
import http from "infra/axios";
import SelectBaseAntd from "../SelectBaseAntd";

function SelectTipoArquivo({ ...props }) {
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const { data } = await http.get("api/arquivo/tipos");

        setItems(
          data.data.map((item: any) => ({
            label: item.nome,
            value: Number(item.id),
          }))
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return <SelectBaseAntd {...props} options={items} loading={isLoading} />;
}

export default SelectTipoArquivo;
