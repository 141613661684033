import { Select, SelectProps } from "antd";

import { Container } from "./styles";

const { Option } = Select;

function SelectBaseAntd({ ...props }: SelectProps<any>) {
  const options = props.options?.map((d: any) => (
    <Option value={d.value} key={d.value}>
      {d.label}
    </Option>
  ));

  return (
    <Container>
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        showSearch
        allowClear
        filterOption={(input, option: any) => {
          return option.label?.toLowerCase()?.includes(input.toLowerCase());
        }}
        {...props}
      >
        {options}
      </Select>
    </Container>
  );
}

export default SelectBaseAntd;
