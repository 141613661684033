import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  ControlLabel,
  Divider,
  Form,
  FormGroup,
  Modal,
  Radio,
  RadioGroup,
  Row,
} from "rsuite";
import SelectCurso from "ui/components/Input/SelectCurso";
import SelectModulos from "ui/components/Input/SelectModulos";
import SelectModulosAssuntos from "ui/components/Input/SelectModulosAssuntos";

function ModalFiltros({
  open,
  onClose,
  onFilter,
  value,
}: {
  open: boolean;
  onClose: Function;
  onFilter: Function;
  value: any;
}) {
  const [form, setForm] = useState<any>({
    cursos: [],
    modulos: [],
    assuntos: [],
  });

  useEffect(() => {
    setForm(value);
  }, [value]);

  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header onHide={() => onClose()}>
        <Modal.Title>Refinar</Modal.Title>
      </Modal.Header>

      <Divider />

      <Modal.Body>
        <Form fluid style={{ overflowX: "hidden" }}>
          <strong>FILTROS</strong>

          <div style={{ marginTop: 16 }}>
            <FormGroup>
              <ControlLabel>Classificação</ControlLabel>
              <CheckboxGroup
                style={{ marginLeft: -18 }}
                inline
                value={form.situacao}
                onChange={(value) =>
                  setForm((prev: any) => ({ ...prev, situacao: value }))
                }
              >
                <Row>
                  <Col sm={8}>
                    <Checkbox value="Sem Classificação">
                      Sem Classificação
                    </Checkbox>
                  </Col>
                  <Col sm={8}>
                    <Checkbox value="Incompleta">Incompleta</Checkbox>
                  </Col>
                  <Col sm={8}>
                    <Checkbox value="Classificada">Classificada</Checkbox>
                  </Col>
                </Row>
              </CheckboxGroup>
            </FormGroup>

            <Row gutter={16}>
              <Col sm={24} style={{ marginBottom: 16 }}>
                <FormGroup>
                  <ControlLabel>Curso</ControlLabel>

                  <SelectCurso
                    placeholder="Todos"
                    value={form.cursos}
                    onChange={(value: any) =>
                      setForm((prev: any) => ({ ...prev, cursos: value }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} style={{ marginBottom: 16 }}>
                <FormGroup>
                  <ControlLabel>Módulos</ControlLabel>

                  <SelectModulos
                    placeholder="Todos"
                    value={form.modulos}
                    isMulti
                    cursos={form.cursos}
                    onChange={(value: any) =>
                      setForm((prev: any) => ({ ...prev, modulos: value }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} style={{ marginBottom: 16 }}>
                <FormGroup>
                  <ControlLabel>Assunto</ControlLabel>

                  <SelectModulosAssuntos
                    placeholder="Todos"
                    value={form.assuntos}
                    isMulti
                    modulos={form.modulos}
                    onChange={(value: any) =>
                      setForm((prev: any) => ({ ...prev, assuntos: value }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <ControlLabel>Situação</ControlLabel>
              <RadioGroup
                style={{ marginLeft: -18 }}
                inline
                value={form.ativo}
                onChange={(value) =>
                  setForm((prev: any) => ({ ...prev, ativo: value }))
                }
              >
                <Row>
                  <Col sm={8}>
                    <Radio value="Todas">Todas</Radio>
                  </Col>
                  <Col sm={8}>
                    <Radio value="1">Ativo</Radio>
                  </Col>
                  <Col sm={8}>
                    <Radio value="0">Inativo</Radio>
                  </Col>
                </Row>
              </RadioGroup>
            </FormGroup>

          </div>
        </Form>
      </Modal.Body>
      <Divider />
      <Modal.Footer>
        <Button onClick={() => onClose()} appearance="ghost">
          CANCELAR
        </Button>
        <Button onClick={() => onFilter(form)} appearance="primary">
          APLICAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFiltros;
