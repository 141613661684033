import { Dropdown, Icon } from "rsuite";
import { SortableHandle, SortableElement } from "react-sortable-hoc";
import colors from "infra/resources/theme/colors";
import { Container, ContainerDragHandle, ContainerContent } from "./styles";
import { useState } from "react";
import { Flex } from "@chakra-ui/react";

const DragHandle = SortableHandle(() => (
  <span style={{ cursor: "move" }}>
    <Icon icon="bars" style={{ fontSize: 12, color: "white" }} />
  </span>
));

const SortableElementItem = SortableElement(({ ...props }: any) => {
  return <SortableItem {...props} />;
});

export interface SortableItemProps {
  id?: string;
  index: number;
  title?: string;
  subtitle?: string;
  menus?: { title: string; onClick: any; icon?: any }[];
  onClick?: any;
  selected?: boolean;
  render?: any;
}

export function SortableItem({
  title,
  subtitle,
  menus,
  selected,
  render,
}: SortableItemProps) {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <Container>
      <ContainerDragHandle selected={selected}>
        <DragHandle />
      </ContainerDragHandle>

      <ContainerContent selected={selected}>
        {title ? (
          <div
            style={{
              fontWeight: subtitle ? "normal" : "bold",
              fontSize: subtitle ? 11 : 16,
              color: selected
                ? colors.white
                : subtitle
                ? colors.black600
                : colors.black700,
            }}
          >
            {title}
          </div>
        ) : null}
        {subtitle ? (
          <div
            style={{
              fontSize: "1.2em",
              color: selected ? colors.white : "inherit",
            }}
          >
            {subtitle}
          </div>
        ) : null}

        {render}
      </ContainerContent>

      {menus?.length ? (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Dropdown
            menuStyle={{
              backgroundColor: "#56626E",
              padding: 8,
            }}
            open={openMenu}
            onClose={() => setOpenMenu(false)}
            renderTitle={() => (
              <div
                onClick={(ev) => {
                  ev.stopPropagation();
                  setOpenMenu(true);
                }}
                style={{
                  padding: "8px 8px",
                  borderRadius: 4,
                  textAlign: "center",
                  backgroundColor: colors.black150,
                }}
              >
                <Icon
                  icon="ellipsis-h"
                  style={{ color: colors.black500, fontSize: 16 }}
                />
              </div>
            )}
          >
            {menus.map((menu) => (
              <Dropdown.Item
                onSelect={(_, ev) => {
                  ev.stopPropagation();
                  menu.onClick(menu);
                }}
              >
                <Flex flex={1} gap="8px">
                  <Flex alignItems={"center"} color="white">
                    {menu.icon}
                  </Flex>
                  <Flex alignItems={"center"} flex={1}>
                    <span style={{ color: "white" }}>{menu.title}</span>
                  </Flex>
                </Flex>
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
      ) : null}
    </Container>
  );
}

export default SortableElementItem;
