import React, { useEffect, useState } from "react";
import SelectBase from "../SelectBase";
import http from "infra/axios";

let oldText: any = null;

function SelectCategoria({ ...props }) {
  const [search, setSearch] = useState<string>();
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (oldText === search) {
          return;
        }

        oldText = search;

        setIsLoading(true);

        const { data } = await http.get("api/categoria", {
          params: {
            nome: search,
            per_page: 100,
          },
        });

        setItems(
          data.data.data.map((item: any) => ({
            label: item.nome,
            value: Number(item.id),
          }))
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [search]);

  const value = props.value
    ? props.value?.map((item: any) => ({
        label: item.nome,
        value: Number(item.id),
      }))
    : [];

  return (
    <SelectBase
      {...props}
      value={value}
      options={items}
      inputValue={search}
      isLoading={isLoading}
      onInputChange={(input: string) => setSearch(input)}
      beforeChange={(v: any) => {
        if (v?.map) {
          return v?.map((item: any) => ({ id: item.value, nome: item.label }));
        }

        if (!v) {
          return null;
        }

        return { id: v.value, nome: v.label };
      }}
    />
  );
}

export default SelectCategoria;
