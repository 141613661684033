import React, { useCallback } from "react";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Button } from "rsuite";
import arrayMove from "array-move";

import colors from "../../../../../../../../infra/resources/theme/colors";

import QuestaoItem from "./QuestaoItem";
import { showConfirm } from "../../../../../../../../infra/alerta";

const SortableContainerComponent = SortableContainer(({ children }: any) => {
  return <ul style={{ padding: 0 }}>{children}</ul>;
});

const SortableItem = SortableElement(
  ({ value, i, onChangePergunta, onRemovePergunta, onChangeResposta }: any) => {
    return (
      <QuestaoItem
        value={value}
        index={i}
        onChangeResposta={onChangeResposta}
        onChangePergunta={onChangePergunta}
        onRemovePergunta={onRemovePergunta}
      />
    );
  }
);

function QuestaoOpcoes({ value, onChange }: any) {
  const onRemovePergunta = (i: any) => {
    showConfirm("Deseja realmente excluir a resposta ?").then(
      ({ isConfirmed }) => {
        if (isConfirmed) {
          const items = [
            ...value.filter((_item: any, index: number) => index !== i),
          ];

          onChange(items);
        }
      }
    );
  };

  const onChangePergunta = useCallback(
    (index: number, prop: string, v: any) => {
      const newArray = value || [];

      newArray[index][prop] = v;

      onChange([...newArray]);
    },
    [onChange, value]
  );

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    value = arrayMove(value, oldIndex, newIndex);

    value.forEach((_item: any, i: number) => {
      value[i].number = i + 1;
    });

    onChange([...value]);
  };

  return (
    <div>
      <h3> Respostas </h3>

      <br />

      <SortableContainerComponent onSortEnd={onSortEnd} useDragHandle>
        {value?.map((item: any, index: number) => (
          <SortableItem
            key={`item-${index}`}
            onChangePergunta={onChangePergunta}
            onRemovePergunta={() => onRemovePergunta(index)}
            onChangeResposta={() => {
              const newItems = value.map((v: any, i: number) => ({
                ...v,
                resposta_correta: i === index,
              }));

              onChange([...newItems]);
            }}
            index={index}
            i={index}
            value={item}
          />
        ))}
      </SortableContainerComponent>

      <div style={{ clear: "both", paddingLeft: 74, paddingRight: 134 }}>
        <Button
          onClick={() => {
            const itemAdd: any = { editando: true };

            if (!value?.length) {
              itemAdd.resposta_correta = true;
            }

            onChange([...(value || []), itemAdd]);
          }}
          block
          style={{ textAlign: "left", paddingLeft: 32 }}
        >
          <i className="mdi mdi-plus-circle" style={{ color: colors.red500 }} />{" "}
          <span style={{ color: colors.red500, paddingLeft: 8 }}>
            Adicionar Resposta
          </span>
        </Button>
      </div>
    </div>
  );
}

export default QuestaoOpcoes;
