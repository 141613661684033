import styled from "styled-components";
import colors from "infra/resources/theme/colors";

export const Container = styled.div`
  flex-direction: row;
  height: 80px;
  display: flex;

  .rs-dropdown-item-content {
    border-radius: 6px;
  }

  .rs-dropdown-item-content:hover {
    background-color: ${colors.black900} !important;
  }
`;

export const ContainerDragHandle = styled.div<any>`
  padding-left: 2px;
  padding-right: 2px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${(props) =>
    props.selected ? colors.primary : colors.black700};
  align-items: center;
  display: flex;
`;

export const ContainerContent = styled.div<any>`
  flex: 1;
  background-color: ${(props) => (props.selected ? colors.blue300 : "#f9f9f9")};
  justify-content: center;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.16);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: ${(props) =>
    typeof props.onClick === "function" ? "pointer" : "default"};
`;
