import styled, { createGlobalStyle } from "styled-components";
import colors from "../../../../infra/resources/theme/colors";

export const GlobalStyled = createGlobalStyle`

  .rs-drawer-backdrop.in {
    opacity: 0.3 !important;
  }
`;

export const ContainerNav = styled.div`
  .rs-nav-item {
    * {
      color: ${colors.carbon};
    }

    .mdi {
      color: ${colors.primary};
      margin-right: 16px;
    }

    &.rs-nav-item-active {
      background-color: ${colors.primary};
      * {
        color: white;
      }
    }

    &.rs-nav-item-disabled {
      margin-top: 20px !important;
      a {
        cursor: default !important;
        color: ${colors.steel};
        font-size: 0.9em;
      }
    }

    svg {
      fill: ${colors.primary};
    }
  }
`;
