import { useState, useMemo } from "react";

import { Tag } from "rsuite";
import { cnpj } from "cpf-cnpj-validator";

import history from "infra/history";
import { showConfirm } from "infra/alerta";
import colors from "infra/resources/theme/colors";
import useEntidadeExcluir from "data/entidade/useEntidadeExcluir";

import Table from "components/Table";
import MenuTable from "components/MenuTable";
import PageHeader from "components/Layout/PageHeader";

import HeaderTable from "./components/HeaderTable";
import ModalFiltros from "./components/ModalFiltros";

import useEntidades from "data/entidade/useEntidades";
import { TENANT_SITUACAO } from "infra/resources/enums/situacao";
import PERMISSOES from "infra/resources/enums/permissoes";
import { AuthUser } from "infra/resources/types/auth";

function getColorTag(situacao: TENANT_SITUACAO): { text: string; bg: string } {
  if (
    situacao === TENANT_SITUACAO.Ativo ||
    situacao === TENANT_SITUACAO.Liberada
  ) {
    return {
      text: colors.success,
      bg: colors.successLight,
    };
  }

  if (situacao === TENANT_SITUACAO.Suspensa) {
    return {
      text: colors.error,
      bg: colors.errorLight,
    };
  }

  return {
    text: colors.warning,
    bg: colors.warningLight,
  };
}

function EntidadeListagemPage() {
  const { isLoading, data, params, setParams } = useEntidades();
  const { isLoading: isLoadingExcluir, handleExcluir } = useEntidadeExcluir();

  const [openFiltro, setOpenFiltro] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "nome",
        Cell: ({ row }: any) => {
          const { original } = row;

          return (
            <div>
              <div
                style={{
                  textTransform: "uppercase",
                  color: "#999",
                  marginBottom: 6,
                }}
              >
                {original.perfil}
              </div>
              {original.nome}
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "cnpj",
        Cell: ({ row }: any) => <div>{cnpj.format(row.original.cnpj)}</div>,
      },
      {
        Header: "",
        accessor: "email",
      },
      {
        Header: "",
        accessor: "uf",
      },
      {
        Header: "",
        accessor: "situacao",
        Cell: ({ row: { values } }) => {
          const color = getColorTag(values.situacao);

          return (
            <Tag
              style={{
                backgroundColor: color.bg,
                color: color.text,
              }}
            >
              {values.situacao ?? "--"}
            </Tag>
          );
        },
      },
      {
        accessor: "id",
        Cell: ({ row: { original } }: any) => {
          return (
            <MenuTable
              actions={[
                {
                  text: "Editar",
                  visible: (user: AuthUser) =>
                    user.permissoes?.includes(PERMISSOES.ESCOLA.ALTERAR),
                  onClick: () =>
                    history.push(
                      `/admin/administracao/entidades/${original.uuid}`
                    ),
                },
                {
                  text: "Excluir",
                  visible: (user: AuthUser) =>
                    user.permissoes?.includes(PERMISSOES.QUESTAO.EXCLUIR),
                  onClick: () => {
                    showConfirm().then(async ({ isConfirmed }) => {
                      if (isConfirmed) {
                        await handleExcluir(original.uuid);

                        setParams((prev: any) => ({
                          ...prev,
                          updatedAt: +new Date(),
                        }));
                      }
                    });
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [handleExcluir, setParams]
  );

  return (
    <div>
      <ModalFiltros
        open={openFiltro}
        onClose={() => setOpenFiltro(false)}
        onFilter={(v: any) => {
          setParams((prev: any) => ({ ...prev, ...v }));
          setOpenFiltro(false);
        }}
      />

      <PageHeader>
        <PageHeader.Left
          title="Entidades"
          icon={<i className="mdi mdi-2x mdi-school" />}
        />
      </PageHeader>

      <div>
        <br />
        <br />
        <Table
          loading={isLoading || isLoadingExcluir}
          header={() => (
            <HeaderTable
              onOpenFilter={() => setOpenFiltro(true)}
              value={params.nome}
              onSearch={(v: string) =>
                setParams((prev: any) => ({
                  ...prev,
                  nome: v,
                  page: 1,
                }))
              }
            />
          )}
          columns={columns}
          data={data?.data ?? []}
          total={data?.total || 0}
          page={params?.page || 0}
          perPage={params?.per_page || 0}
          onChange={(values: any) =>
            setParams((prev: any) => ({
              ...prev,
              page: values.page,
              per_page: values.per_page,
            }))
          }
        />
        <br />
        <br />
      </div>
    </div>
  );
}

export default EntidadeListagemPage;
