import { useContext } from 'react'
import { AuthContext } from "contexts/AuthContext";

export function useCheckPermission() {
  const { state } = useContext(AuthContext);
  const user = state.user

  const checkPermission = (permissoes: any[]) => {
    if (user?.permissoes?.find((item) => permissoes.includes(item))) {
      return true;
    }

    return false;
  };

  return {
    checkPermission,
    user,
  };
}
