import React, { useMemo } from "react";
import ContentScroll from "components/ContentScroll";
import useCursos from "data/curso/useCursos";
import { Flex } from "@chakra-ui/react";

function TabCursos({ value, onChange, active }: any) {
  const { isLoading, data } = useCursos({ per_page: 1000 }, true);

  const items = useMemo(() => {
    if (!data?.data) {
      return [];
    }

    return data.data.map((item: any) => ({
      ...item,
      nome: (
        <>
          <strong>({item.tipo})</strong> {item.nome}
        </>
      ),
    }));
  }, [data]);

  if (!active) {
    return null;
  }

  return (
    <>
      <Flex fontSize="18px" gridGap={'6px'} color="gray.600">
        Selecione os <strong>Cursos</strong> em que a questão seja inserida
      </Flex>

      <br />

      <ContentScroll
        isLoading={isLoading}
        data={items}
        value={value}
        onChange={(v: any) => {
          const values = data.data.filter((d: any) =>
            v.find((v2: any) => v2.id === d.id)
          );
          console.log("values", values);

          onChange(values);
        }}
      />
    </>
  );
}

export default TabCursos;
