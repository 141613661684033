import { Box, Flex, Text } from "@chakra-ui/react";
import { Empty } from "antd";
import { showConfirm, showError } from "infra/alerta";
import http from "infra/axios";
import { BASE_URL_API } from "infra/config";
import colors from "infra/resources/theme/colors";
import { useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import { Button, Col, ControlLabel, FormGroup, Input, Row } from "rsuite";
import SelectTipoArquivo from "ui/components/Input/SelectTipoArquivo";
import { getIconFromType } from "ui/pages/Admin/Administracao/CursosPages/Formulario/components/Modulos/ModalAssunto";

function BoxArquivos({ value, onChange }: any) {
  const [loading, setLoading] = useState<boolean>(false);

  const [formArquivo, setFormArquivo] = useState<{
    tipo_arquivo_id?: string;
    arquivo?: any;
    nome?: string;
  }>({});

  console.log("arquivos value", value);

  const handleRemoveArquivo = async (index: number) => {
    const { isConfirmed } = await showConfirm("Deseja realmente excluir ?");

    if (isConfirmed) {
      const newArquivos = value?.filter((_a: any, i: number) => i !== index);

      console.log("newArquivos", newArquivos);

      onChange([...newArquivos]);
    }
  };

  const handleAddArquivo = async () => {
    if (!formArquivo.nome) {
      return showError("Informe o nome do arquivo");
    }

    if (!formArquivo.tipo_arquivo_id) {
      return showError("Informe o tipo do arquivo");
    }

    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("file", formArquivo.arquivo);
      formData.append("nome", formArquivo.nome!);
      formData.append("tipo_arquivo_id", formArquivo.tipo_arquivo_id!);

      const {
        data: { data },
      } = await http.post(`${BASE_URL_API}/api/arquivo/upload`, formData);

      const arquivos = [
        ...(value || []),
        {
          id: data.id,
          nome: formArquivo.nome,
          caminho: data.url,
          tipo_arquivo_id: formArquivo.tipo_arquivo_id,
        },
      ];

      console.log("new arquivos", arquivos);

      onChange(arquivos);

      setFormArquivo({ nome: "", tipo_arquivo_id: null, arquivo: null } as any);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Row gutter={32}>
        <Col sm={10}>
          <Box
            borderStyle="dashed"
            borderWidth="1px"
            borderColor={colors.black600}
            rounded="4px"
            p="16px"
            pt="0px"
            mt="24px"
          >
            <Text
              bg="white"
              px="6px"
              pos="relative"
              top="-12px"
              d="inline"
              fontSize="lg"
              fontWeight={"bold"}
              color={colors.primary}
            >
              Adicionar Materiais
            </Text>

            <Box>
              <Row style={{ marginBottom: "16px" }}>
                <Col sm={8}>
                  <FormGroup>
                    <ControlLabel>Tipo do arquivo</ControlLabel>
                    <SelectTipoArquivo
                      mode="single"
                      value={formArquivo.tipo_arquivo_id}
                      onChange={(v: string) =>
                        setFormArquivo((prev) => ({
                          ...prev,
                          tipo_arquivo_id: v,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm={16}>
                  <FormGroup>
                    <ControlLabel>Importar arquivo</ControlLabel>
                    <Input
                      type="file"
                      accept="image/*,application/pdf,video/mp4,video/mov"
                      onChange={(_, e: any) =>
                        setFormArquivo((prev) => ({
                          ...prev,
                          arquivo: e.target.files?.[0],
                        }))
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col sm={18}>
                  <FormGroup>
                    <ControlLabel>Nome do arquivo</ControlLabel>
                    <Input
                      value={formArquivo.nome}
                      onChange={(v: string) =>
                        setFormArquivo((prev) => ({
                          ...prev,
                          nome: v,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <Box mt="23px">
                    <Button
                      loading={loading}
                      disabled={!formArquivo.arquivo || loading}
                      onClick={() => handleAddArquivo()}
                      style={{ width: "100%" }}
                      size="sm"
                      color="red"
                    >
                      {!loading ? (
                        <i
                          className="mdi mdi-plus"
                          style={{ color: "white", marginRight: 8 }}
                        />
                      ) : null}
                      Adicionar
                    </Button>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Box>
        </Col>

        <Col sm={14}>
          <Text
            bg="white"
            px="6px"
            pos="relative"
            d="inline"
            top="8px"
            fontSize="lg"
            fontWeight={"bold"}
            color={colors.primary}
          >
            Materiais
          </Text>

          <Flex flexDir="column" mt="8px">
            {value?.map((item: any, index: number) => (
              <Flex key={index} borderBottom="1px solid #eee" py="6px">
                <Flex
                  px="8px"
                  alignItems={"center"}
                  onClick={() => window.open(item.caminho)}
                  cursor="pointer"
                >
                  {getIconFromType(item.tipo_arquivo_id)}
                </Flex>
                <Flex flex={1} alignItems={"center"}>
                  {item.nome}
                </Flex>
                <Flex alignItems={"center"}>
                  <Button
                    size="xs"
                    onClick={() => handleRemoveArquivo(index)}
                    style={{ paddingTop: 6, paddingBottom: 6 }}
                  >
                    <MdOutlineDelete
                      fontSize={"18px"}
                      color={colors.black500}
                    />
                  </Button>
                </Flex>
              </Flex>
            ))}

            {!value?.length ? (
              <Empty description="Nenhum material adicionado" />
            ) : null}
          </Flex>
        </Col>
      </Row>
    </div>
  );
}

export default BoxArquivos;
