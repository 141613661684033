import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox, Col, ControlLabel, FormControl, Row } from "rsuite";
import SelectSituacao from "ui/components/Input/SelectSituacao";
import SelectModalidade from "ui/components/Input/SelectModalidade";
import { Box, Divider, Text } from "@chakra-ui/react";
import InputUploadProfile from "ui/components/Input/InputUploadProfile";
import colors from "infra/resources/theme/colors";
import SelectCurso from "ui/components/Input/SelectCurso";
import SelectCategoriaAntd from "ui/components/Input/SelectCategoriaAntd";

function InformacoesBasicas({ control, isEdit }: any) {
  return (
    <div>
      <Row gutter={16}>
        <Col sm={4}>
          <ControlLabel>ID do Curso</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="ID do Curso"
            control={control}
            name="apelido"
            disabled={isEdit}
          />
        </Col>

        <Col sm={5}>
          <ControlLabel>Modalidade</ControlLabel>
          <Controller
            as={SelectModalidade}
            placeholder="Modalidade"
            control={control}
            name="modalidade_id"
          />
        </Col>

        <Col sm={10}>
          <ControlLabel>Nome do Curso</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Nome do Curso"
            control={control}
            name="nome"
          />
        </Col>

        <Col sm={5}>
          <ControlLabel>Situação</ControlLabel>
          <Controller
            as={SelectSituacao}
            placeholder="Selecione"
            control={control}
            name="situacao"
            rules={{
              required: {
                value: true,
                message: "Informe a situação do curso",
              },
            }}
          />
        </Col>
      </Row>

      <br />

      <Row>
        <Col sm={24}>
          <ControlLabel>Descrição</ControlLabel>
          <Controller
            as={FormControl}
            componentClass="textarea"
            rows={4}
            control={control}
            name="descricao"
            placeholder="Escreva uma descrição para o curso"
          />
        </Col>
      </Row>

      <Divider my={8} />

      <Row gutter={16}>
        <Col sm={10}>
          <Text fontSize="18px" color={colors.black600} mb={2}>
            Imagem do Curso
          </Text>
          <Controller
            control={control}
            name="imagem_url"
            as={<InputUploadProfile />}
          />
        </Col>
        <Col sm={14}>
          <Text fontSize="18px" color={colors.black600} mb={2}>
            Categorias do Curso
          </Text>
          <Controller control={control} name="categorias" as={SelectCategoriaAntd} />
        </Col>
      </Row>

      <Divider my={8} />

      <Box>
        <Text fontSize="18px" color={colors.black600} mb={2}>
          Sincronizar Curso ANAC
        </Text>

        <Row gutter={16}>
          <Col sm={12}>
            <ControlLabel>Código Integração Curso ANAC</ControlLabel>
            <Controller
              control={control}
              name="curso_id"
              mode="single"
              as={SelectCurso}
            />
          </Col>
        </Row>

        <Box mt={2} ml={-2}>
          <Controller
            control={control}
            name="permite_sem_homologacao"
            render={({ value, onChange }) => (
              <Checkbox onChange={() => onChange(!value)} checked={!!value}>
                Permite curso sem homologação da ANAC
              </Checkbox>
            )}
          />
        </Box>
      </Box>

      {/* <Row gutter={16}>
        <Col sm={6}>
          <ControlLabel>Qualificação</ControlLabel>
          <Controller
            as={SelectBase}
            placeholder="Qualificação"
            control={control}
            name="qualificacao"
            options={[
              { label: "EXTRA", value: "EXTRA" },
              { label: "ANAC", value: "ANAC" },
            ]}
          />
        </Col>

        <Col sm={6}>
          <ControlLabel>Tipo</ControlLabel>
          <Controller
            as={SelectBase}
            placeholder="Tipo"
            control={control}
            name="tipo"
            options={[
              { label: "Teórico", value: "Teórico" },
              { label: "Prático", value: "Prático" },
              { label: "Teórico/Prático", value: "Teórico/Prático" },
            ]}
          />
        </Col>
      </Row> */}

      {/* <br />

      <br /> */}

      {/* <Row>
        <Col sm={24}>
          <ControlLabel>Categoria</ControlLabel>
          <Controller
            as={SelectCategoriaAntd}
            isMulti
            placeholder="Categoria"
            control={control}
            name="categorias"
          />
        </Col>
      </Row> */}
    </div>
  );
}

export default InformacoesBasicas;
