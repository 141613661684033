import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, ControlLabel, FormControl, Row, Form, Button } from "rsuite";

import useLogin from "../../../../data/auth/useLogin";
import logo from "../../../../assets/images/logo.svg";
import { Container, ContainerClean, ContainerLogin, CardLogin } from "./styles";
import colors from "../../../../infra/resources/theme/colors";

function LoginPage() {
  const { control, handleSubmit, errors } = useForm<{
    username: any;
    password: any;
  }>({
    reValidateMode: "onChange",
  });

  const { isLoading, handleLogin } = useLogin();

  return (
    <Container>
      <ContainerClean>
        <img src={logo} alt="logo" />
      </ContainerClean>

      <ContainerLogin style={{ display: "flex", flex: 1 }}>
        <CardLogin>
          <Form onSubmit={(_v, ev: any) => handleSubmit(handleLogin)(ev)}>
            <h2>Seja bem vindo ao Piloto</h2>
            <p>
              Não tem conta?{" "}
              <span
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: colors.primary,
                }}
              >
                Crie uma conta agora
              </span>
            </p>
            <br />
            <Row gutter={16}>
              <Col sm={24}>
                <ControlLabel>Email</ControlLabel>
                <Controller
                  as={FormControl}
                  placeholder="Email"
                  control={control}
                  name="username"
                  rules={{
                    required: {
                      message: "Informe o e-mail",
                      value: true,
                    },
                  }}
                  errorMessage={errors.username?.message}
                />
              </Col>
            </Row>

            <br />

            <Row gutter={16}>
              <Col sm={24}>
                <ControlLabel>Senha</ControlLabel>
                <Controller
                  as={FormControl}
                  placeholder="Senha"
                  control={control}
                  type="password"
                  name="password"
                  rules={{
                    required: {
                      message: "Informe a senha",
                      value: true,
                    },
                  }}
                  errorMessage={errors.password?.message}
                />
              </Col>
            </Row>

            <br />

            <Row gutter={16}>
              <Col sm={24}>
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  type="submit"
                  color="blue"
                  block
                >
                  Entrar
                </Button>
              </Col>
            </Row>
          </Form>
        </CardLogin>
      </ContainerLogin>
    </Container>
  );
}

export default LoginPage;
