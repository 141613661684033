import React from "react";

import { SortableHandle } from "react-sortable-hoc";
import { Button, Input, Radio, Icon } from "rsuite";

import colors from "infra/resources/theme/colors";

import { ListItem, ListItemCol, ListItemInner } from "./styles";

const DragHandle = SortableHandle(() => (
  <span>
    <Icon icon="bars" style={{ fontSize: 12 }} />
  </span>
));

function QuestaoItem({
  value,
  index,
  onChangePergunta,
  onRemovePergunta,
  onChangeResposta,
}: any) {
  return (
    <ListItem>
      <ListItemCol>
        <Radio
          onChange={() => {
            onChangeResposta();
          }}
          checked={!!value.resposta_correta}
        />
      </ListItemCol>

      <ListItemCol
        style={{
          color: colors.black500,
          paddingLeft: 8,
          paddingRight: 0,
          width: 20,
        }}
      >
        {index < 9 ? "0" + (index + 1) : index + 1}
      </ListItemCol>

      <ListItemCol
        style={{
          backgroundColor: "#56636e",
          color: "white",
          paddingLeft: 3,
          paddingRight: 3,
          right: -17,
          position: "relative",
          zIndex: 1,
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
          cursor: "move",
        }}
      >
        <DragHandle />
      </ListItemCol>

      <ListItemInner>
        {value.editando ? (
          <Input
            value={value.titulo}
            onChange={(v) => onChangePergunta(index, "titulo", v)}
          />
        ) : (
          <div style={{ paddingTop: 4, fontWeight: "bold", color: "#56636e" }}>
            {" "}
            {value.titulo}{" "}
          </div>
        )}
      </ListItemInner>

      <ListItemCol
        style={{
          marginLeft: 8,
          marginRight: 8,
          cursor: "pointer",
        }}
      >
        <Button
          color={value.editando ? "green" : undefined}
          onClick={() => {
            onChangePergunta(index, "editando", !value.editando);
          }}
        >
          <i
            className={`mdi mdi-${value.editando ? "check" : "pencil"}`}
            style={{
              fontSize: 18,
              color: value.editando ? colors.white : colors.black700,
            }}
          />
        </Button>
      </ListItemCol>

      <ListItemCol
        style={{
          marginLeft: 8,
          marginRight: 8,
          cursor: "pointer",
        }}
      >
        <Button onClick={onRemovePergunta}>
          <i
            className="mdi mdi-delete-outline"
            style={{ fontSize: 20, color: colors.black700 }}
          />
        </Button>
      </ListItemCol>
    </ListItem>
  );
}

export default QuestaoItem;
