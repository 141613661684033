import styled from "styled-components";
import colors from "../../../../infra/resources/theme/colors";

export const PageHeaderWrapper = styled.div``;
export const PageHeaderIcon = styled.span`
  .mdi {
    font-size: 2em;
    color: ${colors.black200};
    margin-right: 16px;
  }
`;

export const PageHeaderTitle = styled.span`
  font-size: 1.6em;
  position: relative;
  top: -2px;
`;

export const PageHeaderSubtitle = styled.span`
  font-size: 1.4em;
  color: ${colors.black500};
`;
