import { useCallback, useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import history from "infra/history";
import AuthClient from "./authClient";

export default function useLogout() {
  const { dispatch } = useContext(AuthContext);
  const handleLogout = useCallback(async () => {
    await AuthClient.revoke().then(({success}) => {
      dispatch({type: "@AUTH_LOGOUT", payload: success});
      history.push("/auth/login");
    });
  }, [dispatch]);

  return {
    handleLogout,
  };
}
