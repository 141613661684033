import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthRoute from "../components/AuthRoute";

import AdminPages from "./Admin";
import AuthPages from "./Auth";

function Pages() {
  return (
    <Switch>
      <AuthRoute path="/admin" component={AdminPages} />
      <Route path="/auth" component={AuthPages} />
      <Redirect path="/" to="/admin" />
    </Switch>
  );
}

export default Pages;
