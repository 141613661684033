import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #fafafa;
  padding-top: 120px;
`;

export const Container = styled.div.attrs({
  className: "container",
})``;
