import { AuthContext } from "contexts/AuthContext";
import { useMemo, useContext } from "react";
import { Drawer, Nav } from "rsuite";

import logo from "assets/images/logo.svg";
import menu, { MenuDataItem } from "data/menu";

import { useLayoutContext } from "../index";
import { ContainerNav, GlobalStyled } from "./styles";
import { AuthUser } from "infra/resources/types/auth";

function Sidebar() {
  const { isOpenMenu, toogleIsOpenMenu } = useLayoutContext();
  const { state } = useContext(AuthContext);

  const menuItems: MenuDataItem[] = useMemo(() => {
    return state.user
      ? menu.filter((item: MenuDataItem) => {
          if (typeof item.visible === "function") {
            const r = item.visible(state.user as AuthUser);
            return r;
          }

          return item.visible;
        })
      : [];
  }, [state.user]);

  if (state.loading) {
    return <>Carregando Menu</>;
  }

  return (
    <>
      <GlobalStyled />
      <Drawer
        style={{ width: 300 }}
        show={isOpenMenu}
        placement="left"
        onHide={toogleIsOpenMenu}
      >
        <Drawer.Header>
          <img src={logo} alt="Piloto" />
        </Drawer.Header>
        <Drawer.Body>
          <ContainerNav>
            <Nav
              vertical
              onSelect={(index) => {
                const item = menuItems[index - 1];

                if (item && typeof item.action === "function") {
                  item.action();

                  toogleIsOpenMenu();
                }
              }}
            >
              {menuItems.map((item: any, index: any) => (
                <Nav.Item
                  key={item.title}
                  disabled={item.divider}
                  eventKey={index + 1}
                  active={index === 0}
                  icon={item.icon}
                >
                  {item.title}
                </Nav.Item>
              ))}
            </Nav>
          </ContainerNav>
        </Drawer.Body>
      </Drawer>
    </>
  );
}

export default Sidebar;
