import React, { useCallback, useEffect, useState } from "react";
import ContentScroll from "components/ContentScroll";
import http from "infra/axios";
import { Spin } from "antd";
import { Flex } from "@chakra-ui/react";

function TabAssuntos({ value, onChange, cursos, modulos, active }: any) {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [idOpen, setIdOpen] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        setData([]);

        if (!modulos?.length || !active) {
          return;
        }

        setIsLoading(true);

        const { data: result } = await http.get("api/curso/modulos-assuntos", {
          params: {
            modulos: modulos.map((item: any) => item.id),
          },
        });

        setData(result.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [modulos, active]);

  const getModulos = useCallback(
    (curso) => {
      if (!modulos?.length) {
        return [];
      }

      return modulos.filter((item: any) => item.curso_id === curso.id);
    },
    [modulos]
  );

  const getAssuntos = useCallback(
    (modulo) => {
      if (!data?.length) {
        return [];
      }

      return data.filter((item) => item.curso_modulo_id === modulo.id);
    },
    [data]
  );

  if (!active) {
    return null;
  }

  return (
    <>
      <Flex fontSize="18px" gridGap={"6px"} color="gray.600">
        Selecione as <strong>Assuntos</strong> em que a questão seja inserida
      </Flex>

      <br />

      {isLoading ? <Spin /> : null}

      {cursos?.map((curso: any) =>
        getModulos(curso).map((modulo: any) => (
          <div style={{ marginBottom: 16 }}>
            <ContentScroll
              title={
                <div>
                  ({curso.tipo}) {curso.nome} / <strong>{modulo.nome}</strong>
                </div>
              }
              openTitle={modulo.id === idOpen}
              onCloseTitle={() => {
                setIdOpen((prev: any) =>
                  prev === modulo.id ? null : modulo.id
                );
              }}
              data={getAssuntos(modulo)}
              value={value}
              onChange={onChange}
              countCheckeds={
                value.filter(
                  (assunto: any) => assunto.curso_modulo_id === modulo.id
                ).length
              }
            />
          </div>
        ))
      )}
    </>
  );
}

export default TabAssuntos;
