import React from "react";
import { Col, Row, Tag } from "rsuite";
import colors from "infra/resources/theme/colors";
import styled from "styled-components";
import history from "infra/history";
import { Box } from "@chakra-ui/react";

const TagModalidade = styled.div`
  color: ${colors.primary};
  text-transform: uppercase;
  font-weight: bold;
  border-right: 2px solid #666;
  padding-right: 8px;
  line-height: 14px;
  float: left;
`;

interface TagQualificacaoProps {
  qualificacao: string;
}

const TagQualificacao = styled.div<TagQualificacaoProps>`
  font-size: 11px;
  padding: 0px 12px;
  color: ${colors.white};
  background-color: ${(props) =>
    props.qualificacao === "EXTRA" ? colors.primary : colors.black700};
  text-transform: uppercase;
  line-height: 20px;
  border-radius: 8px;
`;

function CursoItem({ item }: any) {
  return (
    <Box
      cursor="pointer"
      onClick={() => {
        history.push({
          pathname: `/admin/administracao/cursos/${item.uuid}`,
          search: window.location.search,
        });
      }}
    >
      <Row gutter={16}>
        <Col sm={24}>
          <div>
            {item.qualificacao ? (
              <div style={{ marginRight: 16, float: "left" }}>
                <TagQualificacao qualificacao={item.qualificacao}>
                  {item.qualificacao}
                </TagQualificacao>
              </div>
            ) : null}

            <div style={{ marginRight: 8, float: "left" }}>
              <h5>{item.nome}</h5>
            </div>
            <span>
              <i style={{ fontSize: 12 }}>({item.apelido})</i>
            </span>
          </div>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 32 }}>
        <Col sm={12}>
          <span>
            {item.modalidade ? (
              <TagModalidade>Curso {item.modalidade.nome}</TagModalidade>
            ) : null}

            <div
              style={{
                paddingLeft: 8,
                fontWeight: "bold",
                lineHeight: "14px",
                float: "left",
              }}
            >
              {item.tipo}
            </div>
          </span>
        </Col>
        <Col sm={12}>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div style={{ marginRight: 16 }}>
              <span>TAGS</span>
            </div>
            <div style={{ flex: 1 }}>
              <span>
                {item.categorias.map((categoria: any, index: number) => (
                  <Tag style={{ marginBottom: 8 }} key={index}>
                    {categoria.nome}
                  </Tag>
                ))}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Box>
  );
}

export default CursoItem;
