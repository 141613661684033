import { useCallback, useState } from "react";
import axios from "infra/axios";
import { showError } from "infra/alerta";

export default function usePerfilExcluir() {
  const [isLoading, setIsLoading] = useState(false);

  const handleExcluir = useCallback(async (id) => {
    return new Promise(async (resolve) => {
      setIsLoading(true);

      try {
        await axios.delete(`/api/perfil/${id}?ids=${id}`);

        resolve(true);
      } catch (e) {
        resolve(false);
        showError();
      } finally {
        setIsLoading(false);
      }
    });
  }, []);

  return {
    handleExcluir,
    isLoading,
  };
}
