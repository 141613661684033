import React from "react";
import { Route, Switch } from "react-router-dom";
import CategoriasListagemPage from "./Listagem";

function CursosPages() {
  return (
    <Switch>
      <Route
        path="/admin/configuracao/categorias"
        component={CategoriasListagemPage}
      />
    </Switch>
  );
}

export default CursosPages;
