import PERMISSOES from "infra/resources/enums/permissoes";
import { AuthUser } from "infra/resources/types/auth";
import history from "infra/history";
import { MenuIcon } from "ui/components/Icon";
import { IconNames } from "ui/components/Icon";

export interface MenuDataItem {
  title: string;
  icon?: JSX.Element;
  visible: boolean | ((user: AuthUser) => boolean);
  action?: () => void;
  divider?: boolean;
}
const menu: MenuDataItem[] = [
  {
    title: "Dashboard",
    icon: <MenuIcon icon={IconNames.DASHBOARD} />,
    visible: true,
    action: () => {
      return history.push("/admin/dashboard");
    },
  },
  {
    title: "Calendário",
    icon: <MenuIcon icon={IconNames.CALENDARIO} />,
    visible: false, // TODO, criar permissao
    action: () => {
      return history.push("/admin/dashboard");
    },
  },
  {
    title: "ADMINISTRAÇÃO",
    divider: true,
    visible: true,
  },
  {
    title: "Cursos",
    icon: <MenuIcon icon={IconNames.CURSOS} />,
    visible: (user: AuthUser) =>
      user.permissoes?.includes(PERMISSOES.CURSO.VISUALIZAR),
    action: () => history.push("/admin/administracao/cursos"),
  },
  {
    title: "Turmas",
    icon: <MenuIcon icon={IconNames.TURMAS} />,
    visible: (user: AuthUser) =>
      user.permissoes?.includes(PERMISSOES.TURMA.VISUALIZAR),
    action: () => history.push("/admin/entidade/turmas"),
  },
  {
    title: "Professores",
    icon: <MenuIcon icon={IconNames.PROFESSORES} />,
    visible: false, // TODO, criar permissao
    action: () => history.push("/admin/administracao/usuarios"),
  },
  {
    title: "Provas",
    icon: <MenuIcon icon={IconNames.PROVAS} />,
    visible: false, // TODO, criar permissao de prova
    action: () => history.push("/admin/administracao/provas"),
  },
  {
    title: "Problemas",
    icon: <MenuIcon icon={IconNames.PROBLEMAS} />,
    visible: true, // TODO, criar permissao de prova
    action: () => history.push("/admin/administracao/problemas"),
  },
  {
    title: "Questões",
    icon: <MenuIcon icon={IconNames.QUESTOES} />,
    visible: (user: AuthUser) =>
      user.permissoes?.includes(PERMISSOES.QUESTAO.VISUALIZAR),
    action: () => history.push("/admin/administracao/questoes"),
  },
  {
    title: "Entidade",
    icon: <MenuIcon icon={IconNames.ENTIDADE} />,
    visible: (user: AuthUser) =>
      user.permissoes?.includes(PERMISSOES.ESCOLA.VISUALIZAR),
    action: () => history.push("/admin/administracao/entidades"),
  },

  {
    title: "CONFIGURAÇÕES",
    divider: true,
    visible: true,
  },
  {
    title: "Perfis de Acesso",
    icon: <MenuIcon icon={IconNames.PERFIS_DE_ACESSO} />,
    visible: (user: AuthUser) =>
      user.permissoes?.includes(PERMISSOES.PERFIL.VISUALIZAR),
    action: () => history.push("/admin/configuracao/perfis"),
  },
  {
    title: "Usuários",
    icon: <MenuIcon icon={IconNames.USUARIOS} />,
    visible: (user: AuthUser) =>
      user.permissoes?.includes(PERMISSOES.USUARIO.VISUALIZAR),
    action: () => history.push("/admin/configuracao/usuarios"),
  },
  {
    title: "Integrações",
    icon: <MenuIcon icon={IconNames.INTEGRACOES} />,
    visible: false, // TODO, criar permissao
    action: () => history.push("/admin/configuracao/integracao"),
  },
  {
    title: "Categorias",
    icon: <MenuIcon icon={IconNames.TAGS} />,
    visible: true, // TODO, criar permissao
    action: () => history.push("/admin/configuracao/categorias"),
  },
];

export default menu;
