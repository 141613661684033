import { useEffect, useState } from "react";
import axios from "infra/axios";

export default function useEntidades() {
  const [data, setData] = useState<any>(null);
  const [params, setParams] = useState<any>({
    page: 1,
    per_page: 15,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data } = await axios.get("/api/tenant", {
          params: params,
        });

        setData(data.data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
      // TODO ...
    })();
  }, [params]);

  return {
    isLoading,
    data,
    params,
    setParams,
  };
}
