import { useState } from "react";
import {
  Button,
  Radio,
  RadioGroup,
  Col,
  ControlLabel,
  Divider,
  Form,
  FormGroup,
  Modal,
  Row,
} from "rsuite";
import SelectCategoria from "components/Input/SelectCategoria";
import SelectModalidade from "components/Input/SelectModalidade";
import SelectEntidade from "components/Input/SelectEntidade";

import SelectBase from "components/Input/SelectBase";

interface Entidade {
  id: number;
  nome: string;
}
interface Tag {
  id: number;
  nome: string;
}

function ModalFiltros({
  open,
  onClose,
  onFilter,
  value,
}: {
  open: boolean;
  onClose: Function;
  onFilter: Function;
  value: any;
}) {
  const [form, setForm] = useState<any>({
    order_by: "nome",
  });

  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header onHide={() => onClose()}>
        <Modal.Title>Refinar</Modal.Title>
      </Modal.Header>

      <Divider />

      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <ControlLabel>
              <strong>ORDENAÇÃO</strong>
            </ControlLabel>

            <SelectBase
              isClearable
              placeholder="Padrão (Nome)"
              value={form.order_by}
              onChange={(value: any) =>
                setForm((prev: any) => ({ ...prev, order_by: value }))
              }
              options={[
                {
                  value: "nome",
                  label: "Nome do Curso",
                },
              ]}
            />
          </FormGroup>

          <strong>FILTROS</strong>

          <div style={{ marginTop: 16 }}>
            {/* <FormGroup>
                <ControlLabel>Situação</ControlLabel>
                <CheckboxGroup
                  style={{ marginLeft: -18 }}
                  inline
                  value={form.situacao}
                  onChange={(value) =>
                    setForm((prev: any) => ({ ...prev, situacao: value }))
                  }
                >
                  <Row>
                    <Col sm={6}>
                      <Checkbox value="Ativo">Ativo</Checkbox>
                    </Col>
                    <Col sm={6}>
                      <Checkbox value="Inativo">Inativo</Checkbox>
                    </Col>
                    <Col sm={6}>
                      <Checkbox value="Erro">Erro</Checkbox>
                    </Col>
                    <Col sm={6}>
                      <Checkbox value="Novo">Novo</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </FormGroup> 
               */}

            <FormGroup controlId="situacao">
              <ControlLabel>Situação</ControlLabel>
              <RadioGroup
                name="situacao"
                style={{ marginLeft: -18, width: "100%" }}
                inline
                value={form.situacao}
                onChange={(value) =>
                  setForm((prev: any) => ({ ...prev, situacao: value }))
                }
              >
                <Row>
                  <Col sm={4}>
                    <Radio value={null}>Todas</Radio>
                  </Col>
                  <Col sm={4}>
                    <Radio value="Ativo">Ativo</Radio>
                  </Col>
                  <Col sm={4}>
                    <Radio value="Inativo">Inativo</Radio>
                  </Col>
                </Row>
              </RadioGroup>
            </FormGroup>

            <FormGroup>
              <ControlLabel>Qualificação</ControlLabel>
              <RadioGroup
                value={form.qualificacao}
                onChange={(value) =>
                  setForm((prev: any) => ({ ...prev, qualificacao: value }))
                }
                inline
                style={{ marginLeft: -18, width: "100%" }}
              >
                <Row>
                  <Col sm={4}>
                    <Radio value={null}>Todas</Radio>
                  </Col>
                  <Col sm={4}>
                    <Radio value="ANAC">ANAC</Radio>
                  </Col>
                  <Col sm={4}>
                    <Radio value="EXTRA">EXTRA</Radio>
                  </Col>
                </Row>
              </RadioGroup>
            </FormGroup>

            <Row gutter={16} style={{ marginTop: 32, marginBottom: 56 }}>
              <Col md={24}>
                <FormGroup>
                  <ControlLabel>Modalidade</ControlLabel>
                  <SelectModalidade
                    isClearable
                    placeholder="Todas"
                    value={form.modalidade}
                    onChange={(value: any) =>
                      setForm((prev: any) => ({ ...prev, modalidade: value }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 32, marginBottom: 56 }}>
              <Col md={24}>
                <FormGroup>
                  <ControlLabel>Tipo</ControlLabel>

                  <SelectBase
                    isClearable
                    placeholder="Todas"
                    value={form.tipo}
                    onChange={(value: any) =>
                      setForm((prev: any) => ({ ...prev, tipo: value }))
                    }
                    options={[
                      {
                        value: "Teórico",
                        label: "Teórico",
                      },
                      {
                        value: "Prático",
                        label: "Prático",
                      },
                      {
                        value: "Teórico/Prático",
                        label: "Teórico/Prático",
                      },
                    ]}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 32, marginBottom: 56 }}>
              <Col md={24}>
                <FormGroup>
                  <ControlLabel>Tags</ControlLabel>
                  <SelectCategoria
                    placeholder="Todas tags"
                    isMulti
                    value={form.categorias ?? []}
                    onChange={(value: Tag[]) => {
                      setForm((prev: any) => ({ ...prev, categorias: value }));
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 32, marginBottom: 56 }}>
              <Col md={24}>
                <FormGroup>
                  <ControlLabel>Entidades</ControlLabel>
                  <SelectEntidade
                    placeholder="Todas entidades"
                    isMulti
                    value={form.entidades ?? []}
                    onChange={(value: Entidade[]) => {
                      setForm((prev: any) => ({ ...prev, entidades: value }));
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>
      <Divider />
      <Modal.Footer>
        <Button onClick={() => onClose()} appearance="ghost">
          CANCELAR
        </Button>
        <Button onClick={() => onFilter(form)} appearance="primary">
          APLICAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFiltros;
