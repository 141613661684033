export enum USUARIO_SITUACAO {
  Ativo = "Ativo",
  Inativo = "Inativo",
  Pendente = "Pendente",
}

export enum TENANT_SITUACAO {
  Liberada = "Liberada",
  Ativo = "Ativo",
  Suspensa = "Suspensa",
  AVencer = "A Vencer",
}

export enum QUESTAO_SITUACAO {
  Ativo = "Ativo",
  Inativo = "Inativo",
}
