import { useCallback, useEffect, useState } from "react";

import axios from "infra/axios";
import { showError, showSuccess } from "infra/alerta";
import {
  CursoModuloAssuntoType,
  CursoModuloType,
  CursoType,
} from "infra/resources/types/curso";
import { useHistory } from "react-router";

export default function useCurso(id?: any) {
  const [data, setData] = useState<CursoType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);

        try {
          const { data } = await axios.get(`/api/curso/${id}`);

          setData({
            ...data.data,
          });
        } catch (e) {
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [id]);

  const handleSave = useCallback(
    async (values: CursoType) => {
      setIsLoading(true);

      console.log(" values", values);

      try {
        const model: CursoType = {
          ...data,
          ...values,
          permite_sem_homologacao: !!values.permite_sem_homologacao,
        };

        const modulosExcluidos: CursoModuloType[] = (
          data?.modulos.filter((m: CursoModuloType) => {
            const exists = model.modulos.find((item) => item.id === m.id);

            return !exists;
          }) ?? []
        ).map((m) => ({ ...m, deleted_at: true }));

        const modulos =
          model.modulos.map((modulo: any, indexModulo: number) => {
            const moduloOriginal = data?.modulos.find(
              (m) => m.id === modulo.id
            );

            let assuntos = modulo.assuntos?.map(
              (assunto: any, indexAssunto: number) => ({
                ...assunto,
                new: assunto.new || undefined,
                id: assunto.new ? undefined : assunto.id,
                ordem: indexAssunto,
              })
            );

            if (moduloOriginal) {
              const modulosAssuntosExcluidos: CursoModuloAssuntoType[] =
                moduloOriginal?.assuntos
                  .filter((assunto: CursoModuloAssuntoType) => {
                    const exists = modulo.assuntos.find(
                      (item: CursoModuloAssuntoType) => item.id === assunto.id
                    );

                    return !exists;
                  })
                  .map((a) => ({ ...a, deleted_at: true }));

              assuntos = [...assuntos, ...modulosAssuntosExcluidos];
            }

            return {
              ...modulo,
              new: modulo.new || undefined,
              id: modulo.new ? undefined : modulo.id,
              ordem: indexModulo,
              assuntos,
            };
          }) ?? [];

        model.modulos = [...modulos, ...modulosExcluidos];

        if (!id) {
          await axios.post("/api/curso", model);
        } else {
          await axios.put(`/api/curso/${id}`, model);
        }

        showSuccess().then(() => {
          history.push({
            pathname: "/admin/administracao/cursos",
            search: window.location.search,
          });
        });
      } catch (error: any) {
        const errors = [];

        const data = error.response.data;

        if (data.message) {
          showError();
        } else {
          for (const prop in data) {
            errors.push(data[prop][0]);
          }

          showError(errors[0]);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [data, history, id]
  );
  return {
    handleSave,
    isLoading,
    data,
  };
}
