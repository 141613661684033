import axios from "axios";
import http from "infra/axios";
import {
  BASE_URL_API,
  CLIENT_ID,
  CLIENT_SECRET,
  KEY_AUTH,
} from "../../infra/config";

class AuthClient {
  static async login(username: string, password: string) {
    try {
      const model = {
        username: username,
        password: password,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: "password",
      };

      const { data } = await http.post("/oauth/token", model);

      data.expires_in_date = +new Date() + data.expires_in; // TODO, corrigir isso
      this.saveSession(data);

      return {
        success: true,
        data,
      };
    } catch (error: any) {
      let err = error.message;

      if (error.message.indexOf("Request failed with status code 422") > -1) {
        err = error.response.data;
      }

      if (error.message.indexOf("Request failed with status code 401") > -1) {
        err = "Usuário ou senha incorretos!";
      }

      return {
        error: err,
      };
    }
  }

  static async refreshToken() {
    try {
      const refresh_token = AuthClient.getRefreshToken();

      const model = {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: "refresh_token",
        refresh_token,
      };

      const http2 = axios.create({
        baseURL: BASE_URL_API,
      });

      const result = await http2.post("/oauth/token", model);

      const { data } = result;

      data.expires_in_date = +new Date() + data.expires_in;

      this.saveSession(data);

      return result;
    } catch (e) {
      throw e;
    }
  }

  static async revoke() {
    try {
      const access_token = AuthClient.getAccessToken();
      const http2 = axios.create({
        baseURL: BASE_URL_API,
      });
      await http2.delete(`/oauth/tokens/${access_token}`);
      AuthClient.logout();
      return {
        success: true,
      };
    } catch (e) {
      AuthClient.logout();
      return {
        success: false,
      };
    }
  }

  static saveSession(data: any) {
    return localStorage.setItem(KEY_AUTH as string, JSON.stringify(data));
  }

  static async getServerData() {
    try {
      const { data } = await http.get("/api/usuario/userinfo");
      return data.data;
    } catch (e) {}
  }

  static getLocalStorageAuthData() {
    try {
      const localStorageData = JSON.parse(
        localStorage.getItem(KEY_AUTH) as string
      );
      return localStorageData;
    } catch (e) {
      return null;
    }
  }

  static getAccessToken() {
    const data = AuthClient.getLocalStorageAuthData();

    return data?.access_token;
  }

  static getRefreshToken() {
    const data = AuthClient.getLocalStorageAuthData();

    return data?.refresh_token;
  }

  static isExpires() {
    const data = AuthClient.getLocalStorageAuthData();

    const now = +new Date();

    if (data.expires_in_date > now) {
      return false;
    }

    return true;
  }

  static isLogged() {
    const data = AuthClient.getLocalStorageAuthData();
    return !!data?.access_token;
  }

  static logout = () => {
    if (KEY_AUTH) {
      localStorage.removeItem(KEY_AUTH as string);
    }
  };
}

export default AuthClient;
