import { Empty, Pagination } from "antd";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import colors from "infra/resources/theme/colors";

import TableLoading from "./TableLoading";


const PaginationWrapper = styled.div`
  padding: 16px;
  .ant-pagination {
    display:flex;
  }
  .ant-pagination-total-text {
    order: 5;
    margin-left: 2rem;
    color: #56626E;
  }
  .ant-pagination-item { 
 color: #5D89C5;
 border: unset;

  } 
  .ant-pagination-item-link { 
    border: unset;
    color: #5D89C5;
  }
  .ant-pagination-item-active {
    background: #5D89C5;
    color: #fff;
    border: unset;
    border-radius: 4px;
  }
  .ant-pagination-disabled  {
    color: #eee;
    border: unset;
  }
  .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {

    border: unset;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
      .ant-pagination-item-link { 
    background:#F5F5F5;
  }
  }
`

const Styles = styled.div`
  background-color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  .rs-tag {
    border-radius: 10px;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 25px;
    border-radius: 6px;
    &.rs-tag-default {
      background-color: ${colors.black100};
    }

    .rs-badge {
      font-size: 10px !important;
    }
  }

  table {
    border-spacing: 0;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      color: ${colors.black500};
    }

    th,
    td {
      margin: 0;
      padding: 12px 24px;
      border-bottom: 1px solid ${colors.black200};

      :last-child {
        border-right: 0;
      }
    }
  }

  td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
`;

const HeaderStyle = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 16px;
`;

function TableComponent({ columns, data }: any) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 20);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function Table({
  loading,
  columns,
  data,
  header,
  perPage,
  page,
  total,
  pagination = true,
  onChange,
}: {
  loading?: boolean;
  columns: any;
  data: any;
  header?: any;
  perPage?: number;
  page?: number;
  total?: number;
  pagination?: boolean;
  onChange?: any;
}) {
  if (loading) {
    return <TableLoading />;
  }

  return (
    <Styles>
      {typeof header === "function" ? (
        <HeaderStyle>{header()}</HeaderStyle>
      ) : null}

      {!loading ? <TableComponent columns={columns} data={data} /> : null}

      {!loading && !data?.length ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : null}

      {pagination ? (
        <PaginationWrapper>
          <Pagination
            onChange={(page, perPageDisplay) => {
              onChange({ page, per_page: perPageDisplay });
            }}
            pageSize={perPage ?? 0}
            current={page ?? 0}
            total={total ?? 0}
            showTotal={(total, range) => `${range[0]}-${range[1]} de ${total}`}
            showSizeChanger={false}
          />
        </PaginationWrapper>
      ) : null}
    </Styles>
  );
}

export default Table;
