import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Icon } from "rsuite";
import colors from "infra/resources/theme/colors";
import { showConfirm } from "infra/alerta";

function Classificacao({ uuid, value, modulos, cursos, onChange }: any) {
  const handleRemove = useCallback(
    (index: any) => {
      showConfirm("Deseja remover o assunto?").then((result) => {
        if (result.isConfirmed) {
          delete value[index];

          const assuntos = value.filter((item: any) => item);

          onChange(assuntos);
        }
      });
    },
    [onChange, value]
  );

  console.log("modulos", modulos);

  return (
    <div>
      <h3>Classificações da questão</h3>

      <br />

      <div>
        {value?.map((assunto: any, index: number) => {
          const modulo = modulos.find(
            (item: any) => item.id === assunto.curso_modulo_id
          );

          if (!modulo) {
            console.log("assunto", assunto);
          }

          const curso = cursos.find((item: any) => item.id === modulo?.curso_id);

          return (
            <div style={{ marginBottom: 28, overflow: "hidden" }}>
              <div style={{ float: "left" }}>
                <div>
                  {curso?.nome ?? "--"} / {modulo?.nome ?? "-- Módulo excluido --"}
                </div>
                <div style={{ fontWeight: "bold" }}>{assunto.nome}</div>
              </div>
              <div style={{ float: "right" }}>
                <Button onClick={() => handleRemove(index)}>
                  <i
                    className="mdi mdi-delete-outline"
                    style={{ fontSize: 20, color: colors.black700 }}
                  />
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      {value?.length ? <BotaoClassificar uuid={uuid} /> : null}

      {!value?.length ? (
        <div
          style={{
            backgroundColor: "#f7f7f7",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            paddingTop: 60,
            paddingBottom: 60,
            flexDirection: "column",
          }}
        >
          <div>
            <div>
              <p style={{ fontSize: "1.3em" }}>
                A questão não está classificada em nenhum Assunto
              </p>
            </div>

            <BotaoClassificar uuid={uuid} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

function BotaoClassificar({ uuid }: any) {
  const history = useHistory();

  return (
    <div style={{ textAlign: "center" }}>
      <br />
      <Button
        color="red"
        appearance="ghost"
        onClick={() => {
          showConfirm(
            "Antes de continuar certifique de ter salvo todas as alterações"
          ).then((result) => {
            if (result.isConfirmed) {
              history.push(
                `/admin/administracao/questoes/${uuid}/classificacoes`
              );
            }
          });
        }}
      >
        <Icon
          icon="plus-circle"
          style={{ color: colors.red500, marginRight: 8 }}
        />
        CLASSIFICAR A QUESTÃO
      </Button>
    </div>
  );
}
export default Classificacao;
