import React from "react";
import { Icon, IconButton } from "rsuite";

import colors from "../../../../../../../infra/resources/theme/colors";

function HeaderRight({
  handleSincronizacao,
  isLoading,
}: {
  isLoading: boolean;
  handleSincronizacao: Function;
}) {
  return (
    <div>
      <div style={{ textAlign: "right" }}>
        <IconButton
          color="red"
          loading={isLoading}
          appearance="ghost"
          icon={
            !isLoading ? (
              <Icon style={{ color: colors.red500 }} icon="refresh" />
            ) : undefined
          }
          onClick={() => handleSincronizacao()}
        >
          SINCRONIZAR
        </IconButton>
      </div>
      <div style={{ fontSize: 11, marginTop: 8 }}>
        Última sincronização <strong>28/11/2020</strong> ás{" "}
        <strong>20:21</strong>
      </div>
    </div>
  );
}

export default HeaderRight;
