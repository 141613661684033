import { USUARIO_SITUACAO } from "infra/resources/enums/situacao";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  ControlLabel,
  Divider,
  Form,
  FormControl,
  FormGroup,
  Message,
  Modal,
  Row,
} from "rsuite";
import SelectPerfil from "components/Input/SelectPerfil";
import SelectSituacaoUsuario from "components/Input/SelectSituacaoUsuario";
import InputUploadProfile from "components/Input/InputUploadProfile";
import useUsuario from "data/usuario/useUsuario";
import { UsuarioListItem } from "infra/resources/types/user";

function ModalUsuario({
  open,
  onClose,
  onSaved,
  value,
}: {
  open: boolean;
  onClose: Function;
  onSaved: Function;
  value?: UsuarioListItem;
}) {
  const { handleSave, isLoading } = useUsuario();
  const [messages, setMessages] = useState<any[] | null>();
  const [form, setForm] = useState<UsuarioListItem>({
    situacao: USUARIO_SITUACAO.Ativo,
  } as any);

  useEffect(() => {
    if (value) {
      setForm({
        ...value,
      });
    }
  }, [value]);

  useEffect(() => {
    if (!open) {
      setForm({ situacao: USUARIO_SITUACAO.Ativo } as any);
    }
  }, [open]);

  const onSave = async () => {
    const { errors } = await handleSave(form);

    setMessages(errors);

    if (!errors) {
      onSaved();
    } else {
    }
  };

  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header onHide={() => onClose()}>
        <Modal.Title>{form.id ? "Editar" : "Novo"} Usuário</Modal.Title>
      </Modal.Header>

      <Divider />

      <Modal.Body>
        <Form fluid>
          {messages?.length ? (
            <Message
              style={{ marginBottom: 16 }}
              showIcon
              type="error"
              title="Houve alguns problemas :/"
              description={messages.map((message) => (
                <div>{message}</div>
              ))}
            />
          ) : null}

          <FormGroup>
            <ControlLabel>
              <strong>Nome</strong>
            </ControlLabel>
            <FormControl
              name="nome"
              value={form.nome}
              onChange={(v) => setForm((prev) => ({ ...prev, nome: v }))}
              placeholder="Nome"
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>
              <strong>Email</strong>
            </ControlLabel>
            <FormControl
              name="email"
              value={form.email}
              onChange={(v) => setForm((prev) => ({ ...prev, email: v }))}
              placeholder="Email"
            />
          </FormGroup>

          <Row>
            <Col sm={12}>
              <FormGroup>
                <ControlLabel>
                  <strong>Perfil de Acesso</strong>
                </ControlLabel>
                <SelectPerfil
                  isMulti
                  value={form.perfis}
                  onChange={(v: any) =>
                    setForm((prev) => ({ ...prev, perfis: v }))
                  }
                  placeholder="Perfil"
                />
              </FormGroup>
            </Col>

            <Col sm={12}>
              <FormGroup>
                <ControlLabel>
                  <strong>Situação</strong>
                </ControlLabel>
                <SelectSituacaoUsuario
                  value={form.situacao as any}
                  onChange={(v: any) =>
                    setForm((prev) => ({ ...prev, situacao: v }))
                  }
                  placeholder="Situação"
                />
              </FormGroup>
            </Col>
          </Row>

          <br />

          <FormGroup>
            <InputUploadProfile
              value={form.imagem_url}
              onChange={(v: any) =>
                setForm((prev) => ({ ...prev, imagem_url: v }))
              }
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Divider />
      <Modal.Footer>
        <Button
          disabled={isLoading}
          onClick={() => onClose()}
          appearance="ghost"
        >
          CANCELAR
        </Button>
        <Button
          loading={isLoading}
          onClick={() => onSave()}
          appearance="primary"
        >
          SALVAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalUsuario;
