import React, { useEffect, useState } from "react";

function Tabs({ children, index, onChange, tabActions }: any) {
  const [indexTab, setIndexTab] = useState(0);

  useEffect(() => {
    setIndexTab(index ?? 0);
  }, [index]);

  const childrens = React.Children.map(children, (item) => item);

  return (
    <div>
      <ul className="nav nav-tabs" role="tablist">
        {childrens.map((child: any, index: number) => (
          <li key={index} className="nav-item" role="presentation">
            <span
              className={`nav-link ${indexTab === index ? "active" : ""}`}
              onClick={() => {
                setIndexTab(index);
                onChange(index);
              }}
            >
              {child.props.title}
            </span>
          </li>
        ))}

        {tabActions ? (
          <div className="nav-tab-actions">{tabActions}</div>
        ) : null}
      </ul>
      <div className="tab-content">
        {childrens.map((child: any, index: number) => (
          <div
            key={index}
            className={`tab-pane ${indexTab === index ? "show" : "fade"}`}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  );
}

export function TabItem({
  title,
  component,
}: {
  title: string;
  component: any;
}) {
  return <div>{component}</div>;
}

Tabs.TabItem = TabItem;

export default Tabs;
