import { Switch } from "react-router-dom";
import TurmasPages from "./TurmasPages";
import RouteAuthorize from "ui/components/RouteAuthorize";
import PERMISSOES from "infra/resources/enums/permissoes";

function EntidadePages() {
  return (
    <Switch>
      <RouteAuthorize
        permissoes={[PERMISSOES.TURMA.VISUALIZAR]}
        path="/admin/entidade/turmas"
        component={TurmasPages}
      />
    </Switch>
  );
}

export default EntidadePages;
