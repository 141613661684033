import PERMISSOES from "infra/resources/enums/permissoes";
import { Switch } from "react-router-dom";
import RouteAuthorize from "ui/components/RouteAuthorize";
import QuestaoClassificacaoPage from "./Classificacao";
import QuestaoFormularioPage from "./Formulario";
import QuestaoListagemPage from "./Listagem";

function QuestoesPages() {
  return (
    <Switch>
      <RouteAuthorize
        permissoes={[PERMISSOES.QUESTAO.ADICIONAR]}
        exact
        path="/admin/administracao/questoes/novo"
        component={QuestaoFormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.QUESTAO.ALTERAR]}
        exact
        path="/admin/administracao/questoes/:id/classificacoes"
        component={QuestaoClassificacaoPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.QUESTAO.ALTERAR]}
        exact
        path="/admin/administracao/questoes/:id"
        component={QuestaoFormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.QUESTAO.VISUALIZAR]}
        path="/admin/administracao/questoes"
        component={QuestaoListagemPage}
      />
    </Switch>
  );
}

export default QuestoesPages;
