import { useEffect, useState } from "react";
import axios from "infra/axios";
import { TurmaResponse } from "./typings";
import { Paginate } from "infra/helpers/paginate";

export default function useTurmas() {
  const [data, setData] = useState<Paginate<TurmaResponse>>({
    data: [],
    current_page: 1,
  } as any);

  const [params, setParams] = useState<any>({
    page: 1,
    per_page: 15,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const {
          data: { data },
        }: { data: { data: Paginate<TurmaResponse> } } = await axios.get(
          "/api/turma",
          {
            params: params,
          }
        );

        setData(data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
      // TODO ...
    })();
  }, [params]);

  return {
    isLoading,
    data,
    params,
    setParams,
  };
}
