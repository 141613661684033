import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";

function CheckPermission({ children, permissoes = [] }: any) {
  const { state } = useContext(AuthContext);

  let isOr = Array.isArray(permissoes?.[0]);
  let hasPermission = false;

  const userPermissoes = state.user?.permissoes ?? [];

  if (isOr) {
    hasPermission = permissoes.find((item: string[]) =>
      item.some((i) => permissoes.includes(i))
    );

    if (hasPermission) {
      return children;
    }

    return null;
  }

  hasPermission = permissoes.every((item: string) =>
    userPermissoes.includes(item)
  );

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
}

export default CheckPermission;
