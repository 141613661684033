import PERMISSOES from "infra/resources/enums/permissoes";
import { Switch } from "react-router-dom";
import RouteAuthorize from "ui/components/RouteAuthorize";
import CursoFormularioPage from "./Formulario";
import CursoListagemPage from "./Listagem";

function CursosPages() {
  return (
    <Switch>
      <RouteAuthorize
        permissoes={[PERMISSOES.CURSO.ADICIONAR]}
        exact
        path="/admin/administracao/cursos/novo"
        component={CursoFormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.CURSO.ADICIONAR]}
        exact
        path="/admin/administracao/cursos/:id"
        component={CursoFormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.CURSO.VISUALIZAR]}
        path="/admin/administracao/cursos"
        component={CursoListagemPage}
      />
    </Switch>
  );
}

export default CursosPages;
