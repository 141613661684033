import React from "react";
import { Props } from "react-select";

import colors from "infra/resources/theme/colors";
import { TENANT_SITUACAO } from "infra/resources/enums/situacao";

import SelectBase from "../SelectBase";

function SelectSituacaoTenant({ ...props }: Props) {
  const options: { value: any; label: any; color: any }[] = [];

  options.push({
    label: "Ativo",
    value: TENANT_SITUACAO.Ativo,
    color: colors.green,
  });

  options.push({
    label: "Liberada",
    value: TENANT_SITUACAO.Liberada,
    color: colors.green,
  });

  options.push({
    label: "A Vencer",
    value: TENANT_SITUACAO.AVencer,
    color: colors.warning,
  });

  options.push({
    label: "Suspensa",
    value: TENANT_SITUACAO.Suspensa,
    color: colors.error,
  });

  let value: any = null;

  if (props.value) {
    if (typeof props.value === "string") {
      value = options.find((item) => item.value === props.value);
    } else {
      value = props.value;
    }
  }

  return <SelectBase {...props} value={value} options={options} />;
}

export default SelectSituacaoTenant;
