import styled from "styled-components";

export const ListItem = styled.div`
  clear: both;
  margin-bottom: 16px;
  float: left;
  width: 100%;
  display: flex;
`;

export const ListItemCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListItemInner = styled.div`
  display: flex;
  flex: 1;
  width: auto;
  padding: 8px;
  padding-left: 32px;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background-color: #f8f8f8;
  margin-left: 0px;
  margin-right: 16px;
`;
