import React, { useMemo } from "react";
import { Container } from "./styles";
import { ReactComponent as Dashboard } from "./assets/Dashboard.svg";
import { ReactComponent as Calendario } from "./assets/Calendario.svg";
import { ReactComponent as Cursos } from "./assets/Cursos.svg";
import { ReactComponent as Entidade } from "./assets/Entidade.svg";
import { ReactComponent as Integracoes } from "./assets/Integracoes.svg";
import { ReactComponent as PerfisDeAcesso } from "./assets/Perfis-de-acesso.svg";
import { ReactComponent as Provas } from "./assets/Provas.svg";
import { ReactComponent as Problemas } from "./assets/Problemas.svg";
import { ReactComponent as Questoes } from "./assets/Questoes.svg";
import { ReactComponent as Tags } from "./assets/Tags.svg";
import { ReactComponent as Usuarios } from "./assets/Usuarios.svg";
import { ReactComponent as Professores } from "./assets/Professores.svg";
import { ReactComponent as Alunos } from "./assets/Alunos.svg";
import { ReactComponent as Turmas } from "./assets/Turmas.svg"; 

export enum IconNames {
  DASHBOARD = "DASHBOARD",
  CALENDARIO = "CALENDARIO",
  PROFESSORES = "PROFESSORES",
  ALUNOS = "ALUNOS",
  TURMAS = "TURMAS",
  CURSOS = "CURSOS",
  ENTIDADE = "ENTIDADE",
  INTEGRACOES = "INTEGRACOES",
  PERFIS_DE_ACESSO = "PERFIS_DE_ACESSO",
  PROVAS = "PROVAS",
  PROBLEMAS = "PROBLEMAS",
  QUESTOES = "QUESTOES",
  TAGS = "TAGS",
  USUARIOS = "USUARIOS",
}

const MENU_ICONS: {
  [key in IconNames]: React.FunctionComponent;
} = {
  DASHBOARD: () => <Dashboard />,
  PROFESSORES: () => <Professores />,
  ALUNOS: () => <Alunos />,
  TURMAS: () => <Turmas />,
  CALENDARIO: () => <Calendario />,
  CURSOS: () => <Cursos />,
  ENTIDADE: () => <Entidade />,
  INTEGRACOES: () => <Integracoes />,
  PERFIS_DE_ACESSO: () => <PerfisDeAcesso />,
  PROVAS: () => <Provas />,
  PROBLEMAS: () => <Problemas />,
  QUESTOES: () => <Questoes />,
  TAGS: () => <Tags />,
  USUARIOS: () => <Usuarios />,
};

type Props = {
  icon: IconNames;
};

export function MenuIcon(props: Props) {
  const { icon } = props;

  const IconComponent = useMemo(() => {
    const component = MENU_ICONS[icon];

    return component;
  }, [icon]);

  return (
    <Container>
      <IconComponent />
    </Container>
  );
}
