import { useState, useMemo } from "react";
import { Icon, IconButton, Tag } from "rsuite";

import useUsuarios from "data/usuario/useUsuarios";

import { showConfirm } from "infra/alerta";

import Table from "components/Table";
import PageHeader from "components/Layout/PageHeader";

import HeaderTable from "./components/HeaderTable";
import ModalFiltros from "./components/ModalFiltros";
import ModalUsuario from "./components/ModalUsuario";
import colors from "infra/resources/theme/colors";
import { UsuarioListItem } from "infra/resources/types/user";
import { USUARIO_SITUACAO } from "infra/resources/enums/situacao";

function UsuarioListagemPage() {
  const {
    isLoading,
    data,
    params,
    setParams,
    handleExcluir,
    isLoadingExcluir,
  } = useUsuarios();

  const [openFiltro, setOpenFiltro] = useState(false);
  const [openCadastro, setOpenCadastro] = useState(false);
  const [indexEdit, setIndexEdit] = useState<Number | null>(null);

  const columns = useMemo(
    () => [
      {
        Header: "USUÁRIO",
        accessor: "nome",
        Cell: ({
          row: { original },
        }: {
          row: { original: UsuarioListItem };
        }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
            }}
          >
            {original.imagem_url ? (
              <div
                style={{
                  display: "flex",
                  width: 50,
                  paddingRight: 10,
                }}
              >
                <img
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                  }}
                  src={original.imagem_url}
                  alt={original.nome}
                />
              </div>
            ) : null}
            {!original.imagem_url ? (
              <div
                style={{
                  display: "flex",
                  width: 50,
                  textAlign: "center",
                }}
              >
                <Icon icon="user-circle-o" size="2x" />
              </div>
            ) : null}
            <div
              style={{
                flex: 1,
                display: "flex",
              }}
            >
              {original.nome}
            </div>
          </div>
        ),
      },
      {
        Header: "EMAIL",
        accessor: "email",
      },
      {
        Header: "PERFIL",
        accessor: "perfil",
        Cell: ({
          row: { original },
        }: {
          row: { original: UsuarioListItem };
        }) => (
          <>
            {original.perfis.map((item: any, index: any) => (
              <Tag style={{ marginRight: 4 }} key={index}>
                {item.nome}
              </Tag>
            ))}
          </>
        ),
      },
      {
        Header: "SITUAÇÃO",
        accessor: "situacao",
        Cell: ({
          row: { original },
        }: {
          row: { original: UsuarioListItem };
        }) => {
          return (
            <Tag
              style={{
                backgroundColor:
                  original.situacao === USUARIO_SITUACAO.Pendente
                    ? colors.warningLight
                    : original.situacao === USUARIO_SITUACAO.Ativo
                    ? colors.successLight
                    : colors.errorLight,
                color:
                  original.situacao === USUARIO_SITUACAO.Pendente
                    ? colors.warning
                    : original.situacao === USUARIO_SITUACAO.Ativo
                    ? colors.success
                    : colors.error,
              }}
            >
              {original.situacao ?? USUARIO_SITUACAO.Pendente}
            </Tag>
          );
        },
      },
      {
        accessor: "id",
        Cell: ({
          row: { index, original },
        }: {
          row: { index: Number; original: UsuarioListItem };
        }) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setIndexEdit(index);
                  setOpenCadastro(true);
                }}
                style={{ marginRight: 16 }}
                icon={<Icon icon="edit" />}
              />
              <IconButton
                onClick={() => {
                  showConfirm().then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      return handleExcluir(original.id);
                    }
                  });
                }}
                icon={<Icon icon="trash-o" />}
              />
            </div>
          );
        },
      },
    ],
    [handleExcluir]
  );

  return (
    <div>
      <ModalUsuario
        value={indexEdit !== null ? data?.data[indexEdit as any] : undefined}
        open={openCadastro}
        onClose={() => setOpenCadastro(false)}
        onSaved={() => {
          setParams((prev: any) => ({ ...prev, page: 1 }));

          setOpenCadastro(false);
          setIndexEdit(null);
        }}
      />

      <ModalFiltros
        open={openFiltro}
        onClose={() => setOpenFiltro(false)}
        onFilter={(v: any) => {
          setParams((prev: any) => ({ ...prev, ...v }));
          setOpenFiltro(false);
        }}
      />

      <PageHeader>
        <PageHeader.Left
          title="Usuários"
          icon={<i className="mdi mdi-2x mdi-account" />}
        />
      </PageHeader>

      <div>
        <br />
        <br />
        <Table
          loading={isLoading || isLoadingExcluir}
          header={() => (
            <HeaderTable
              value={params.nome}
              onSearch={(v: string) =>
                setParams((prev: any) => ({
                  ...prev,
                  nome: v,
                  page: 1,
                }))
              }
              onOpenModal={() => setOpenCadastro(true)}
            />
          )}
          columns={columns}
          data={data?.data ?? []}
          total={data?.total || 0}
          page={params?.page || 0}
          perPage={params?.per_page || 0}
          onChange={(values: any) =>
            setParams((prev: any) => ({
              ...prev,
              page: values.page,
              per_page: values.per_page,
            }))
          }
        />
        <br />
        <br />
      </div>
    </div>
  );
}

export default UsuarioListagemPage;
