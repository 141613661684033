const blue = "#4676B6";
const blue100 = "#E2EEFF";
const blue200 = "#92E3FD";
const blue300 = "#5D89C5";
const blue500 = "#739CDE";

const yellow = "#FDDE7D";

const red100 = "#FFE2E5";
const red500 = "#DB8572";
const red = "#F64E60";

const black = "#15181C";
const black900 = "#424447";
const black700 = "#56626E";
const black600 = "#838485";
const black500 = "#B3B4B6";
const black200 = "#DEDFE0";
const black150 = "#F5F5F5";
const black100 = "#F0F0F1";

const white = "#F0F0F1";

const green = "#1BC5BD";
const green100 = "#C9F7F5";

const orange = "#EBB571";
const orange100 = "#FFF4DE";

const theme = {
  royal: blue,
  sky: blue200,
  blonde: yellow,
  coral: red500,
  dark: black,
  carbon: black900,
  steel: black700,
  silver: black600,
  gray: black500,
  smoke: black200,
  snow: black100,
  neutral: white,
  primary: blue,
  error: red,
  errorLight: red100,
  success: green,
  successLight: green100,
  info: blue500,
  infoLight: blue100,
  warning: orange,
  warningLight: orange100,
};

const colors = {
  blue,
  blue100,
  blue200,
  blue300,
  blue500,

  yellow,

  red100,
  red500,
  red,

  black,
  black900,
  black700,
  black600,
  black500,
  black200,
  black150,
  black100,

  white,

  green,
  green100,

  orange,
  orange100,

  ...theme,
};

export default colors;
