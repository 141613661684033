import { useCallback, useEffect, useState } from "react";

import axios from "infra/axios";
import { showError, showSuccess } from "infra/alerta";
import { useHistory } from "react-router";
import { ProblemaForm } from "./types";

export default function useProblema(id?: any) {
  const [data, setData] = useState<ProblemaForm | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);

        try {
          const { data } = await axios.get<{ data: ProblemaForm }>(
            `/api/problema/${id}`
          );

          setData({
            ...data.data,
          });
        } catch (e) {
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [id]);

  const handleSave = useCallback(
    async (values: ProblemaForm) => {
      setIsLoading(true);

      try {
        const model: ProblemaForm = {
          ...data,
          ...values,
        };

        if (!id) {
          await axios.post("/api/problema", model);
        } else {
          await axios.put(`/api/problema/${id}`, model);
        }

        await showSuccess();

        history.push({
          pathname: "/admin/administracao/problemas",
          search: window.location.search,
        });
      } catch (error: any) {
        const errors = [];

        const data = error.response.data;

        if (data.message) {
          showError();
        } else {
          for (const prop in data) {
            errors.push(data[prop][0]);
          }

          showError(errors[0]);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [data, history, id]
  );
  return {
    handleSave,
    isLoading,
    data,
  };
}
