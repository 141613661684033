import { useCallback, useEffect, useState } from "react";

import { showError, showSuccess } from "infra/alerta";
import http from "infra/axios";
import { useHistory } from "react-router";

export default function useQuestaoClassificacao(id: any) {
  const [data, setData] = useState<any>(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);

        try {
          const { data } = await http.get(
            "/api/questao/" + id + "/classificacao"
          );

          setData(data.data);
        } catch (e) {
        } finally {
          setIsLoading(false);
        }
        // TODO ...
      }
    })();
  }, [id]);

  const handleSave = useCallback(
    async (values) => {
      setIsLoading(true);

      try {
        await http.put(`/api/questao/${id}/classificacao`, values);

        showSuccess().then(() => {
          history.push({
            pathname: "/admin/administracao/questoes",
            search: window.location.search,
          });
        });
      } catch (error) {
        const errors = [];

        const data = error.response.data;

        if (data.message) {
          showError();
        } else {
          for (const prop in data) {
            errors.push(data[prop][0]);
          }

          showError(errors[0]);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [history, id]
  );

  return {
    handleSave,
    isLoading,
    data,
  };
}
