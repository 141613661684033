import { Switch } from "react-router-dom";
import QuestoesPages from "./QuestoesPages";
import CursosPages from "./CursosPages";
import ProblemasPages from "./ProblemasPages";
import EntidadesPages from "./EntidadesPages";
import RouteAuthorize from "ui/components/RouteAuthorize";
import PERMISSOES from "infra/resources/enums/permissoes";

function AdministracaoPages() {
  return (
    <Switch>
      <RouteAuthorize
        permissoes={[PERMISSOES.QUESTAO.VISUALIZAR]}
        path="/admin/administracao/questoes"
        component={QuestoesPages}
      />

      <RouteAuthorize
        permissoes={[PERMISSOES.CURSO.VISUALIZAR]}
        path="/admin/administracao/cursos"
        component={CursosPages}
      />

      <RouteAuthorize
        permissoes={[PERMISSOES.PROBLEMAS.VISUALIZAR]}
        path="/admin/administracao/problemas"
        component={ProblemasPages}
      />

      <RouteAuthorize
        permissoes={[PERMISSOES.ESCOLA.VISUALIZAR]}
        path="/admin/administracao/entidades"
        component={EntidadesPages}
      />
    </Switch>
  );
}

export default AdministracaoPages;
