import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Icon, Input, InputGroup } from "rsuite";

function HeaderTable({
  onSearch,
  value,
}: {
  value: any;
  onSearch: Function;
  onOpenFilter: Function;
}) {
  const history = useHistory();
  const [nome, update] = useState<string>();

  useEffect(() => {
    update(value);
  }, [value]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: 1 }}>
        <InputGroup inside style={{ maxWidth: 350, width: "100%" }}>
          <Input
            value={nome}
            onChange={(v) => update(v)}
            placeholder="Procurar"
            onPressEnter={(ev: any) => onSearch(ev.target.value)}
            style={{ borderRadius: 16 }}
          />
          <InputGroup.Button onClick={() => onSearch(nome)}>
            <Icon icon="search" />
          </InputGroup.Button>
        </InputGroup>
      </div>

      <div>
        <Button
          color="blue"
          onClick={() => history.push("/admin/administracao/entidades/novo")}
        >
          <i className="mdi mdi-plus" style={{ color: "white" }} />
          ADICIONAR ENTIDADE
        </Button>
      </div>
    </div>
  );
}

export default HeaderTable;
