import styled from "styled-components";

export const StepsContainer = styled.div`
  .rs-steps-horizontal {
    justify-content: left !important;
  }

  .rs-steps-item {
    overflow: visible;
    flex-basis: auto !important;
    flex-grow: inherit;
    padding-right: 70px;

    .rs-steps-item-title {
      padding-left: 16px;
    }
  }

  .rs-steps-item-icon-wrapper {
    background-color: #f2f2f2;
    border: none;
  }

  .rs-steps-item-status-finish {
    .rs-steps-item-icon-wrapper {
      background-color: #e2eeff;
    }
  }

  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
    border-radius: 8px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-weight: bold;
    margin-top: -8px;
  }

  .rs-steps-horizontal .rs-steps-item-title::after {
    display: none;
  }

  .rs-steps-item-icon::before {
    top: 4px !important;
    left: 9px !important;
    font-size: 16px !important;
  }
`;
