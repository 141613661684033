import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function InputPhone({ value, onChange, onLoadCep, ...props }: any) {
  return (
    <PhoneInput
      inputClass="rs-input"
      onlyCountries={['br']}
      value={value}
      onChange={onChange}
      disableDropdown
      {...props}
    />
  );
}

export default InputPhone;
