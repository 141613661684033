import PERMISSOES from "infra/resources/enums/permissoes";
import { Switch } from "react-router-dom";
import RouteAuthorize from "ui/components/RouteAuthorize";
import FormularioPage from "./Formulario";
import ListagemPage from "./Listagem";

function ProblemasPages() {
  return (
    <Switch>
      <RouteAuthorize
        permissoes={[PERMISSOES.PROBLEMAS.ADICIONAR]}
        exact
        path="/admin/administracao/problemas/novo"
        component={FormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.PROBLEMAS.ADICIONAR]}
        exact
        path="/admin/administracao/problemas/:id"
        component={FormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.PROBLEMAS.VISUALIZAR]}
        path="/admin/administracao/problemas"
        component={ListagemPage}
      />
    </Switch>
  );
}

export default ProblemasPages;
