import { useCallback, useEffect, useState } from "react";
import axios from "infra/axios";
import { showError } from "infra/alerta";
import { PaginateProps } from "infra/resources/types/paginate";
import { UsuarioListItem } from "infra/resources/types/user";

export default function useUsuarios() {
  const [data, setData] = useState<PaginateProps<UsuarioListItem> | null>(null);
  const [params, setParams] = useState<any>({
    page: 1,
    per_page: 15,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExcluir, setIsLoadingExcluir] = useState(false);

  const handleExcluir = useCallback(async (id) => {
    try {
      setIsLoadingExcluir(true);

      await axios.delete(`api/usuario/${id}`);

      setParams((prev: any) => ({ ...prev, page: 1, updatedAt: +new Date() }));

      setIsLoadingExcluir(false);

      return true;
    } catch (error) {
      setIsLoadingExcluir(false);
      showError();
      return false;
    }
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data } = await axios.get("/api/usuario", {
          params: params,
        });

        setData(data.data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
      // TODO ...
    })();
  }, [params]);

  return {
    handleExcluir,
    isLoadingExcluir,
    isLoading,
    data,
    params,
    setParams,
  };
}
