const PERMISSOES = {
  ASSUNTO: {
    toString: () => "assunto",
    VISUALIZAR: "assunto.visualizar",
    ADICIONAR: "assunto.adicionar",
    EXCLUIR: "assunto.excluir",
    ALTERAR: "assunto.alterar",
  },
  CURSO: {
    toString: () => "curso",
    VISUALIZAR: "curso.visualizar",
    ADICIONAR: "curso.adicionar",
    EXCLUIR: "curso.excluir",
    ALTERAR: "curso.alterar",
  },
  PROBLEMAS: {
    toString: () => "problema",
    VISUALIZAR: "problema.visualizar",
    ADICIONAR: "problema.adicionar",
    EXCLUIR: "problema.excluir",
    ALTERAR: "problema.alterar",
  },
  PRODUTO: {
    toString: () => "produto",
    VISUALIZAR: "produto.visualizar",
    ADICIONAR: "produto.adicionar",
    EXCLUIR: "produto.excluir",
    ALTERAR: "produto.alterar",
  },
  DISCIPLINA: {
    toString: () => "disciplina",
    VISUALIZAR: "disciplina.visualizar",
    ADICIONAR: "disciplina.adicionar",
    EXCLUIR: "disciplina.excluir",
    ALTERAR: "disciplina.alterar",
  },
  QUESTAO: {
    toString: () => "questao",
    VISUALIZAR: "questao.visualizar",
    ADICIONAR: "questao.adicionar",
    EXCLUIR: "questao.excluir",
    ALTERAR: "questao.alterar",
  },
  USUARIO: {
    toString: () => "usuario",
    VISUALIZAR: "usuario.visualizar",
    ADICIONAR: "usuario.adicionar",
    EXCLUIR: "usuario.excluir",
    ALTERAR: "usuario.alterar",
  },
  PERFIL: {
    toString: () => "perfil",
    VISUALIZAR: "perfil.visualizar",
    ADICIONAR: "perfil.adicionar",
    EXCLUIR: "perfil.excluir",
    ALTERAR: "perfil.alterar",
  },
  ESCOLA: {
    toString: () => "escola",
    VISUALIZAR: "escola.visualizar",
    ADICIONAR: "escola.adicionar",
    EXCLUIR: "escola.excluir",
    ALTERAR: "escola.alterar",
  },
  TURMA: {
    toString: () => "turma",
    VISUALIZAR: "turma.visualizar",
    ADICIONAR: "turma.adicionar",
    CANCELAR: "turma.cancelar",
    ALTERAR: "turma.alterar",
    INICIAR: "turma.iniciar",
    FINALIZAR: "turma.finalizar",
    GERAR_SIMULADOS: "turma.gerarSimulados",
    ALUNO: {
      ALTERAR: "turma.aluno.alterar",
      ADICIONAR: "turma.aluno.adicionar",
      ENVIAR_EMAIL: "turma.aluno.enviarEmail",
      EXCLUIR: "turma.aluno.excluir",
    },
    DISCIPLINA: {
      ADICIONAR_PROFESSOR: "turma.disciplina.adicionarProfessor",
      ALTERAR_PROFESSOR: "turma.disciplina.alterarProfessor",
      ENVIAR_EMAIL_PROFESSOR: "turma.disciplina.enviarEmailProfessor",
      EXCLUIR: "turma.disciplina.excluir",
    },
  },
};

export default PERMISSOES;
