import { Col, Row } from "rsuite";

import { Button, Checkbox, Flex, Input, Text } from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import { useCallback } from "react";
import { getDayName } from "infra/helpers/date";
import { CalendarioItem, CalendarioItemHorario } from "data/turma/typings";

interface InputCalendarioProps {
  value: CalendarioItem;
  onChange: (v: CalendarioItem) => void;
  control: any;
  parentIndex: number;
}

function InputCalendario({ value, onChange }: InputCalendarioProps) {
  const handleAddHorario = useCallback(() => {
    const newValue = {
      ...value,
      horarios: [...(value?.horarios ?? []), {} as any],
    };

    onChange(newValue);
  }, [onChange, value]);

  const handleChangeHorario = useCallback(
    (index: number, prop: keyof CalendarioItemHorario, v: any) => {
      value.horarios[index][prop] = v;
      onChange({ ...value });
    },
    [value, onChange]
  );

  return (
    <Row style={{ marginBottom: 12, marginTop: 12 }} gutter={32}>
      <Col sm={6}>
        <Flex flex={1} rounded="8px" bg="gray.50" flexDir="column" p="24px">
          <Checkbox
            size="lg"
            justifyContent="start"
            alignItems="start"
            flexDir="column"
            isChecked={value.ativo === 1}
            onChange={() => {
              onChange({
                ...value,
                horarios: value.ativo !== 1 ? [{} as any] : [],
                ativo: value.ativo === 1 ? 0 : 1,
              });
            }}
          >
            <Text
              mt="8px"
              ml="-8px"
              fontSize="1.1em"
              color="gray.700"
              fontWeight="semibold"
            >
              {getDayName(value?.dia)}
            </Text>
          </Checkbox>
        </Flex>
      </Col>
      {value.ativo ? (
        <Col sm={18}>
          <Text fontSize="1.1em" color="gray.600">
            Intervalo de tempo de aulas
          </Text>

          <Flex gap="16px">
            {value?.horarios.map((item, index) => (
              <Flex
                key={index}
                ml={index !== 0 ? "16px" : undefined}
                w="200px"
                gap="8px"
                mt="24px"
              >
                <Flex flex={1} flexDir="column">
                  <Text pl={index !== 0 ? "6px" : undefined}>De</Text>
                  <Flex
                    borderLeft={index !== 0 ? "1px solid #ccc" : undefined}
                    pl={index !== 0 ? "6px" : undefined}
                  >
                    <Input
                      value={item.inicio}
                      type="time"
                      onChange={(e) =>
                        handleChangeHorario(index, "inicio", e.target.value)
                      }
                    />
                  </Flex>
                </Flex>
                <Flex pt="28px">-</Flex>
                <Flex flex={1} flexDir="column">
                  <Text>Até</Text>
                  <Input
                    value={item.fim}
                    type="time"
                    onChange={(e) =>
                      handleChangeHorario(index, "fim", e.target.value)
                    }
                  />
                </Flex>
              </Flex>
            ))}

            <Flex mt="45px" borderLeft="1px solid #ccc" ml="24px" pl="6px">
              <Button
                disabled={value?.horarios.length === 3}
                onClick={() => handleAddHorario()}
                border={"1px dashed #ccc"}
                bg="gray.100"
                color="gray.500"
              >
                <BsPlus size="20px" />
              </Button>
            </Flex>
          </Flex>
        </Col>
      ) : null}
    </Row>
  );
}

export default InputCalendario;
