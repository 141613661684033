import React, { useState, useMemo } from "react";
import { Dropdown, Icon } from "rsuite";

import usePerfis from "data/perfil/usePerfis";
import usePerfilExcluir from "data/perfil/usePerfilExcluir";

import history from "infra/history";
import { showConfirm } from "infra/alerta";
import colors from "infra/resources/theme/colors";

import Table from "components/Table";
import PageHeader from "components/Layout/PageHeader";

import HeaderTable from "./components/HeaderTable";
import ModalFiltros from "./components/ModalFiltros";
import CheckPermission from "ui/components/CheckPermission";
import PERMISSOES from "infra/resources/enums/permissoes";

function PerfilListagemPage() {
  const { isLoading, data, params, setParams } = usePerfis();
  const { isLoading: isLoadingExcluir, handleExcluir } = usePerfilExcluir();

  const [openFiltro, setOpenFiltro] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "PERFIL",
        accessor: "nome",
      },
      {
        accessor: "id",
        Cell: ({ row: { original } }: any) => {
          return (
            <Dropdown
              menuStyle={{
                backgroundColor: "#56626E",
                padding: "6px 16px",
              }}
              renderTitle={() => (
                <div
                  style={{
                    padding: 8,
                    textAlign: "center",
                  }}
                >
                  <Icon
                    style={{ color: colors.black, cursor: "pointer" }}
                    icon="ellipsis-v"
                  />
                </div>
              )}
            >
              <CheckPermission permissoes={[PERMISSOES.PERFIL.ALTERAR]}>
                <Dropdown.Item
                  onSelect={() =>
                    history.push(`/admin/configuracao/perfis/${original.id}`)
                  }
                >
                  <span style={{ color: "white" }}>Editar</span>
                </Dropdown.Item>
              </CheckPermission>

              <CheckPermission permissoes={[PERMISSOES.PERFIL.EXCLUIR]}>
                <Dropdown.Item
                  onSelect={() => {
                    showConfirm().then(({ isConfirmed }) => {
                      if (isConfirmed) {
                        return handleExcluir(original.id);
                      }
                    });
                  }}
                >
                  <span style={{ color: "white" }}>Excluir</span>
                </Dropdown.Item>
              </CheckPermission>
            </Dropdown>
          );
        },
      },
    ],
    [handleExcluir]
  );

  return (
    <div>
      <ModalFiltros
        open={openFiltro}
        onClose={() => setOpenFiltro(false)}
        onFilter={(v: any) => {
          setParams((prev: any) => ({ ...prev, ...v }));
          setOpenFiltro(false);
        }}
      />

      <PageHeader>
        <PageHeader.Left
          title="Perfis de acesso"
          icon={<i className="mdi mdi-2x mdi-lock" />}
        />
      </PageHeader>

      <div>
        <br />
        <br />
        <Table
          loading={isLoading || isLoadingExcluir}
          header={() => (
            <HeaderTable
              onFilter={(v: any) =>
                setParams((prev: any) => ({ ...prev, page: 1, ...v }))
              }
            />
          )}
          columns={columns}
          data={data?.data ?? []}
          total={data?.total || 0}
          page={params?.page || 0}
          perPage={params?.per_page || 0}
          onChange={(values: any) =>
            setParams((prev: any) => ({
              ...prev,
              page: values.page,
              per_page: values.per_page,
            }))
          }
        />
        <br />
        <br />
      </div>
    </div>
  );
}

export default PerfilListagemPage;
