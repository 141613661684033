import { Text } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import InputCalendario from "./InputCalendario";

function CalendarioAula({ control }: any) {
  return (
    <div>
      <Text fontSize="1.3em" color="gray.600">
        Dias de Aula na Semana
      </Text>

      <Controller
        render={({ value, onChange }) => (
          <InputCalendario
            control={control}
            value={value}
            onChange={onChange}
            parentIndex={0}
          />
        )}
        control={control}
        name="calendarios.0"
      />

      <hr />

      <Controller
        render={({ value, onChange }) => (
          <InputCalendario
            control={control}
            value={value}
            onChange={onChange}
            parentIndex={1}
          />
        )}
        control={control}
        name="calendarios.1"
      />

      <hr />

      <Controller
        render={({ value, onChange }) => (
          <InputCalendario
            control={control}
            value={value}
            onChange={onChange}
            parentIndex={2}
          />
        )}
        control={control}
        name="calendarios.2"
      />

      <hr />

      <Controller
        render={({ value, onChange }) => (
          <InputCalendario
            control={control}
            value={value}
            onChange={onChange}
            parentIndex={3}
          />
        )}
        control={control}
        name="calendarios.3"
      />

      <hr />

      <Controller
        render={({ value, onChange }) => (
          <InputCalendario
            control={control}
            value={value}
            onChange={onChange}
            parentIndex={4}
          />
        )}
        control={control}
        name="calendarios.4"
      />

      <hr />

      <Controller
        render={({ value, onChange }) => (
          <InputCalendario
            control={control}
            value={value}
            onChange={onChange}
            parentIndex={5}
          />
        )}
        control={control}
        name="calendarios.5"
      />

      <hr />

      <Controller
        render={({ value, onChange }) => (
          <InputCalendario
            control={control}
            value={value}
            onChange={onChange}
            parentIndex={6}
          />
        )}
        control={control}
        name="calendarios.6"
      />
    </div>
  );
}

export default CalendarioAula;
