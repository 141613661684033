import styled from "styled-components";

export const PageContainerWrapper = styled.div`
  margin-top: 16px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.05);
  padding: 24px;
  padding-top: 48px;

  background-color: white;
  border-radius: 8px;
`;
