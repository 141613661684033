import { useEffect, useState } from "react";
import {
  Button,
  Col,
  ControlLabel,
  Divider,
  Form,
  FormControl,
  FormGroup,
  Input,
  Modal,
  Row,
} from "rsuite";
import colors from "infra/resources/theme/colors";
import { showConfirm, showError } from "infra/alerta";
import { Box, Flex, Text } from "@chakra-ui/react";
import SelectTipoArquivo from "ui/components/Input/SelectTipoArquivo";
import {
  MdDeleteOutline,
  MdOutlineDelete,
  MdPhoto,
  MdTextSnippet,
} from "react-icons/md";

import { BsFillPlayBtnFill } from "react-icons/bs";
import { BASE_URL_API } from "infra/config";
import http from "infra/axios";
import { Empty } from "antd";

export function getIconFromType(type: number) {
  type = +type;

  console.log("type", type);

  if (type === 1) {
    return <MdTextSnippet fontSize={"22px"} color={colors.black700} />;
  }

  if (type === 2) {
    return <MdPhoto fontSize={"22px"} color={colors.black700} />;
  }
  if (type === 3) {
    return <BsFillPlayBtnFill fontSize={"22px"} color={colors.black700} />;
  }
}

function ModalAssunto({
  open,
  value,
  onClose,
  onSave,
  onDelete,
}: {
  open: boolean;
  value?: any;
  onClose: Function;
  onSave: Function;
  onDelete: Function;
}) {
  const [loading, setLoading] = useState<boolean>(false);

  const [form, setForm] = useState<any>({});
  const [formArquivo, setFormArquivo] = useState<{
    tipo_arquivo_id?: string;
    arquivo?: any;
    nome?: string;
  }>({});

  useEffect(() => {
    if (open) {
      setForm({ arquivos: [], ...value });
    } else {
      setForm({});
    }
  }, [value, open]);

  const handleRemoveArquivo = async (index: number) => {
    const result = await showConfirm("Deseja realmente excluir o arquivo?");

    if (result.isConfirmed) {
      setForm((prev: any) => ({
        ...prev,
        arquivos: form.arquivos.filter((_a: any, i: number) => i !== index),
      }));
    }
  };

  const handleAddArquivo = async () => {
    if (!formArquivo.nome) {
      return showError("Informe o nome do arquivo");
    }

    if (!formArquivo.tipo_arquivo_id) {
      return showError("Informe o tipo do arquivo");
    }

    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("file", formArquivo.arquivo);
      formData.append("nome", formArquivo.nome!);
      formData.append("tipo_arquivo_id", formArquivo.tipo_arquivo_id!);

      const {
        data: { data },
      } = await http.post(`${BASE_URL_API}/api/arquivo/upload`, formData);

      form.arquivos = form.arquivos || [];

      form.arquivos.push({
        id: +new Date(),
        new: true,
        arquivo_id: data.id,
        nome: formArquivo.nome,
        caminho: data.url,
        tipo_arquivo_id: formArquivo.tipo_arquivo_id,
      });

      setForm(form);
      setFormArquivo({ nome: "", tipo_arquivo_id: null, arquivo: null } as any);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header onHide={() => onClose()}>
        <Modal.Title>{value?.id ? "Editar" : "Criar"} Assunto</Modal.Title>
      </Modal.Header>

      <Divider />

      <Modal.Body>
        <Form fluid>
          <div>
            <FormGroup>
              <ControlLabel>Titulo do Assunto</ControlLabel>

              <FormControl
                value={form.nome}
                placeholder="Titulo do Assunto"
                onChange={(v) => setForm((prev: any) => ({ ...prev, nome: v }))}
              />
            </FormGroup>
          </div>

          <Box mt={"24px"} mb="12px">
            <Text fontSize="18px" color={colors.black600} mb={2}>
              Materiais do Assunto
            </Text>
          </Box>

          <Box
            borderStyle="dashed"
            borderWidth="1px"
            borderColor={colors.black600}
            rounded="4px"
            p="16px"
            pt="0px"
            mt="24px"
          >
            <Text
              bg="white"
              px="6px"
              pos="relative"
              top="-12px"
              d="inline"
              fontSize="lg"
              fontWeight={"bold"}
              color={colors.primary}
            >
              Adicionar Materiais
            </Text>

            <Box>
              <Row style={{ marginBottom: "16px" }}>
                <Col sm={8}>
                  <FormGroup>
                    <ControlLabel>Tipo do arquivo</ControlLabel>
                    <SelectTipoArquivo
                      mode="single"
                      value={formArquivo.tipo_arquivo_id}
                      onChange={(v: string) =>
                        setFormArquivo((prev) => ({
                          ...prev,
                          tipo_arquivo_id: v,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm={16}>
                  <FormGroup>
                    <ControlLabel>Importar arquivo</ControlLabel>
                    <Input
                      type="file"
                      accept="image/*,application/pdf,video/mp4,video/mov"
                      onChange={(_, e: any) =>
                        setFormArquivo((prev) => ({
                          ...prev,
                          arquivo: e.target.files?.[0],
                        }))
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col sm={18}>
                  <FormGroup>
                    <ControlLabel>Nome do arquivo</ControlLabel>
                    <Input
                      value={formArquivo.nome}
                      onChange={(v: string) =>
                        setFormArquivo((prev) => ({
                          ...prev,
                          nome: v,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <Box mt="23px">
                    <Button
                      loading={loading}
                      disabled={!formArquivo.arquivo || loading}
                      onClick={() => handleAddArquivo()}
                      style={{ width: "100%" }}
                      size="sm"
                      color="red"
                    >
                      {!loading ? (
                        <i
                          className="mdi mdi-plus"
                          style={{ color: "white", marginRight: 8 }}
                        />
                      ) : null}
                      Adicionar
                    </Button>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Box>

          <Box mt="24px">
            <Text
              ml="24px"
              fontSize="lg"
              fontWeight={"bold"}
              color={colors.primary}
            >
              Materiais Adicionados
            </Text>

            <Flex flexDir="column" mt="8px">
              {form.arquivos?.map((item: any, index: number) => (
                <Flex key={index} borderBottom="1px solid #eee" py="6px">
                  <Flex
                    px="8px"
                    alignItems={"center"}
                    onClick={() => window.open(item.caminho)}
                    cursor="pointer"
                  >
                    {getIconFromType(item.tipo_arquivo_id)}
                  </Flex>
                  <Flex flex={1} alignItems={"center"}>
                    {item.nome}
                  </Flex>
                  <Flex alignItems={"center"}>
                    <Button
                      size="xs"
                      onClick={() => handleRemoveArquivo(index)}
                      style={{ paddingTop: 6, paddingBottom: 6 }}
                    >
                      <MdOutlineDelete
                        fontSize={"18px"}
                        color={colors.black500}
                      />
                    </Button>
                  </Flex>
                </Flex>
              ))}

              {!form.arquivos?.length ? (
                <Empty description="Nenhum material adicionado" />
              ) : null}
            </Flex>
          </Box>
        </Form>
      </Modal.Body>
      <Divider />
      <Modal.Footer>
        {value?.id ? (
          <Button
            style={{ float: "left" }}
            onClick={() => {
              showConfirm("Deseja realmente excluir o assunto?").then((result) => {
                if (result.isConfirmed) {
                  onDelete(form);
                }
              });
            }}
            color="red"
            appearance="link"
          >
            <Flex flex={1}>
              <Flex alignItems={"center"}>
                <MdDeleteOutline
                  style={{
                    marginRight: 6,
                    float: "left",
                    color: colors.red500,
                  }}
                />
              </Flex>
              <Flex alignItems={"center"}>Excluir</Flex>
            </Flex>
          </Button>
        ) : null}

        <Button onClick={() => onClose()} appearance="ghost">
          CANCELAR
        </Button>
        <Button onClick={() => onSave(form)} appearance="primary">
          APLICAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAssunto;
