import PERMISSOES from "infra/resources/enums/permissoes";
import { Route, Switch } from "react-router-dom";
import RouteAuthorize from "ui/components/RouteAuthorize";
import CategoriasPages from "./CategoriasPages";
import PerfisPages from "./PerfisPages";
import UsuariosPages from "./UsuariosPages";

function ConfiguracaoPages() {
  return (
    <Switch>
      <Route
        path="/admin/configuracao/categorias"
        component={CategoriasPages}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.PERFIL.VISUALIZAR]}
        path="/admin/configuracao/perfis"
        component={PerfisPages}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.USUARIO.VISUALIZAR]}
        path="/admin/configuracao/usuarios"
        component={UsuariosPages}
      />
    </Switch>
  );
}

export default ConfiguracaoPages;
