import { Icon, IconButton } from "rsuite";
import { useState, useMemo, useEffect, useContext } from "react";

import history from "infra/history";
import { showConfirm } from "infra/alerta";

import Table from "components/Table";
import PageHeader from "components/Layout/PageHeader";

import HeaderTable from "./components/HeaderTable";
import ModalFiltros from "./components/ModalFiltros";

import PERMISSOES from "infra/resources/enums/permissoes";
import { AuthContext } from "contexts/AuthContext";
import useProblemas from "data/problema/useProblemas";
import useProblemaExcluir from "data/problema/useProblemaExcluir";

function ProblemasListagemPage() {
  const { data, isLoading, params, setParams } = useProblemas();
  const { isLoading: isLoadingExcluir, handleExcluir } = useProblemaExcluir();

  const [openFiltro, setOpenFiltro] = useState(false);

  const permissions = useMemo(
    () => [
      PERMISSOES.PROBLEMAS.ALTERAR,
      PERMISSOES.PROBLEMAS.EXCLUIR,
      PERMISSOES.PROBLEMAS.ADICIONAR,
    ],
    []
  );
  const [currentPermissions, setCurrentPermissions] = useState<string[]>([]);

  const { state } = useContext(AuthContext);

  useEffect(() => {
    permissions.map((permission) => {
      return setCurrentPermissions((current) => [...current, permission]);
    });
  }, [state, permissions]);

  function generateFilteredResults(query: any) {
    setParams((prev: any) => {
      return { ...prev, ...query, page: 1 };
    });
    setOpenFiltro(false);
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Titulo",
        accessor: "titulo",
      },
      {
        accessor: "id-2",
        Cell: ({ row: { original } }: any) => {
          return (
            <div>
              {currentPermissions.includes(PERMISSOES.PROBLEMAS.ALTERAR) && (
                <IconButton
                  onClick={() => {
                    history.push({
                      pathname: `/admin/administracao/problemas/${original.id}`,
                      search: window.location.search,
                    });
                  }}
                  style={{ marginRight: 16 }}
                  icon={<Icon icon="edit" />}
                />
              )}

              {currentPermissions.includes(PERMISSOES.PROBLEMAS.EXCLUIR) && (
                <IconButton
                  onClick={() => {
                    showConfirm().then(async ({ isConfirmed }) => {
                      if (isConfirmed) {
                        const res = await handleExcluir(original.id);

                        if (res) {
                          setParams((prev: any) => ({
                            ...prev,
                            updated_at: +new Date(),
                          }));
                        }
                      }
                    });
                  }}
                  icon={<Icon icon="trash-o" />}
                />
              )}
            </div>
          );
        },
      },
    ],
    [handleExcluir, setParams, currentPermissions]
  );

  return (
    <div>
      <ModalFiltros
        open={openFiltro}
        value={params}
        onClose={() => setOpenFiltro(false)}
        onFilter={(q: any) => generateFilteredResults(q)}
      />

      <PageHeader>
        <PageHeader.Left
          title="Problemas"
          icon={<i className="mdi mdi-2x mdi-book-variant-multiple" />}
        />
      </PageHeader>

      <div>
        <br />
        <br />
        <Table
          loading={isLoading || isLoadingExcluir}
          header={() => (
            <HeaderTable
              onOpenFilter={() => setOpenFiltro(true)}
              value={params.query}
              onSearch={(v: string) =>
                setParams((prev: any) => ({
                  ...prev,
                  query: v,
                  page: 1,
                }))
              }
              permissions={currentPermissions}
            />
          )}
          columns={columns}
          data={data?.data ?? []}
          total={data?.total || 0}
          page={params?.page || 0}
          perPage={params?.per_page || 0}
          onChange={(values: any) =>
            setParams((prev: any) => ({
              ...prev,
              page: values.page,
              per_page: values.per_page,
            }))
          }
        />
        <br />
        <br />
      </div>
    </div>
  );
}

export default ProblemasListagemPage;
