import { useState } from "react";
import {
  Button,
  Col,
  ControlLabel,
  Divider,
  Form,
  FormGroup,
  Modal,
  Row,
} from "rsuite";
import SelectQuestao from "components/Input/SelectQuestao";

import SelectBase from "components/Input/SelectBase";

interface Questao {
  id: number;
  titulo: string;
}

function ModalFiltros({
  open,
  onClose,
  onFilter,
  value,
}: {
  open: boolean;
  onClose: Function;
  onFilter: Function;
  value: any;
}) {
  const [form, setForm] = useState<any>({
    order_by: "nome",
    ...value,
  });

  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header onHide={() => onClose()}>
        <Modal.Title>Refinar</Modal.Title>
      </Modal.Header>

      <Divider />

      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <ControlLabel>
              <strong>ORDENAÇÃO</strong>
            </ControlLabel>

            <SelectBase
              isClearable
              placeholder="Padrão (Nome)"
              value={form.order_by}
              onChange={(value: any) =>
                setForm((prev: any) => ({ ...prev, order_by: value }))
              }
              options={[
                {
                  value: "titulo",
                  label: "Titulo do Curso",
                },
              ]}
            />
          </FormGroup>

          <strong>FILTROS</strong>

          <div style={{ marginTop: 16 }}>
            <Row gutter={16} style={{ marginTop: 32, marginBottom: 56 }}>
              <Col md={24}>
                <FormGroup>
                  <ControlLabel>Questões</ControlLabel>
                  <SelectQuestao
                    placeholder="Todas questões"
                    isMulti
                    value={form.questoes ?? []}
                    onChange={(value: Questao[]) => {
                      setForm((prev: any) => ({ ...prev, questoes: value }));
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>
      <Divider />
      <Modal.Footer>
        <Button onClick={() => onClose()} appearance="ghost">
          CANCELAR
        </Button>
        <Button onClick={() => onFilter(form)} appearance="primary">
          APLICAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFiltros;
