import { useEffect, useState } from "react";
import { parse } from "query-string";
import { useQueryParams } from "use-query-params";
import axios from "infra/axios";

export default function useQuestoes() {
  const [params, setParams] = useState<any>(() => {
    const p: any = parse(window.location.search);

    const modulos = (p.modulos ? p.modulos?.split(",") : []).map((item: any) =>
      Number(item)
    );

    const assuntos = (p.assuntos
      ? p.assuntos?.split(",")
      : []
    ).map((item: any) => Number(item));

    const cursos = (p.cursos ? p.cursos?.split(",") : []).map((item: any) =>
      Number(item)
    );

    const situacao = p.situacao ? p.situacao?.split(",") : [];

    return {
      ...p,
      page: Number(p.page) || 1,
      per_page: Number(p.per_page) || 15,
      modulos: modulos,
      assuntos: assuntos,
      cursos: cursos,
      situacao: situacao,
    };
  });

  const [, setQuery] = useQueryParams({});

  const [data, setData] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        let filtros = { ...params };

        if (filtros.ativo) {
          filtros.ativo = Number(filtros.ativo) || undefined;

          if (filtros.ativo === undefined) {
            delete filtros.ativo;
          }
        }

        const { data } = await axios.get("/api/questao", {
          params: filtros,
        });

        setQuery(filtros);

        setData(data.data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
      // TODO ...
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return {
    isLoading,
    data,
    params,
    setParams,
  };
}
