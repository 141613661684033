import { useEffect, useMemo, useState } from "react";
import http from "infra/axios";
import { Checkbox, Spin, Table } from "antd";
import { ProblemaItem } from "data/problema/types";
import { Flex, Input } from "@chakra-ui/react";
import colors from "infra/resources/theme/colors";

function TabProblemas({ value, onChange, active }: any) {
  const [data, setData] = useState<ProblemaItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [naoPossuiProblema, setNaoPossuiProblema] = useState<boolean>(!value?.length);
  const [search, setSearch] = useState<any>("");

  useEffect(() => {
    (async () => {
      try {
        setData([]);

        if (!active) {
          return;
        }

        setIsLoading(true);

        const { data: result } = await http.get("api/problema", {
          params: {
            per_page: 1000,
          },
        });

        setData(result.data.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [active]);

  const items = useMemo(() => {
    if (search) {
      return data.filter((item) =>
        item.titulo.toLowerCase().includes(search.toLowerCase())
      );
    }

    return data;
  }, [data, search]);

  if (!active) {
    return null;
  }

  return (
    <>
      <Flex fontSize="18px" gridGap={"6px"} color="gray.600">
        Selecione o <strong>Problema</strong> em que a questão seja inserida
      </Flex>

      <br />

      {isLoading ? <Spin /> : null}

      <Flex mb={8} bg={colors.black100} p="16px">
        <Flex>
          <Checkbox
            checked={naoPossuiProblema}
            onChange={() => setNaoPossuiProblema((prev) => !prev)}
          >
            Não possui problema para essa questão no momento.
          </Checkbox>
        </Flex>
      </Flex>

      {!naoPossuiProblema ? (
        <>
          <Flex flex={1} alignItems={"center"} mb={8}>
            <Flex color={colors.black600} fontSize={18} flex={1}>
              Problemas cadastrados
            </Flex>
            <Flex>
              <Input
                type="search"
                onChange={(ev) => setSearch(ev.target.value)}
                value={search}
                placeholder="Procurar"
                w="350px"
                rounded={"full"}
                bg="#F3F6F9"
              />
            </Flex>
          </Flex>
          <Table
            columns={[
              {
                render: (item) => `${item.titulo} #${item.id}`,
              },
            ]}
            rowKey="id"
            showHeader={false}
            rowSelection={{
              onChange(selectedRowKeys, selectedRows) {
                console.log("selectedRowKeys", selectedRowKeys);
                onChange(selectedRows);
              },
              selectedRowKeys: value?.map((item: any) => item.id),
            }}
            dataSource={items}
            pagination={false}
            scroll={{ y: 500 }}
          />
        </>
      ) : null}

      {/* {cursos?.map((curso: any) =>
        getModulos(curso).map((modulo: any) => (
          <div style={{ marginBottom: 16 }}>
            <ContentScroll
              title={
                <div>
                  ({curso.tipo}) {curso.nome} / <strong>{modulo.nome}</strong>
                </div>
              }
              openTitle={modulo.id === idOpen}
              onCloseTitle={() => {
                setIdOpen((prev: any) =>
                  prev === modulo.id ? null : modulo.id
                );
              }}
              data={getAssuntos(modulo)}
              value={value}
              onChange={onChange}
              countCheckeds={
                value.filter(
                  (assunto: any) => assunto.curso_modulo_id === modulo.id
                ).length
              }
            />
          </div>
        ))
      )} */}
    </>
  );
}

export default TabProblemas;
