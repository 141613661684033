import { FlexboxGrid } from "rsuite";

import {
  PageHeaderIcon,
  PageHeaderTitle,
  PageHeaderSubtitle,
  PageHeaderWrapper,
} from "./styles";

function PageHeader({ children }: any) {
  return (
    <PageHeaderWrapper>
      <FlexboxGrid>{children}</FlexboxGrid>
    </PageHeaderWrapper>
  );
}
function PageHeaderLeft({ icon, title, subtitle, onClickTitle }: any) {
  return (
    <FlexboxGrid.Item style={{ flex: 1 }}>
      {icon ? <PageHeaderIcon>{icon}</PageHeaderIcon> : null}
      {title ? (
        <PageHeaderTitle
          style={{
            cursor: typeof onClickTitle === "function" ? "pointer" : "inherit",
          }}
          onClick={onClickTitle}
        >
          {title}
        </PageHeaderTitle>
      ) : null}
      {subtitle ? (
        <PageHeaderSubtitle>
          {" "}
          {">"} {subtitle}
        </PageHeaderSubtitle>
      ) : null}
    </FlexboxGrid.Item>
  );
}

function PageHeaderRight({ children }: any) {
  return <FlexboxGrid.Item>{children}</FlexboxGrid.Item>;
}

PageHeader.Left = PageHeaderLeft;
PageHeader.Right = PageHeaderRight;

export default PageHeader;
