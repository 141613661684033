import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "../../components/Layout";
import DashboardPage from "./Dashboard";
import AdministracaoPages from "./Administracao";
import ConfiguracaoPages from "./Configuracao";
import EntidadePages from "./Entidade";

function Admin() {
  return (
    <Layout>
      <Switch>
        <Route path="/admin/configuracao" component={ConfiguracaoPages} />
        <Route path="/admin/administracao" component={AdministracaoPages} />
        <Route path="/admin/entidade" component={EntidadePages} />
        <Route path="/admin/dashboard" component={DashboardPage} />
        <Redirect path="/admin" to="/admin/dashboard" />
      </Switch>
    </Layout>
  );
}

export default Admin;
