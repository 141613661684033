import React, { useState } from "react";
import { Tag } from "rsuite";
import colors from "infra/resources/theme/colors";

import Table from "components/Table";
import PageHeader from "components/Layout/PageHeader";
import QuestaoItem from "./components/QuestaoItem";
import useQuestoes from "data/questao/useQuestoes";
import HeaderTable from "./components/HeaderTable";
import ModalFiltros from "./components/ModalFiltros";
import useQuestaoExcluir from "data/questao/useQuestaoExcluir";
import MenuTable from "ui/components/MenuTable";
import { showConfirm } from "infra/alerta";
import { AuthUser } from "infra/resources/types/auth";
import PERMISSOES from "infra/resources/enums/permissoes";
import { useHistory } from "react-router";

function QuestaoListagemPage() {
  const { isLoading, data, setParams, params } = useQuestoes();
  const { isLoading: isLoadingExcluir, handleExcluir } = useQuestaoExcluir();
  const [openFiltro, setOpenFiltro] = useState(false);

  const history = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: "QUESTÃO",
        accessor: "titulo",
        Cell: ({ row: { original } }: any) => {
          return <QuestaoItem item={original} />;
        },
      },
      {
        Header: "SITUAÇÃO",
        accessor: "situacao",
        Cell: ({ row: { original } }: any) => {
          return (
            <Tag
              style={{
                backgroundColor:
                  original.situacao === "Incompleta"
                    ? colors.warningLight
                    : original.situacao === "Classificada"
                    ? colors.successLight
                    : colors.errorLight,
                color:
                  original.situacao === "Incompleta"
                    ? colors.warning
                    : original.situacao === "Classificada"
                    ? colors.success
                    : colors.error,
              }}
            >
              {original.situacao}
            </Tag>
          );
        },
      },
      {
        accessor: "id",
        Cell: ({ row: { original } }: any) => {
          return (
            <i
              onClick={() => {
                history.push({
                  pathname:
                    "/admin/administracao/questoes/" +
                    original.uuid +
                    "/classificacoes",
                  search: window.location.search,
                });
              }}
              style={{ color: colors.black, cursor: "pointer" }}
              className="mdi mdi-beaker-check-outline"
            />
          );
        },
      },
      {
        accessor: "id2",
        Cell: ({ row: { original } }: any) => {
          return (
            <MenuTable
              actions={[
                {
                  text: "Editar",
                  visible: (user: AuthUser) =>
                    user.permissoes?.includes(PERMISSOES.QUESTAO.ALTERAR),
                  onClick: () =>
                    history.push({
                      pathname: `/admin/administracao/questoes/${original.uuid}`,
                      search: window.location.search,
                    }),
                },
                {
                  text: "Excluir",
                  visible: (user: AuthUser) =>
                    user.permissoes?.includes(PERMISSOES.QUESTAO.EXCLUIR),
                  onClick: () => {
                    showConfirm().then(async ({ isConfirmed }) => {
                      if (isConfirmed) {
                        const result = await handleExcluir(original.uuid);

                        if (result) {
                          setParams((prev: any) => ({
                            ...prev,
                            updatedAt: +new Date(),
                          }));
                        }
                      }
                    });
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [handleExcluir, history, setParams]
  );

  return (
    <div>
      <ModalFiltros
        open={openFiltro}
        onClose={() => setOpenFiltro(false)}
        value={params}
        onFilter={(v: any) => {
          setParams((prev: any) => ({ ...prev, ...v }));
          setOpenFiltro(false);
        }}
      />

      <PageHeader>
        <PageHeader.Left
          title="Questões"
          icon={<i className="mdi mdi-forum" />}
        />
      </PageHeader>

      <div>
        <br />
        <br />
        <Table
          loading={isLoading || isLoadingExcluir}
          header={() => (
            <HeaderTable
              onOpenFilter={() => setOpenFiltro(true)}
              value={params.titulo}
              onSearch={(v: string) =>
                setParams((prev: any) => ({
                  ...prev,
                  titulo: v,
                  page: 1,
                }))
              }
            />
          )}
          columns={columns}
          data={data?.data ?? []}
          total={data?.total || 0}
          page={params?.page || 0}
          perPage={params?.per_page || 0}
          onChange={(values: any) =>
            setParams((prev: any) => ({
              ...prev,
              page: values.page,
              per_page: values.per_page,
            }))
          }
        />
        <br />
        <br />
      </div>
    </div>
  );
}

export default QuestaoListagemPage;
