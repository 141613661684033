import { useEffect, useState } from "react";
import http from "infra/axios";
import SelectBaseAntd from "../SelectBaseAntd";

function SelectModulos({ ...props }) {
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!props.cursos?.length) {
        setItems([]);
        return;
      }

      try {
        setIsLoading(true);

        const { data } = await http.get("api/curso/modulos-cursos", {
          params: {
            cursos: props.cursos,
          },
        });

        setItems(
          data.data.map((item: any) => ({
            label: item.nome,
            value: Number(item.id),
          }))
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [props.cursos]);

  return (
    <SelectBaseAntd
      mode="tags"
      {...props}
      options={items}
      loading={isLoading}
    />
  );
}

export default SelectModulos;
