import React from "react";
import { Controller } from "react-hook-form";
import { Col, ControlLabel, FormControl, Row } from "rsuite";
import SelectPermissao from "ui/components/Input/SelectPermissao";

function InformacoesBasicas({ control }: any) {
  return (
    <div>
      <Row gutter={16}>
        <Col sm={12}>
          <ControlLabel>Nome do Perfil</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Nome do Perfil"
            control={control}
            name="nome"
          />
        </Col>
      </Row>

      <br />

      <Row gutter={16}>
        <Col sm={6}>
          <ControlLabel>Permissões</ControlLabel>
          <Controller
            as={SelectPermissao}
            placeholder="Selecione"
            control={control}
            name="permissoes"
          />
        </Col>
      </Row>
    </div>
  );
}

export default InformacoesBasicas;
