import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AuthClient from "../../../data/auth/authClient";

import LoginPage from "./Login";

function Auth() {
  if (AuthClient.isLogged()) {
    return <Redirect to="/admin" />;
  }

  return (
    <Switch>
      <Route path="/auth/login" component={LoginPage} />
    </Switch>
  );
}

export default Auth;
