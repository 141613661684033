import { useContext } from "react";
import useLogout from "data/auth/useLogout";
import { Icon, Nav, Navbar } from "rsuite";
import { useLayoutContext } from "..";

import logo from "assets/images/logo.svg";

import { IconMenu, IconPower, NavbarWrapper } from "./styles";
import { AuthContext } from "contexts/AuthContext";
import { Flex } from "@chakra-ui/react";

function Appbar() {
  const { toogleIsOpenMenu } = useLayoutContext();
  const { handleLogout } = useLogout();
  const { state } = useContext(AuthContext);

  if (!state.user) {
    return <>Carregando dados</>;
  }

  return (
    <NavbarWrapper>
      <Navbar appearance="subtle">
        <div className="container-fluid">
          <Navbar.Header>
            <Flex>
              <Flex pt={2}>
                <IconMenu onClick={toogleIsOpenMenu}>
                  <i className="mdi mdi-menu" />
                </IconMenu>
              </Flex>
              <Flex pt={2}>
                <img src={logo} alt="Piloto" />
              </Flex>
            </Flex>
          </Navbar.Header>
          <Navbar.Body>
            <Nav pullRight>
              <Nav.Item>
                <div style={{ marginTop: -8 }}>
                  <span style={{ marginRight: 16 }}>
                    {state.user.pessoa?.nome ?? "---"}
                  </span>

                  {!state.user.pessoa?.imagem_url ? (
                    <Icon
                      style={{ top: 6, position: "relative" }}
                      icon="user-circle-o"
                      size="2x"
                    />
                  ) : null}
                  {state.user.pessoa?.imagem_url ? (
                    <img
                      style={{ marginTop: 0, width: 40, maxHeight: 40 }}
                      src={state.user.pessoa.imagem_url}
                      alt="Profile"
                    />
                  ) : null}
                </div>
              </Nav.Item>
              <Nav.Item>
                <IconPower onClick={() => handleLogout()}>
                  <i className="mdi mdi-power" />
                </IconPower>
              </Nav.Item>
            </Nav>
          </Navbar.Body>
        </div>
      </Navbar>
    </NavbarWrapper>
  );
}

export default Appbar;
