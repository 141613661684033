import styled from "styled-components";
import colors from "../../../infra/resources/theme/colors";

export const Title = styled.div<any>`
  background-color: ${colors.black200};
  width: 100%;
  cursor: pointer;
  padding: 10px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: ${(props) => (props.open ? 0 : 8)}px;
  border-bottom-right-radius: ${(props) => (props.open ? 0 : 8)}px;
  font-size: 16px;
  margin-bottom: -2px;
  z-index: 2;
  position: relative;

  flex-direction: row;
  display: flex;
`;

export const ListItem = styled.div`
  background-color: white;
  padding: 0 16px;
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 8px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: linear all 0.2s;
  flex-direction: row;
  display: flex;

  .rs-checkbox {
    margin: 0;
  }

  .rs-checkbox-checker {
    min-height: auto;
    margin: 0;
    margin-top: -8px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &:hover {
    box-shadow: 0 0 16px 6px rgba(0, 0, 0, 0.15);
  }
`;

export const ContainerScroll = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;

  &:before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 16px;
    bottom: 0;
    right: 0;
    box-shadow: -4px 0px 16px 8px rgba(0, 0, 0, 0.2);
  }
`;

export const ContainerScrollInner = styled.div`
  background-color: #f7f7f7;
  padding: 16px;
  border-radius: 8px;
  overflow-x: scroll;
  position: relative;
  width: 100%;
`;

export const Linha = styled.div<any>`
  width: ${(props) => props.width}px;
  display: flex;
  flex-direction: row;
`;

export const Coluna = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;
