import PageHeader from "../../../components/Layout/PageHeader";

function HomePage() {
  return (
    <div>
      <PageHeader>
        <PageHeader.Left
          title="Dashboard"
          icon={<i className="mdi mdi-view-dashboard" />}
        />
      </PageHeader>
      <div></div>
    </div>
  );
}

export default HomePage;
