import { useCheckPermission } from "data/shared/useCheckPermission";
import { navigate } from "infra/router";
import { useEffect } from "react";
import { Route } from "react-router";

function RouteAuthorize({ permissoes, ...props }: any) {
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (permissoes && !checkPermission(permissoes)) {
      navigate("/");
    }
  }, [checkPermission, permissoes]);

  return <Route {...props} />;
}

export default RouteAuthorize;
