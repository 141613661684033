import PERMISSOES from "infra/resources/enums/permissoes";
import { useHistory } from "react-router-dom";
import { Button, Icon, Input, InputGroup } from "rsuite";
import CheckPermission from "ui/components/CheckPermission";

function HeaderTable({ onFilter }: any) {
  const history = useHistory();

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: 1 }}>
        <InputGroup inside style={{ maxWidth: 350, width: "100%" }}>
          <Input
            placeholder="Procurar"
            onPressEnter={(ev: any) => onFilter({ search: ev.target.value })}
            style={{ borderRadius: 16 }}
          />
          <InputGroup.Button>
            <Icon icon="search" />
          </InputGroup.Button>
        </InputGroup>
      </div>

      <div>
        <CheckPermission permissoes={[PERMISSOES.PERFIL.ADICIONAR]}>
          <Button
            color="blue"
            onClick={() => history.push("/admin/configuracao/perfis/novo")}
          >
            <i className="mdi mdi-plus" style={{ color: "white" }} />
            ADICIONAR PERFIL
          </Button>
        </CheckPermission>
      </div>
    </div>
  );
}

export default HeaderTable;
