import { useEffect, useState } from "react";
import http from "infra/axios";
import SelectBaseAntd from "../SelectBaseAntd";

function SelectCurso({ ...props }) {
  const [search, setSearch] = useState<string>();
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const { data } = await http.get("api/curso", {
          params: {
            nome: search,
            per_page: 1000,
          },
        });

        setItems(
          data.data.data.map((item: any) => ({
            label: item.nome,
            value: Number(item.id),
          }))
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [search]);

  return (
    <SelectBaseAntd
      {...props}
      mode={props.mode ?? "tags"}
      options={items}
      loading={isLoading}
      inputValue={search}
      onSelect={() => {
        setSearch("");
      }}
      onSearch={(input: string) => setSearch(input)}
    />
  );
}

export default SelectCurso;
