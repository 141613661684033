import styled from "styled-components";

export const Container = styled.div`
  padding: 0 2px;
  z-index: 999999;
  .select-container > div[class*="control"] {
    background-color: rgb(243 246 249) !important;
    border-color: rgb(243 246 249) !important;
  }

  .select-prefix-react-select__menu {
    z-index: 999999;
  }
`;
