import { IntlProvider } from "rsuite";
import { BrowserRouter, Router, Route } from "react-router-dom";
import ptBR from "rsuite/lib/IntlProvider/locales/pt_BR";
import "sweetalert2/src/sweetalert2.scss";
import { QueryParamProvider } from "use-query-params";
import { ChakraProvider } from "@chakra-ui/react";
import { ConfigProvider as AntdConfigProvider } from "antd";
import antPtBR from "antd/lib/locale/pt_BR";

import Pages from "./ui/pages";
import history from "./infra/history";

import { AuthProvider } from "contexts/AuthContext";

function App(): any {
  return (
    <>
      <AntdConfigProvider locale={antPtBR}>
        <IntlProvider locale={ptBR}>
          <BrowserRouter>
            <AuthProvider>
              <Router history={history}>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <ChakraProvider>
                    <Pages />
                  </ChakraProvider>
                </QueryParamProvider>
              </Router>
            </AuthProvider>
          </BrowserRouter>
        </IntlProvider>
      </AntdConfigProvider>
    </>
  );
}

export default App;
