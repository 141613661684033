import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form } from "rsuite";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Alert } from "antd";
import cloneDeep from "lodash.clonedeep";

import { CursoType } from "infra/resources/types/curso";

import PageContainer from "components/Layout/PageContainer";
import PageHeader from "components/Layout/PageHeader";
import Tabs from "components/Tabs";

import useCurso from "data/curso/useCurso";

import InformacoesBasicas from "./components/InformacoesBasicas";
import Modulos from "./components/Modulos";

function CursoFormularioPage() {
  const history = useHistory();
  const params = useParams<any>();

  const [indexTab, setIndexTab] = useState(0);
  const { handleSave, data, isLoading } = useCurso(params.id);

  const { control, handleSubmit, reset, setValue, watch, errors } =
    useForm<CursoType>();

  useEffect(() => {
    (() => {
      if (data) {
        reset(cloneDeep({ ...data }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleAddModulo = useCallback(() => {
    const modulos = watch("modulos") ?? [];

    modulos.push({
      id: +new Date(),
      new: true,
      assuntos: [],
    } as any);

    setValue("modulos", [...modulos]);
  }, [setValue, watch]);

  const listErrors = useMemo(() => {
    const response = [];

    for (const prop in errors) {
      response.push((errors as any)[prop].message);
    }

    return response;
  }, [errors]);

  return (
    <div style={{ marginBottom: 100 }}>
      <Form onSubmit={(_v, ev: any) => handleSubmit(handleSave)(ev)}>
        <PageHeader>
          <PageHeader.Left
            title="Cursos"
            onClickTitle={() => {
              history.push({
                pathname: `/admin/administracao/cursos`,
                search: window.location.search,
              });
            }}
            subtitle={`${
              params.id ? `Editar ${watch("nome")}` : "Adicionar Novo Curso"
            }`}
            icon={<i className="mdi mdi-forum" />}
          />
          <PageHeader.Right>
            <div>
              <Button
                color="blue"
                disabled={isLoading}
                appearance="ghost"
                componentClass="a"
                style={{ marginRight: 16 }}
                onClick={() => {
                  history.push({
                    pathname: "/admin/administracao/cursos",
                    search: window.location.search,
                  });
                }}
              >
                CANCELAR
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                loading={isLoading}
                color="blue"
              >
                SALVAR
              </Button>
            </div>
          </PageHeader.Right>
        </PageHeader>

        {listErrors.length ? (
          <Alert
            message="Avisos"
            description={
              <div>
                {listErrors.map((error) => (
                  <div>{error}</div>
                ))}
              </div>
            }
            type="error"
            closable
          />
        ) : null}

        <PageContainer style={{ paddingTop: 16 }}>
          <Tabs
            index={indexTab}
            onChange={setIndexTab}
            tabActions={
              <div>
                {indexTab === 1 ? (
                  <Button color="red" onClick={handleAddModulo}>
                    <i
                      className="mdi mdi-plus"
                      style={{ color: "white", marginRight: 8 }}
                    />
                    ADICIONAR MÓDULO
                  </Button>
                ) : null}
              </div>
            }
          >
            <Tabs.TabItem
              title="Informações Básicas"
              component={
                <InformacoesBasicas control={control} isEdit={watch("id")} />
              }
            />

            <Tabs.TabItem
              title="Módulos"
              component={
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Informe algum módulo para o curso",
                    },
                  }}
                  name="modulos"
                  as={Modulos}
                  control={control}
                />
              }
            />
          </Tabs>
        </PageContainer>
      </Form>
    </div>
  );
}

export default CursoFormularioPage;
