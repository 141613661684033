import { Controller } from "react-hook-form";
import { Col, ControlLabel, FormControl, Row } from "rsuite";
  
import SelectCurso from "ui/components/Input/SelectCurso";
import SelectModalidade from "ui/components/Input/SelectModalidade";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";


function InformacoesBasicas({ control }: any) {
  return (
    <div>
      <Row style={{ marginBottom: 12 }} gutter={16}>
        <Col sm={6}>
          <ControlLabel>ID da Turma</ControlLabel>
          <Controller as={FormControl} disabled control={control} name="codigo" />
        </Col>
        <Col sm={18}>
          <ControlLabel>Nome da Turma</ControlLabel>
          <Controller
            as={FormControl}
            control={control}
            name="nome"
            rules={{
              required: {
                value: true,
                message: "Informe o nome",
              },
            }}
          />
        </Col>
      </Row>

      <Row style={{ marginBottom: 12 }} gutter={16}>
        <Col sm={12}>
          <ControlLabel>Curso da Turma</ControlLabel>
          <Controller
            as={SelectCurso}
            mode={'none'}
            control={control}
            name="curso_id"
            rules={{
              required: {
                value: true,
                message: "Informe o curso",
              },
            }}
          />
        </Col>

        <Col sm={6}>
          <ControlLabel>Modalidade</ControlLabel>
          <Controller
            as={SelectModalidade}
            placeholder="Selecione"
            control={control}
            name="modalidade_id"
            rules={{
              required: {
                value: true,
                message: "Informe a modalidade",
              },
            }}
          />
        </Col>

        <Col sm={6}>
          <ControlLabel>Tipo de Curso</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Selecione"
            control={control}
            name="tipo_curso_id"
          />
        </Col>
      </Row>

      <br />

      <Row style={{ marginBottom: 12 }} gutter={16}>
        <Col sm={6}>
          <ControlLabel>Início das Aulas</ControlLabel>
          <Controller
            as={FormControl}
            type="date"
            control={control}
            name="data_inicio"
          />
        </Col>
        <Col sm={6}>
          <ControlLabel>Término das Aulas</ControlLabel>
          <Controller
            as={FormControl}
            type="date"
            control={control}
            name="data_fim"
          />
        </Col>
        <Col sm={12}>
          <ControlLabel>Categoria do Curso</ControlLabel>
          <Controller
            control={control}
            name="categoria"
            render={({onChange, value}) => (
              <RadioGroup onChange={onChange} value={value}>
                <Stack gap="16px" alignItems={'center'} mt="8px" direction="row">
                  <Radio size="lg" value="intensivo">Intensivo</Radio>
                  <Radio size="lg" value="extensivo">Extensivo</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </Col>
      </Row>

         
    </div>
  );
}

export default InformacoesBasicas;
