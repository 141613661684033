
import { Dropdown, Icon } from "rsuite";
import colors from "infra/resources/theme/colors";
import  {AuthContext}  from "contexts/AuthContext";
import { useContext } from "react";

function MenuTable({ actions }: { actions: any[] }) {
  const { state } = useContext(AuthContext);

  return (
    <Dropdown
      menuStyle={{
        backgroundColor: "#56626E",
        padding: "6px 16px",
      }}
      renderTitle={() => (
        <div
          style={{
            padding: 8,
            textAlign: "center",
          }}
        >
          <Icon
            style={{ color: colors.black, cursor: "pointer" }}
            icon="ellipsis-v"
          />
        </div>
      )}
    >
      {actions
        .filter((item) => item.visible(state.user))
        .map((action: any) => (
          <Dropdown.Item onSelect={action.onClick}>
            <span style={{ color: "white" }}>{action.text}</span>
          </Dropdown.Item>
        ))}
    </Dropdown>
  );
}

export default MenuTable;
