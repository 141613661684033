import { useState, useMemo } from "react";

import Table from "components/Table";
import PageHeader from "components/Layout/PageHeader";

import HeaderTable from "./components/HeaderTable";
import ModalFiltros from "./components/ModalFiltros";

import useTurmas from "data/turma/useTurmas";

import { TurmaResponse } from "data/turma/typings";

import TurmaItem from "./components/TurmaItem";

function TurmaListagemPage() {
  const { isLoading, data, params, setParams } = useTurmas();

  const [openFiltro, setOpenFiltro] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "nome",
        Cell: ({ row: { original } }: { row: { original: TurmaResponse } }) => (
          <TurmaItem original={original} />
        ),
      },
    ],
    []
  );

  return (
    <div>
      <ModalFiltros
        open={openFiltro}
        onClose={() => setOpenFiltro(false)}
        onFilter={(v: any) => {
          setParams((prev: any) => ({ ...prev, ...v }));
          setOpenFiltro(false);
        }}
      />

      <PageHeader>
        <PageHeader.Left
          title="Turmas"
          icon={<i className="mdi mdi-2x mdi-users" />}
        />
      </PageHeader>

      <div>
        <br />
        <br />
        <Table
          loading={isLoading}
          header={() => (
            <HeaderTable
              onOpenFilter={() => setOpenFiltro(true)}
              value={params.query}
              onSearch={(v: string) =>
                setParams((prev: any) => ({
                  ...prev,
                  query: v,
                  page: 1,
                }))
              }
            />
          )}
          columns={columns}
          data={data?.data ?? []}
          total={data?.total || 0}
          page={params?.page || 0}
          perPage={params?.per_page || 0}
          onChange={(values: any) =>
            setParams((prev: any) => ({
              ...prev,
              page: values.page,
              per_page: values.per_page,
            }))
          }
        />
        <br />
        <br />
      </div>
    </div>
  );
}

export default TurmaListagemPage;
