import React from "react";
import { Props } from "react-select";

import SelectBase from "../SelectBase";

function SelectTipoEntidade({ ...props }: Props) {
  const options: { value: any; label: any }[] = [];

  options.push({
    label: "Aeroclube",
    value: 2,
  });

  options.push({
    label: "Escola",
    value: 3,
  });

  let value: any = null;

  if (props.value) {
    if (typeof props.value === "string") {
      value = options.find((item) => item.value === props.value);
    } else {
      value = props.value;
    }
  }

  return <SelectBase {...props} value={value} options={options} />;
}

export default SelectTipoEntidade;
