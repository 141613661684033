import { useCallback, useEffect, useState } from "react";

import axios from "infra/axios";
import { navigate } from "infra/router";
import { showError, showSuccess } from "infra/alerta";

export default function useEntidade(id?: any) {
  const [data, setData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);

        try {
          const { data } = await axios.get(`/api/tenant/${id}`);

          setData({
            ...data.data,
          });
        } catch (e) {
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [id]);

  const handleSave = useCallback(
    async (values: any) => {
      setIsLoading(true);

      try {
        const model: any = {
          ...data,
          ...values,
        };

        if (!id) {
          await axios.post("/api/tenant", model);
        } else {
          await axios.put(`/api/tenant/${id}`, model);
        }

        showSuccess().then(() => navigate("/admin/administracao/entidades"));
      } catch (error) {
        const errors = [];

        const data = error.response.data;

        if (data.message) {
          showError();
        } else {
          for (const prop in data) {
            errors.push(data[prop][0]);
          }

          showError(errors[0]);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [data, id]
  );

  return {
    handleSave,
    isLoading,
    data,
  };
}
