import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  ControlLabel,
  Divider,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "rsuite";
import colors from "infra/resources/theme/colors";
import { showConfirm } from "infra/alerta";
import { MdDeleteOutline } from "react-icons/md";
import { Flex } from "@chakra-ui/react";

function ModalModulo({
  open,
  value,
  onClose,
  onSave,
  onDelete,
}: {
  open: boolean;
  value?: any;
  onClose: Function;
  onSave: Function;
  onDelete: Function;
}) {
  const [form, setForm] = useState<any>({});

  useEffect(() => {
    if (open) {
      setForm({ ...value });
    } else {
      setForm({});
    }
  }, [value, open]);

  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header onHide={() => onClose()}>
        <Modal.Title>{value?.id ? "Editar" : "Criar"} Módulo</Modal.Title>
      </Modal.Header>

      <Divider />

      <Modal.Body>
        <Form fluid>
          <div>
            <Row gutter={16}>
              <Col md={24}>
                <FormGroup>
                  <ControlLabel>Titulo do Módulo</ControlLabel>

                  <FormControl
                    value={form.nome}
                    placeholder="Titulo do Módulo"
                    onChange={(v) =>
                      setForm((prev: any) => ({ ...prev, nome: v }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>
      <Divider />
      <Modal.Footer>
        {value?.id ? (
          <Button
            style={{ float: "left" }}
            onClick={() => {
              showConfirm("Deseja realmente excluir ?").then((result) => {
                if (result.isConfirmed) {
                  onDelete(form);
                }
              });
            }}
            color="red"
            appearance="link"
          >
            <Flex flex={1}>
              <Flex alignItems={"center"}>
                <MdDeleteOutline
                  style={{
                    marginRight: 6,
                    float: "left",
                    color: colors.red500,
                  }}
                />
              </Flex>
              <Flex alignItems={"center"}>Excluir</Flex>
            </Flex>
          </Button>
        ) : null}

        <Button onClick={() => onClose()} appearance="ghost">
          CANCELAR
        </Button>
        <Button onClick={() => onSave(form)} appearance="primary">
          APLICAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalModulo;
