import { createContext, useReducer, useEffect } from "react";
import { AuthUser } from "infra/resources/types/auth";
import AuthClient from '../../data/auth/authClient'
type Action =
  | { type: "@AUTH_IS_LOGGED"; payload: boolean }
  | { type: "@AUTH_IS_EXPIRED"; payload: boolean }
  | { type: "@AUTH_LOGIN"; payload: AuthUser }
  | { type: "@AUTH_LOGOUT"; payload: boolean }
  | { type: "@AUTH_REFRESH_TOKEN"; payload: boolean }
  | { type: "@AUTH_REVOKE_TOKEN"; payload: boolean }
  | { type: "@AUTH_SAVE_SESSION"; payload: boolean }
  | { type: "@AUTH_GET_SERVER_DATA"; payload: boolean }
  | { type: "@AUTH_GET_LOCALSTORAGE_DATA"; payload: boolean }
  | { type: "@AUTH_GET_ACCESS_TOKEN"; payload: boolean }
  | { type: "@AUTH_ERROR" }
  | { type: "@AUTH_IS_LOADING"; payload: boolean };

type AppState = typeof initialState;

type Auth = {
  loading: boolean;
  isLogged: boolean;
  isExpired: boolean;
  user?: AuthUser;
  session: {
    success: boolean;
  };
  error: boolean;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

const initialState: Auth = {
  loading: false,
  isLogged: false,
  isExpired: false,
  user: undefined,
  session: {
    success: false,
  },
  error: false,
};

const reducer = (state: AppState, action: Action): AppState => {

  switch (action.type) {
    case "@AUTH_IS_LOGGED":
      return {
        ...state,
        isLogged: action.payload,
      };
    case "@AUTH_IS_EXPIRED":
      return {
        ...state,
        isExpired: action.payload,
      };
    case "@AUTH_LOGIN":
      return {
        ...state,
        user: action.payload,
        error: false,
        isLogged: true,
      };
    case "@AUTH_LOGOUT":
      return {
        ...state,
        isLogged: action.payload,
        user: undefined,
      };
    case "@AUTH_SAVE_SESSION":
      return {
        ...state,
        session: { success: action.payload },
      };
    case "@AUTH_REFRESH_TOKEN":
      return {
        ...state,
        session: { success: action.payload },
      };
    case "@AUTH_REVOKE_TOKEN":
      return {
        ...state,
        session: { success: action.payload },
      };
    case "@AUTH_GET_SERVER_DATA":
      return {
        ...state,
        session: { success: action.payload },
      };
    case "@AUTH_GET_LOCALSTORAGE_DATA":
      return {
        ...state,
        session: { success: action.payload },
      };
    case "@AUTH_GET_ACCESS_TOKEN":
      return {
        ...state,
        session: { success: action.payload },
      };
    case "@AUTH_IS_LOADING": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "@AUTH_ERROR": {
      return {
        ...state,
        error: true,
      };
    }
    default:
      return state;
  }
};

const AuthContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<Action>;
}>({ state: initialState, dispatch: () => {} });

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

useEffect(()=>{
  if(AuthClient.getLocalStorageAuthData()) {
    (async () => { 
   const data = await AuthClient.getServerData().then((data) => {
    dispatch({type: "@AUTH_LOGIN", payload: data as AuthUser})})
   return data
   })()
  }
  }, [dispatch])
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
