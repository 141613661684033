import React, { useEffect, useState } from "react";
import {
  Button,
  ControlLabel,
  Divider,
  Form,
  FormControl,
  FormGroup,
  Modal,
} from "rsuite";

function ModalCategoria({
  open,
  onClose,
  onSave,
  value,
  isLoading,
}: {
  open: boolean;
  onClose: Function;
  onSave: Function;
  value?: any;
  isLoading: boolean;
}) {
  const [form, setForm] = useState<{ nome: string; id: string }>({} as any);

  useEffect(() => {
    if (value) {
      setForm(value);
    }
  }, [value]);

  useEffect(() => {
    if (!open) {
      setForm({} as any);
    }
  }, [open]);

  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header onHide={() => onClose()}>
        <Modal.Title>{form.id ? "Editar" : "Nova"} Categoria</Modal.Title>
      </Modal.Header>

      <Divider />

      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <ControlLabel>
              <strong>Nome da Categoria</strong>
            </ControlLabel>
            <FormControl
              name="nome"
              value={form.nome}
              onChange={(v) => setForm((prev) => ({ ...prev, nome: v }))}
              placeholder="Nome da Categoria"
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Divider />
      <Modal.Footer>
        <Button
          disabled={isLoading}
          onClick={() => onClose()}
          appearance="ghost"
        >
          CANCELAR
        </Button>
        <Button
          loading={isLoading}
          onClick={() => onSave(form)}
          appearance="primary"
        >
          SALVAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCategoria;
