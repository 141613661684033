/* eslint-disable no-lone-blocks */
import { useCallback } from "react";
import { SortableItem3 } from "./SortableItem3";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export default function SortableList3({ value, onChange, ...props }: any) {
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = value[dragIndex];
      onChange(
        update(value, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [onChange, value]
  );

  const renderItem = (item: any, index: number) => {
    return (
      <SortableItem3
        key={item.id}
        index={index}
        id={item.id}
        content={props.renderItem(item, index)}
        moveCard={moveCard}
      />
    );
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {value.map((item: any, i: number) => renderItem(item, i))}
      </DndProvider>
    </>
  );
}
