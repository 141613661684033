import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Divider,
  Row,
  Col,
  Form,
  FormControl,
  ControlLabel,
  Icon,
} from "rsuite";
import PageContainer from "components/Layout/PageContainer";
import PageHeader from "components/Layout/PageHeader";
import QuestaoOpcoes from "./components/QuestaoOpcoes";
import useQuestao from "data/questao/useQuestao";
import Classificacao from "./components/Classificacao";
import cloneDeep from "lodash.clonedeep";
import useQuestaoExcluir from "data/questao/useQuestaoExcluir";
import colors from "infra/resources/theme/colors";
import { showConfirm } from "infra/alerta";

function QuestaoFormularioPage() {
  const history = useHistory();
  const params = useParams<any>();

  const { handleSave, data, isLoading } = useQuestao(params.id);
  const { handleExcluir, isLoading: isLoadingExcluir } = useQuestaoExcluir();

  const { control, reset, handleSubmit } = useForm();

  useEffect(() => {
    (() => {
      if (data !== null) {
        reset(cloneDeep({ ...(data as any) }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const beforeHandleExcluir = (id: any) => {
    showConfirm().then(async (result) => {
      if (result.isConfirmed) {
        const res = await handleExcluir(id);
        if (res) {
          handleVoltar();
        }
      }
    });
  };

  const handleVoltar = () => {
    history.push({
      pathname: "/admin/administracao/questoes",
      search: window.location.search,
    });
  };

  return (
    <div>
      <Form onSubmit={(_v, ev: any) => handleSubmit(handleSave)(ev)}>
        <PageHeader>
          <PageHeader.Left
            title="Questão"
            subtitle="Detalhe da questão"
            icon={<i className="mdi mdi-forum" />}
          />

          <PageHeader.Right>
            <div>
              <Button
                color="blue"
                disabled={isLoading}
                appearance="ghost"
                componentClass="a"
                style={{ marginRight: 16 }}
                onClick={() => handleVoltar()}
              >
                CANCELAR
              </Button>
              <Button
                disabled={isLoading}
                loading={isLoading}
                color="blue"
                type="submit"
              >
                SALVAR
              </Button>
            </div>
          </PageHeader.Right>
        </PageHeader>

        <PageContainer>
          <h3>Informações Básicas</h3>

          <br />

          <Row>
            <Col>
              <ControlLabel>Questão</ControlLabel>
              <Controller
                as={FormControl}
                placeholder="Questão"
                control={control}
                name="titulo"
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col>
              <ControlLabel>Explicação da questão</ControlLabel>
              <Controller
                as={FormControl}
                componentClass="textarea"
                rows={3}
                control={control}
                name="descricao"
                placeholder="Adicione uma explicação"
              />
            </Col>
          </Row>

          <Divider />

          <Controller as={QuestaoOpcoes} control={control} name="opcoes" />

          <Divider />

          {data?.uuid ? (
            <Controller
              as={Classificacao}
              uuid={data.uuid}
              modulos={data.modulos ?? []}
              cursos={data.cursos ?? []}
              control={control}
              name="assuntos"
            />
          ) : null}

          <Divider />
          {data?.id ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <Button
                  color="red"
                  appearance="ghost"
                  disabled={isLoading || isLoadingExcluir}
                  loading={isLoadingExcluir}
                  componentClass="a"
                  onClick={() => beforeHandleExcluir(data?.id)}
                >
                  <Icon
                    icon="trash-o"
                    style={{ marginRight: 6, color: colors.red500 }}
                  />
                  DELETAR QUESTÃO
                </Button>
              </div>
            </div>
          ) : null}
        </PageContainer>
      </Form>
    </div>
  );
}

export default QuestaoFormularioPage;
