import React, { useCallback, useState } from "react";
import { Icon, Input, InputGroup } from "rsuite";
import http from "infra/axios";

function InputCep({ value, onChange, onLoadCep, ...props }: any) {
  const [isLoading, setIsLoading] = useState(false);

  const handleCep = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await http.get(`api/cep/${value}`);

      onLoadCep(data.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [onLoadCep, value]);

  return (
    <InputGroup inside style={{ width: "100%" }}>
      <Input
        value={value}
        onChange={(v) => {
          onChange(v);
        }}
        onPressEnter={() => {
          if (typeof onLoadCep === "function") {
            handleCep();
          }
        }}
        {...props}
      />
      <InputGroup.Button
        loading={isLoading}
        disabled={isLoading}
        onClick={() => {
          if (typeof handleCep === "function") {
            handleCep();
          }
        }}
      >
        {!isLoading ? <Icon icon="search" /> : null}
      </InputGroup.Button>
    </InputGroup>
  );
}

export default InputCep;
