import React from "react";
import { Props } from "react-select";

import SelectBase from "../SelectBase";
import colors from "infra/resources/theme/colors";

function SelectSituacao({ ...props }: Props) {
  const options: { value: any; label: any; color: any }[] = [];

  options.push({
    label: "Ativo",
    value: "Ativo",
    color: colors.green,
  });

  options.push({
    label: "Inativo",
    value: "Inativo",
    color: colors.error,
  });

  options.push({
    label: "Erro",
    value: "Erro",
    color: colors.warning,
  });

  options.push({
    label: "Novo",
    value: "Novo",
    color: colors.blue,
  });

  let value: any = null;

  if (props.value) {
    if (typeof props.value === "string") {
      value = options.find((item) => item.value === props.value);
    } else {
      value = props.value;
    }
  }

  return <SelectBase {...props} value={value} options={options} />;
}

export default SelectSituacao;
