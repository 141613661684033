import { useState } from "react";
import { Button, Icon } from "rsuite";

import colors from "infra/resources/theme/colors";

import { showConfirm, showError } from "infra/alerta";

import ModalAssunto from "./ModalAssunto";
import SortableList3 from "ui/components/SortableList3";
import { SortableItem } from "ui/components/SortableItem";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import { Box, Flex } from "@chakra-ui/react";
import { FiPieChart } from "react-icons/fi";
import { AiOutlineClockCircle } from "react-icons/ai";

import TagFile from "./TagFile";

function ModuloAssuntos({ value, modulo, onChange }: any) {
  const [selected, setSelected] = useState<any>(value[0]);
  const [openModalAssunto, setOpenModalAssunto] = useState(false);

  const getGroupFiles = (assunto: any) => {
    const groups: Record<string, { total: number; tipo: number }[]> = {};

    for (const arquivo of assunto.arquivos) {
      if (!groups[arquivo.tipo_arquivo_id]) {
        groups[arquivo.tipo_arquivo_id] = [];
      }

      groups[arquivo.tipo_arquivo_id].push(arquivo);
    }

    const result = Object.keys(groups).map((key) => ({
      total: groups[key].length,
      tipo: +key,
    }));

    return result;
  };

  return (
    <>
      <ModalAssunto
        open={openModalAssunto}
        value={selected}
        onClose={() => setOpenModalAssunto(false)}
        onDelete={(v: any) => {
          if (v.id) {
            value = value.filter((item: any) => item.id !== v.id);

            onChange([...value]);
          }

          setOpenModalAssunto(false);
        }}
        onSave={(v: any) => {
          if (!v.nome) {
            showError("Informe o nome do assunto");
            return;
          }

          if (v.id) {
            value.forEach((item: any) => {
              if (item.id === v.id) {
                item.nome = v.nome;
              }
            });

            onChange([...value]);
          } else {
            const items = value ?? [];

            onChange([
              ...items,
              { ...v, id: +new Date(), new: true, ordem: items.length },
            ]);
          }

          setOpenModalAssunto(false);
        }}
      />

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, paddingRight: 8, marginBottom: 32 }}>
          <h4>Assuntos do Módulo</h4>
          <span style={{ color: colors.black600 }}>
            Conteúdo programático da matéria
          </span>
        </div>
        <div>
          <Button
            disabled={!modulo?.id}
            onClick={() => {
              setSelected(null);
              setOpenModalAssunto(true);
            }}
            color="red"
            appearance="link"
            style={{
              textTransform: "uppercase",
            }}
          >
            <Icon
              style={{ color: colors.red500, fontSize: 16, marginRight: 8 }}
              icon="plus"
            />
            Adicionar Assunto
          </Button>
        </div>
      </div>

      <div>
        <SortableList3
          value={value ?? []}
          onChange={onChange}
          renderItem={(assunto: any, index: number) => (
            <Flex mb={4}>
              <Flex px="8px" alignItems="center">
                <Box
                  rounded="full"
                  w="25px"
                  h="25px"
                  bg="gray.200"
                  textAlign={"center"}
                  color="gray.400"
                  p="3px"
                  fontSize="12px"
                >
                  {index + 1 <= 9 ? `0${index + 1}` : index + 1}
                </Box>
              </Flex>
              <Flex flex={1}>
                <Box w="100%">
                  <SortableItem
                    id={assunto.id}
                    index={index}
                    key={index}
                    // subtitle={assunto?.nome}
                    render={
                      <Flex flex={1} flexDir="column">
                        <Flex
                          flex={1}
                          alignItems="center"
                          borderBottom={`1px solid ${colors.black200}`}
                        >
                          <Flex flex={1}>{assunto.nome}</Flex>
                          <Flex>
                            <Flex bg={colors.black100} rounded={2} p={2}>
                              <FiPieChart color={colors.orange} />
                            </Flex>
                          </Flex>
                        </Flex>

                        <Flex
                          flex={1}
                          borderBottom={`1px solid ${colors.black200}`}
                          py={1}
                        >
                          <Flex flex={1} mt={2} gap={2}>
                            {getGroupFiles(assunto).map((item, key) => (
                              <TagFile total={item.total} tipo={item.tipo} key={key} />
                            ))}
                          </Flex>
                          <Flex>
                            <Flex alignItems={"center"} gridGap="4px">
                              <AiOutlineClockCircle color={colors.gray} />
                              24h
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    }
                    menus={[
                      {
                        title: "Editar Assunto",
                        icon: <MdEdit />,
                        onClick: () => {
                          setSelected(assunto);
                          setOpenModalAssunto(true);
                        },
                      },
                      {
                        title: "Excluir Assunto",
                        icon: <MdDeleteOutline />,
                        onClick: () => {
                          showConfirm("Deseja realmente excluir ?").then(
                            (result) => {
                              if (result.isConfirmed) {
                                onChange([
                                  ...value.filter(
                                    (_item: any) => _item.id !== assunto.id
                                  ),
                                ]);
                              }
                            }
                          );
                        },
                      },
                    ]}
                  />
                </Box>
              </Flex>
            </Flex>
          )}
        />
      </div>
    </>
  );
}

export default ModuloAssuntos;
