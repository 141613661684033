import PERMISSOES from "infra/resources/enums/permissoes";
import React, { useEffect, useState } from "react";
import { Button, Icon, Input, InputGroup } from "rsuite";
import CheckPermission from "ui/components/CheckPermission";

function HeaderTable({
  onOpenModal,
  onSearch,
  value,
}: {
  onSearch: Function;
  onOpenModal: Function;
  value: string;
}) {
  const [nome, update] = useState<string>();

  useEffect(() => {
    update(value);
  }, [value]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: 1 }}>
        <InputGroup inside style={{ maxWidth: 350, width: "100%" }}>
          <Input
            value={nome}
            onChange={(v) => update(v)}
            placeholder="Procurar"
            onPressEnter={(ev: any) => onSearch(ev.target.value)}
            style={{ borderRadius: 16 }}
          />
          <InputGroup.Button onClick={() => onSearch(nome)}>
            <Icon icon="search" />
          </InputGroup.Button>
        </InputGroup>
      </div>

      <div>
        <CheckPermission permissoes={[PERMISSOES.USUARIO.ADICIONAR]}>
          <Button color="blue" onClick={() => onOpenModal()}>
            <i className="mdi mdi-plus" style={{ color: "white" }} />
            ADICIONAR USUÁRIO
          </Button>
        </CheckPermission>
      </div>
    </div>
  );
}

export default HeaderTable;
