import styled from "styled-components";
import colors from "../../../../infra/resources/theme/colors";

export const NavbarWrapper = styled.div`
  border-bottom: 1px solid #eee;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  .rs-navbar.rs-navbar-subtle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .rs-navbar.rs-navbar-subtle,
  .rs-navbar-header,
  .rs-navbar-body {
    height: auto;
  }

  .rs-navbar-header img {
    border-radius: 8px;
  }

  .mdi {
    font-size: 2em;
    cursor: pointer;
    line-height: 1;
  }
`;

export const IconPower = styled.span`
  * {
    color: ${colors.primary};
    line-height: 1;
  }
`;

export const IconMenu = styled.span`
  display: inline-block;
  position: relative;
  top: 8px;
  margin-right: 32px;
  * {
    color: ${colors.primary};
  }
`;
