import { Divider, Text } from "@chakra-ui/react";
import colors from "infra/resources/theme/colors";
import { Controller } from "react-hook-form";
import { Col, ControlLabel, FormControl, Row } from "rsuite";
import BoxArquivos from "./BoxArquivos";

function InformacoesBasicas({ control }: any) {
  return (
    <div>
      <Row gutter={16}>
        <Col sm={4}>
          <ControlLabel>ID do Curso</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="ID do Curso"
            control={control}
            name="id"
            disabled
          />
        </Col>

        <Col sm={20}>
          <ControlLabel>Titulo do Problema</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Titulo do Problema"
            control={control}
            name="titulo"
          />
        </Col>
      </Row>

      <br />

      <Row>
        <Col sm={24}>
          <ControlLabel>Descrição</ControlLabel>
          <Controller
            as={FormControl}
            componentClass="textarea"
            rows={6}
            control={control}
            name="descricao"
            placeholder="Escreva uma descrição para o problema"
          />
        </Col>
      </Row>

      <Divider my={8} />

      <Text my={6} fontSize="18px" color={colors.black600}>
        Anexos ao problema
      </Text>

      <Controller as={BoxArquivos} name="arquivos" control={control} />
    </div>
  );
}

export default InformacoesBasicas;
