import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Icon, Input, InputGroup, IconButton } from "rsuite";
import colors from "infra/resources/theme/colors";
import PERMISSOES from "infra/resources/enums/permissoes";

function HeaderTable({
  onOpenFilter,
  onSearch,
  value,
  permissions
}: {
  value: any;
  onSearch: Function;
  onOpenFilter: Function;
  permissions?: string[]
}) {
  const history = useHistory();
  const [nome, update] = useState<string>();
  const currentPermissions = permissions || []
  useEffect(() => {
    update(value);
  }, [value]);

  const handleAdicionar = () => {
    history.push({
      pathname: "/admin/administracao/cursos/novo",
      search: window.location.search,
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: 1 }}>
        <InputGroup inside style={{ maxWidth: 350, width: "100%" }}>
          <Input
            value={nome}
            onChange={(v) => update(v)}
            placeholder="Procurar"
            onPressEnter={(ev: any) => onSearch(ev.target.value)}
            style={{ borderRadius: 16 }}
          />
          <InputGroup.Button onClick={() => onSearch(nome)}>
            <Icon icon="search" />
          </InputGroup.Button>
        </InputGroup>
      </div>

      <div>
        <IconButton
          style={{ marginRight: 16 }}
          circle
          appearance="ghost"
          onClick={() => onOpenFilter()}
          icon={<Icon style={{ color: colors.primary }} icon="sliders" />}
        />

        {currentPermissions.includes(PERMISSOES.CURSO.ADICIONAR) && (
          <Button color="blue" onClick={() => handleAdicionar()}>
            <i className="mdi mdi-plus" style={{ color: "white" }} />
            ADICIONAR CURSO
          </Button>
        )}
      </div>
    </div>
  );
}

export default HeaderTable;
