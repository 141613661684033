import React from "react";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

function InputCpfCnpj({ value, onChange }: any) {
  return (
    <div>
      <CpfCnpj
        className="rs-input"
        value={value}
        onChange={(event: any) => {
          onChange(event.target.value);
        }}
      />
    </div>
  );
}

export default InputCpfCnpj;
