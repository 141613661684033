import { Flex } from "@chakra-ui/react"; 
import { getIconFromType } from "./ModalAssunto";

function TagFile({ total, tipo }: { total: number; tipo: number }) {
  return (
    <>
      <Flex h={8} border={`1px solid #CCCCCC`} rounded={4}>
        <Flex justifyContent={"center"} alignItems="center" w={10}>
          {/* <Image src={textBoxIcon} w={6} ringColor="red" /> */}
          {getIconFromType(tipo)}
        </Flex>
        <Flex
          w={10}
          bg={`#efefef`}
          justifyContent={"center"}
          alignItems="center"
          roundedRight={4}
        >
          {total}
        </Flex>
      </Flex>
    </>
  );
}

export default TagFile;
