const {
  REACT_APP_BASE_URL_API,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_KEY_AUTH,
  REACT_APP_NAME,
  REACT_APP_PUBLIC_URL,
} = process.env;

export const BASE_URL_API = REACT_APP_BASE_URL_API ?? 'https://piloto.com.br/api/public/index.php';
export const CLIENT_ID = REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = REACT_APP_CLIENT_SECRET;
export const KEY_AUTH = REACT_APP_KEY_AUTH as string;
export const NAME = REACT_APP_NAME;
export const PUBLIC_URL = REACT_APP_PUBLIC_URL;