import React from "react";
import { Badge, Tag } from "rsuite";
import colors from "infra/resources/theme/colors";

function QuestaoItem({ item }: any) {
  return (
    <div>
      <div style={{ marginBottom: 16 }}>{item.titulo} </div>
      <Tag style={{ backgroundColor: colors.black100 }}>
        Cursos{" "}
        <Badge
          style={{
            backgroundColor: item.total_curso > 0 ? colors.blue : "inhiert",
          }}
          content={item.total_curso}
        />
      </Tag>{" "}
      <Tag style={{ backgroundColor: colors.black100 }}>
        Módulos{" "}
        <Badge
          style={{
            backgroundColor: item.total_modulo > 0 ? colors.blue : "inhiert",
          }}
          content={item.total_modulo}
        />
      </Tag>{" "}
      <Tag style={{ backgroundColor: colors.black100 }}>
        Assuntos{" "}
        <Badge
          style={{
            backgroundColor: item.total_assunto > 0 ? colors.blue : "inhiert",
          }}
          content={item.total_assunto}
        />
      </Tag>{" "}
    </div>
  );
}

export default QuestaoItem;
