import { useCallback, useEffect, useState } from "react";

import { showError, showSuccess } from "infra/alerta";
import http from "infra/axios";
import { useHistory } from "react-router";

export default function useQuestao(id?: any) {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);

        try {
          const { data } = await http.get("/api/questao/" + id);

          setData(data.data);
        } catch (e) {
        } finally {
          setIsLoading(false);
        }
        // TODO ...
      }
    })();
  }, [id]);

  const handleSave = useCallback(
    async (values) => {
      setIsLoading(true);

      try {
        if (!id) {
          await http.post("/api/questao", { ...data, ...values });
        } else {
          await http.put(`/api/questao/${id}`, { ...data, ...values });
        }

        showSuccess().then(() => {
          history.push({
            pathname: "/admin/administracao/questoes",
            search: window.location.search,
          });
        });
      } catch (error) {
        const errors = [];

        const data = error.response.data;

        if (data.message) {
          showError();
        } else {
          for (const prop in data) {
            errors.push(data[prop][0]);
          }

          showError(errors[0]);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [id, data, history]
  );

  return {
    handleSave,
    isLoading,
    data,
  };
}
