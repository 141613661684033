import PERMISSOES from "infra/resources/enums/permissoes";
import { Switch } from "react-router-dom";
import RouteAuthorize from "ui/components/RouteAuthorize";
import EntidadeFormularioPage from "./Formulario";
import EntidadeListagemPage from "./Listagem";

function EntidadesPages() {
  return (
    <Switch>
      <RouteAuthorize
        permissoes={[PERMISSOES.ESCOLA.ADICIONAR]}
        exact
        path="/admin/administracao/entidades/novo"
        component={EntidadeFormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.ESCOLA.ALTERAR]}
        exact
        path="/admin/administracao/entidades/:id"
        component={EntidadeFormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.ESCOLA.VISUALIZAR]}
        path="/admin/administracao/entidades"
        component={EntidadeListagemPage}
      />
    </Switch>
  );
}

export default EntidadesPages;
