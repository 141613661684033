import { useCallback, useEffect, useState } from "react";

import axios from "infra/axios";
import { navigate } from "infra/router";
import { showError, showSuccess } from "infra/alerta";

export default function usePerfil(id?: any) {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);

        try {
          const { data } = await axios.get(`/api/perfil/${id}`);

           if (data?.data.permissoes) {
             data.data.permissoes = data.data.permissoes?.map(
               (item: any) => item.id
             );
           }

          setData({
            ...data.data,
          });
        } catch (e) {
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [id]);

  const handleSave = useCallback(
    async (values: any) => {
      setIsLoading(true);

      try {
        const model: any = {
          ...data,
          ...values,
        };

        if (!id) {
          await axios.post("/api/perfil", model);
        } else {
          await axios.put(`/api/perfil/${id}`, model);
        }

        showSuccess().then(() => navigate("/admin/configuracao/perfis"));
      } catch (e) {
        showError();
      } finally {
        setIsLoading(false);
      }
    },
    [data, id]
  );

  return {
    handleSave,
    isLoading,
    data,
  };
}
