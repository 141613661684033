import React, { useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  ControlLabel,
  Divider,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "rsuite";
import SelectCategoria from "ui/components/Input/SelectCategoria";
import SelectModalidade from "ui/components/Input/SelectModalidade";
import SelectBase from "../../../../../../../components/Input/SelectBase";

function ModalFiltros({
  open,
  onClose,
  onFilter,
}: {
  open: boolean;
  onClose: Function;
  onFilter: Function;
}) {
  const [form, setForm] = useState<any>({});

  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header onHide={() => onClose()}>
        <Modal.Title>Refinar</Modal.Title>
      </Modal.Header>

      <Divider />

      <Modal.Body>
        <Form fluid>
          <FormGroup>
            <ControlLabel>
              <strong>ORDENAÇÃO</strong>
            </ControlLabel>
            <FormControl name="order_by" placeholder="Nome do Curso" />
          </FormGroup>

          <strong>FILTROS</strong>

          <div style={{ marginTop: 16 }}>
            <FormGroup>
              <ControlLabel>Situação</ControlLabel>
              <CheckboxGroup
                style={{ marginLeft: -18 }}
                inline
                value={form.situacao}
                onChange={(value) =>
                  setForm((prev: any) => ({ ...prev, situacao: value }))
                }
              >
                <Row>
                  <Col sm={6}>
                    <Checkbox value="Ativo">Ativo</Checkbox>
                  </Col>
                  <Col sm={6}>
                    <Checkbox value="Inativo">Inativo</Checkbox>
                  </Col>
                  <Col sm={6}>
                    <Checkbox value="Erro">Erro</Checkbox>
                  </Col>
                  <Col sm={6}>
                    <Checkbox value="Novo">Novo</Checkbox>
                  </Col>
                </Row>
              </CheckboxGroup>
            </FormGroup>

            <FormGroup>
              <ControlLabel>Qualificação</ControlLabel>
              <CheckboxGroup
                value={form.qualificacao}
                onChange={(value) =>
                  setForm((prev: any) => ({ ...prev, qualificacao: value }))
                }
                inline
                style={{ marginLeft: -18 }}
              >
                <Row>
                  <Col sm={6}>
                    <Checkbox value="ANAC">ANAC</Checkbox>
                  </Col>
                  <Col sm={6}>
                    <Checkbox value="EXTRA">EXTRA</Checkbox>
                  </Col>
                </Row>
              </CheckboxGroup>
            </FormGroup>

            <Row gutter={16}>
              <Col sm={10} style={{ marginBottom: 16 }}>
                <FormGroup>
                  <ControlLabel>Tipo</ControlLabel>

                  <SelectBase
                    isClearable
                    placeholder="Todas"
                    value={form.tipo}
                    onChange={(value: any) =>
                      setForm((prev: any) => ({ ...prev, tipo: value }))
                    }
                    options={[
                      {
                        value: "Teórico",
                        label: "Teórico",
                      },
                      {
                        value: "Prático",
                        label: "Prático",
                      },
                      {
                        value: "Teórico/Prático",
                        label: "Teórico/Prático",
                      },
                    ]}
                  />
                </FormGroup>
              </Col>
              <Col sm={14} style={{ marginBottom: 16 }}>
                <FormGroup>
                  <ControlLabel>Modalidade</ControlLabel>
                  <SelectModalidade
                    isClearable
                    placeholder="Todas"
                    value={form.modalidade}
                    onChange={(value: any) =>
                      setForm((prev: any) => ({ ...prev, modalidade: value }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row gutter={16} style={{ marginTop: 32, marginBottom: 56 }}>
              <Col md={24}>
                <FormGroup>
                  <ControlLabel>Categoria</ControlLabel>
                  <SelectCategoria
                    placeholder="Todas"
                    value={form.categoria ?? []}
                    onChange={(value: any) =>
                      setForm((prev: any) => ({ ...prev, categoria: value }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>
      <Divider />
      <Modal.Footer>
        <Button onClick={() => onClose()} appearance="ghost">
          CANCELAR
        </Button>
        <Button onClick={() => onFilter(form)} appearance="primary">
          APLICAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFiltros;
