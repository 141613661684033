import { useCallback, useState } from "react";
import axios from "infra/axios";
import { showError } from "infra/alerta";

export default function useUsuario() {
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = useCallback(async (values) => {
    try {
      setIsLoading(true);

      if (values.id) {
        await axios.put(`api/usuario/${values.id}`, values);
      } else {
        await axios.post(`api/usuario`, values);
      }
      setIsLoading(false);

      return { errors: null };
    } catch (error) {
      setIsLoading(false);

      const errors = [];

      const data = error.response.data;

      if (data.message) {
        showError();
      } else {
        for (const prop in data) {
          errors.push(data[prop][0]);
        }
      }

      return {
        errors,
      };
    }
  }, []);

  return {
    handleSave,
    isLoading,
  };
}
