import PERMISSOES from "infra/resources/enums/permissoes";
import { Switch } from "react-router-dom";
import RouteAuthorize from "ui/components/RouteAuthorize";
import EntidadeFormularioPage from "./Formulario";
import EntidadeListagemPage from "./Listagem";

function TurmasPages() {
  return (
    <Switch>
      <RouteAuthorize
        permissoes={[PERMISSOES.TURMA.ADICIONAR]}
        exact
        path="/admin/entidade/turmas/novo"
        component={EntidadeFormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.TURMA.ALTERAR]}
        exact
        path="/admin/entidade/turmas/:id"
        component={EntidadeFormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.TURMA.VISUALIZAR]}
        path="/admin/entidade/turmas"
        component={EntidadeListagemPage}
      />
    </Switch>
  );
}

export default TurmasPages;
