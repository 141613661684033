import { useEffect, useState } from "react";
import http from "infra/axios";
import SelectBaseAntd from "../SelectBaseAntd";

let oldText: any = null;

function SelectCategoriaAntd({ ...props }) {
  const [search, setSearch] = useState<string>();
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (oldText === search) {
          return;
        }

        oldText = search;

        setIsLoading(true);

        const { data } = await http.get("api/categoria", {
          params: {
            nome: search,
            per_page: 100,
          },
        });

        setItems(
          data.data.data.map((item: any) => ({
            label: item.nome,
            value: Number(item.id),
          }))
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [search]);

  return (
    <SelectBaseAntd
      {...props}
      options={items}
      mode={props.mode ?? "tags"}
      loading={isLoading}
      inputValue={search}
      onSelect={() => {
        setSearch("");
      }}
      onSearch={(input: string) => setSearch(input)}
    />
  );
}

export default SelectCategoriaAntd;
