import PERMISSOES from "infra/resources/enums/permissoes";
import { Switch } from "react-router-dom";
import RouteAuthorize from "ui/components/RouteAuthorize";
import PerfilFormularioPage from "./Formulario";
import PerfilListagemPage from "./Listagem";

function PerfisPages() {
  return (
    <Switch>
      <RouteAuthorize
        permissoes={[PERMISSOES.PERFIL.ADICIONAR]}
        exact
        path="/admin/configuracao/perfis/novo"
        component={PerfilFormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.PERFIL.ALTERAR]}
        exact
        path="/admin/configuracao/perfis/:id"
        component={PerfilFormularioPage}
      />
      <RouteAuthorize
        permissoes={[PERMISSOES.PERFIL.VISUALIZAR]}
        path="/admin/configuracao/perfis"
        component={PerfilListagemPage}
      />
    </Switch>
  );
}

export default PerfisPages;
