import { Table } from "antd";
import { showConfirm } from "infra/alerta";
import { Icon, IconButton } from "rsuite";

import EmptyList from "./EmptyList";

function Modulos({ value, onChange }: any) {
  return (
    <div>
      {!value?.length ? <EmptyList /> : null}

      {value?.length ? (
        <Table
          columns={[
            // {
            //   title: "CLASSIFICAÇÃO",
            // },
            {
              title: "QUESTÕES",
              dataIndex: "titulo",
            },
            {
              render(original) {
                return (
                  <IconButton
                    onClick={async () => {
                      const { isConfirmed } = await showConfirm();

                      if (isConfirmed) {
                        const newValue = value.filter(
                          (v: any) => v.id !== original.id
                        );

                        console.log("new", newValue);
                        onChange(newValue);
                      }
                    }}
                    icon={<Icon icon="trash-o" />}
                  />
                );
              },
            },
          ]}
          dataSource={value ?? []}
        />
      ) : null}
    </div>
  );
}

export default Modulos;
