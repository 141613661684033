import React from "react";
import colors from "../../../../../../../../infra/resources/theme/colors";
import { Button, Icon } from "rsuite";

function EmptyList({ onClick }: any) {
  return (
    <div
      style={{
        backgroundColor: colors.black100,
        borderRadius: 4,
        padding: 32,
        textAlign: "center",
      }}
    >
      <span style={{ fontSize: 16 }}>
        Nenhum Módulo foi adicionado ao Curso
      </span>

      <div style={{ marginTop: 16 }}>
        <Button onClick={onClick} color="red" style={{ borderRadius: 18 }}>
          <Icon style={{ color: "white" }} icon="plus" />
        </Button>
      </div>
    </div>
  );
}

export default EmptyList;
