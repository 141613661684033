import React, { useEffect, useState } from "react";
import http from "infra/axios";
import SelectBase from "../SelectBase";

function SelectCidade({ ...props }) {
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!props.estado_id) {
        return;
      }
      try {
        setIsLoading(true);

        const { data } = await http.get("api/cidade", {
          params: {
            estado_id: props.estado_id,
          },
        });

        setItems(
          data.data.map((item: any) => ({
            label: item.nome,
            value: Number(item.id),
          }))
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [props.estado_id]);

  const value: any = props.value
    ? items.find((x) => x.value === props.value)
    : null;

  return (
    <SelectBase
      {...props}
      options={items}
      value={value}
      isMulti={false}
      isLoading={isLoading}
      beforeChange={(v: any) => {
        return v;
      }}
    />
  );
}

export default SelectCidade;
