import React from "react";
import { Controller } from "react-hook-form";
import { Col, ControlLabel, FormControl, Row, Table } from "rsuite";

import InputCpfCnpj from "components/Input/InputCpfCnpj";
import SelectTipoEntidade from "components/Input/SelectTipoEntidade";
import SelectSituacaoTenant from "components/Input/SelectSituacaoTenant";

import colors from "infra/resources/theme/colors";
import styled from "styled-components";
import SelectEstado from "ui/components/Input/SelectEstado";
import InputCep from "ui/components/Input/InputCep";
import SelectCidade from "ui/components/Input/SelectCidade";
import InputPhone from "ui/components/Input/InputPhone";

import moment from "moment";

const Title = styled.h4`
  color: ${colors.primary};
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

function InformacoesBasicas({ control, setValue, watch }: any) {
  return (
    <div>
      <Title>ENTIDADE</Title>
      <Row style={{ marginBottom: 12 }} gutter={16}>
        <Col sm={6}>
          <ControlLabel>Tipo de entidade</ControlLabel>
          <Controller
            as={SelectTipoEntidade}
            control={control}
            name="perfil_acesso"
          />
        </Col>
        <Col sm={6}>
          <ControlLabel>CNPJ/CPF</ControlLabel>
          <Controller as={InputCpfCnpj} control={control} name="cnpj" />
        </Col>
        <Col sm={6}>
          <ControlLabel>Código ANAC</ControlLabel>
          <Controller as={FormControl} control={control} name="codigo_anac" />
        </Col>

        <Col sm={6}>
          <ControlLabel>Situação</ControlLabel>
          <Controller
            as={SelectSituacaoTenant}
            placeholder="Selecione"
            control={control}
            name="situacao"
            rules={{
              required: {
                value: true,
                message: "Informe a situação da entidade",
              },
            }}
          />
        </Col>
      </Row>

      <br />

      <Row style={{ marginBottom: 12 }} gutter={16}>
        <Col sm={12}>
          <ControlLabel>Nome Fantasia</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Nome Fantasia"
            control={control}
            name="nome_fantasia"
          />
        </Col>

        <Col sm={12}>
          <ControlLabel>Razão Social</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Razão Social"
            control={control}
            name="nome"
            rules={{
              required: {
                value: true,
                message: "Informe a Razão Social da entidade",
              },
            }}
          />
        </Col>
      </Row>

      <br />
      <Row style={{ marginBottom: 12 }} gutter={16}>
        <Col sm={6}>
          <ControlLabel>Cep</ControlLabel>
          <Controller
            as={InputCep}
            placeholder="CEP"
            control={control}
            name="endereco.cep"
            onLoadCep={(data: any) => {
              if (data) {
                setValue("endereco.estado.id", data.estado_id);
                setValue("endereco.cidade_id", data.cidade_id);
                setValue("endereco.logradouro", data.logradouro);
                setValue("endereco.complemento", data.complemento);
                setValue("endereco.bairro", data.bairro);
              }
            }}
          />
        </Col>
        <Col sm={10}>
          <ControlLabel>Endereço</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Endereço"
            control={control}
            name="endereco.logradouro"
          />
        </Col>

        <Col sm={8}>
          <ControlLabel>Bairro</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Bairro"
            control={control}
            name="endereco.bairro"
          />
        </Col>
      </Row>

      <br />

      <Row style={{ marginBottom: 12 }} gutter={16}>
        <Col sm={6}>
          <ControlLabel>Estado</ControlLabel>
          <Controller
            as={SelectEstado}
            placeholder="Estado"
            control={control}
            name="endereco.estado.id"
          />
        </Col>

        <Col sm={12}>
          <ControlLabel>Cidade</ControlLabel>
          <Controller
            as={SelectCidade}
            estado_id={watch("endereco.estado.id")}
            placeholder="Cidade"
            control={control}
            name="endereco.cidade_id"
          />
        </Col>

        <Col sm={6}>
          <ControlLabel>Telefone</ControlLabel>
          <Controller
            as={InputPhone}
            placeholder="Telefone"
            control={control}
            country="br"
            name="contato"
          />
        </Col>
      </Row>

      <br />

      <Row gutter={16} style={{ marginBottom: 12 }}>
        <Col sm={12}>
          <ControlLabel>Email</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Email"
            control={control}
            name="email"
          />
        </Col>

        <Col sm={12}>
          <ControlLabel>Site</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Site"
            control={control}
            name="site"
          />
        </Col>
      </Row>

      <br />

      <Title>DADOS DA ADMINISTRAÇÃO</Title>

      <Row gutter={16} style={{ marginBottom: 12 }}>
        <Col sm={12}>
          <ControlLabel>Nome do administrador</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Nome do administrador"
            control={control}
            name="administrador.pessoa.nome"
          />
        </Col>

        <Col sm={12}>
          <ControlLabel>Email do administrador</ControlLabel>
          <Controller
            as={FormControl}
            placeholder="Email do administrador"
            control={control}
            name="administrador.email"
          />
        </Col>
      </Row>

      <br />

      <h5>CURSOS</h5>

      <br />

      <Table height={500} data={watch("cursos") ?? []}>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>NOME</Table.HeaderCell>
          <Table.Cell dataKey="nome" />
        </Table.Column>
        <Table.Column width={250}>
          <Table.HeaderCell>TIPO</Table.HeaderCell>
          <Table.Cell dataKey="tipo" />
        </Table.Column>
        <Table.Column width={250}>
          <Table.HeaderCell>SITUAÇÃO</Table.HeaderCell>
          <Table.Cell>
            {(rowData: any) => {
              return (
                <span>
                  Vence em {moment(rowData.data_validade).format("DD/MM/YYYY")}
                </span>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </div>
  );
}

export default InformacoesBasicas;
