import { Icon, IconButton, Tag } from "rsuite";
import { useCallback, useState, useMemo, useEffect, useContext } from "react";

import useCursos from "data/curso/useCursos";
import useCursoExcluir from "data/curso/useCursoExcluir";

import history from "infra/history";
import { showConfirm } from "infra/alerta";
import colors from "infra/resources/theme/colors";

import Table from "components/Table";
import PageHeader from "components/Layout/PageHeader";

import CursoItem from "./components/CursoItem";
import HeaderRight from "./components/HeaderRight";
import HeaderTable from "./components/HeaderTable";
import ModalFiltros from "./components/ModalFiltros";

import PERMISSOES from "infra/resources/enums/permissoes";
import { AuthContext } from "contexts/AuthContext";

function getColorSituacao(situacao: string) {
  if (situacao === "Ativo") {
    return {
      color: colors.green,
      bg: colors.green100,
    };
  }

  if (situacao === "Inativo") {
    return {
      color: colors.error,
      bg: colors.errorLight,
    };
  }

  if (situacao === "Erro") {
    return {
      color: colors.warning,
      bg: colors.warningLight,
    };
  }

  if (situacao === "Novo") {
    return {
      color: colors.blue,
      bg: colors.blue200,
    };
  }
}

function CursoListagemPage() {
  const { isLoading, data, params, setParams } = useCursos();
  const { isLoading: isLoadingExcluir, handleExcluir } = useCursoExcluir();

  const [openFiltro, setOpenFiltro] = useState(false);
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const permissions = useMemo(
    () => [
      PERMISSOES.CURSO.ALTERAR,
      PERMISSOES.CURSO.EXCLUIR,
      PERMISSOES.CURSO.ADICIONAR,
    ],
    []
  );
  const [currentPermissions, setCurrentPermissions] = useState<string[]>([]);

  const { state } = useContext(AuthContext);

  useEffect(() => {
    permissions.map((permission) => {
      return setCurrentPermissions((current) => [...current, permission]);
    });
  }, [state, permissions]);

  const handleSincronizacao = useCallback(() => {
    setIsLoadingSync(true);
    setTimeout(() => {
      setIsLoadingSync(false);
    }, 3000);
  }, []);

  function generateFilteredResults(query: any) {
    setParams((prev: any) => {
    return { ...prev, ...query, page: 1 };
    });
    setOpenFiltro(false);
  }

  const columns = useMemo(
    () => [
      {
        Header: "CURSOS",
        accessor: "nome",
        Cell: ({ row }: any) => <CursoItem item={row.original} />,
      },
      {
        Header: "",
        accessor: "situacao",
        Cell: ({ row: { original } }) => {
          const color = getColorSituacao(original.situacao);

          if (!color) {
            return null;
          }

          return (
            <Tag
              style={{
                backgroundColor: color.bg,
                color: color.color,
              }}
            >
              {original.situacao}
            </Tag>
          );
        },
      },
      {
        accessor: "id",
        Cell: ({ row: { original } }: any) => {
          return (
            <div>
              {currentPermissions.includes(PERMISSOES.CURSO.ALTERAR) && (
                <IconButton
                  onClick={() => {
                    history.push({
                      pathname: `/admin/administracao/cursos/${original.uuid}`,
                      search: window.location.search,
                    });
                  }}
                  style={{ marginRight: 16 }}
                  icon={<Icon icon="edit" />}
                />
              )}

              {currentPermissions.includes(PERMISSOES.CURSO.EXCLUIR) && (
                <IconButton
                  onClick={() => {
                    showConfirm().then(async ({ isConfirmed }) => {
                      if (isConfirmed) {
                        const res = await handleExcluir(original.uuid);

                        if (res) {
                          setParams((prev: any) => ({
                            ...prev,
                            updated_at: +new Date(),
                          }));
                        }
                      }
                    });
                  }}
                  icon={<Icon icon="trash-o" />}
                />
              )}
            </div>
          );
        },
      },
    ],
    [handleExcluir, setParams, currentPermissions]
  );

  return (
    <div>
      <ModalFiltros
        open={openFiltro}
        value={params}
        onClose={() => setOpenFiltro(false)}
        onFilter={(q: any) => generateFilteredResults(q)}
      />

      <PageHeader>
        <PageHeader.Left
          title="Cursos"
          icon={<i className="mdi mdi-2x mdi-book-variant-multiple" />}
        />

        <PageHeader.Right>
          <HeaderRight
            isLoading={isLoadingSync}
            handleSincronizacao={handleSincronizacao}
          />
        </PageHeader.Right>
      </PageHeader>

      <div>
        <br />
        <br />
        <Table
          loading={isLoading || isLoadingExcluir}
          header={() => (
            <HeaderTable
              onOpenFilter={() => setOpenFiltro(true)}
              value={params.query}
              onSearch={(v: string) =>
                setParams((prev: any) => ({
                  ...prev,
                  query: v,
                  page: 1,
                }))
              }
              permissions={currentPermissions}
            />
          )}
          columns={columns}
          data={data?.data ?? []}
          total={data?.total || 0}
          page={params?.page || 0}
          perPage={params?.per_page || 0}
          onChange={(values: any) =>
            setParams((prev: any) => ({
              ...prev,
              page: values.page,
              per_page: values.per_page,
            }))
          }
        />
        <br />
        <br />
      </div>
    </div>
  );
}

export default CursoListagemPage;
