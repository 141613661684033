import React, { useEffect, useState } from "react";
import SelectBase from "../SelectBase";
import http from "infra/axios";
let oldText: any = null;

function SelectModalidade({ ...props }) {
  const [search, setSearch] = useState<string>();
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (oldText === search) {
          return;
        } else {
          oldText = search;
        }

        setIsLoading(true);

        const { data } = await http.get("api/modalidade", {
          params: {
            nome: search,
            per_page: 100,
          },
        });

        setItems(
          data.data.data.map((item: any) => ({
            label: item.nome,
            value: Number(item.id),
          }))
        );
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [search]);

  const value: any = props.value
    ? items.find((x) => x.value === props.value)
    : null;

  return (
    <SelectBase
      {...props}
      options={items}
      value={value}
      inputValue={search}
      isMulti={false}
      isLoading={isLoading}
      onInputChange={(input: string) => setSearch(input)}
    />
  );
}

export default SelectModalidade;
