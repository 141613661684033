import useTurmaExcluir from "data/turma/useTurmaExcluir";

import useTurmas from "data/turma/useTurmas";
import { Button, Flex, Progress, Tag, Text } from "@chakra-ui/react";
import { Tag as TagRSuite } from "rsuite";
import { TurmaResponse } from "data/turma/typings";
import colors from "infra/resources/theme/colors";
import moment from "moment";
import { MdAccountCircle, MdDeleteOutline, MdEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { showConfirm } from "infra/alerta";

function TurmaItem({ original }: { original: TurmaResponse }) {
  const { setParams } = useTurmas();
  const { isLoading: isLoadingExcluir, handleExcluir } = useTurmaExcluir();

  const history = useHistory();

  const getTheme = (original: TurmaResponse) => {
    if (original.data_cancelamento) {
      return {
        light: colors.red100,
        dark: colors.red500,
      };
    }

    if (
      moment(original.data_inicio).isBefore(moment()) &&
      moment(original.data_fim).isAfter(moment())
    ) {
      return {
        light: colors.blue100,
        dark: colors.blue500,
      };
    }

    return {
      light: colors.black100,
      dark: colors.black700,
    };
  };

  const theme = getTheme(original);

  const isProgress =
    moment(original.data_inicio).isBefore(moment()) &&
    moment(original.data_fim).isAfter(moment());

  return (
    <Flex flexDir={"column"} gap="16px">
      <Flex>
        <Flex flex={1} fontSize="1.2em" fontWeight={"semibold"}>
          {original.curso_apelido} |{" "}
          {moment(original.data_inicio).format("MM[.]YYYY")}
        </Flex>
        <Flex gap="16px">
          <Tag
            bg="gray.600"
            color="white"
            rounded="full"
            fontSize="1.2em"
            px="16px"
          >
            {original.categoria}
          </Tag>
          <Flex
            bg="#F5F5F5"
            color="gray.600"
            rounded="full"
            boxShadow="0 0 4px rgba(0,0,0,.3)"
            fontSize="1.2em"
            gap="8px"
            alignItems={"center"}
            px="8px"
          >
            <MdAccountCircle /> <Text>{original.total_aluno}</Text>
          </Flex>

          {moment(original.data_fim).isAfter(moment()) ? (
            <TagRSuite
              style={{
                backgroundColor: theme.light,
                color: theme.dark,
                fontSize: "1.1em",
              }}
            >
              <Flex gap="8px" flex={1} alignItems="center">
                <Flex w="8px" h="8px" rounded="full" bg={theme.dark} />

                {original.data_cancelamento
                  ? "Cancelado"
                  : moment(original.data_inicio).isBefore(moment())
                  ? "Em Andamento"
                  : "Finalizado"}
              </Flex>
            </TagRSuite>
          ) : null}

          <Button
            onClick={() =>
              history.push(`/admin/entidade/turmas/${original.uuid}`)
            }
          >
            <MdEdit fontSize="16px" />
          </Button>

          <Button
            isLoading={isLoadingExcluir}
            onClick={() => {
              showConfirm().then(async ({ isConfirmed }) => {
                if (isConfirmed) {
                  const res = await handleExcluir(original.uuid);

                  if (res) {
                    setParams((prev: any) => ({
                      ...prev,
                      updated_at: +new Date(),
                    }));
                  }
                }
              });
            }}
          >
            <MdDeleteOutline fontSize="16px" />
          </Button>
        </Flex>
      </Flex>

      <Flex>
        <Text color="gray.500" fontSize="1.2em">
          {original.nome}
        </Text>
      </Flex>

      <Flex>
        <Flex flex={1}>
          <Flex color={colors.primary} fontWeight={"semibold"}>{original.curso}</Flex>
          <Flex borderLeft="1px solid #333" pl="6px" fontWeight={"semibold"}>
            {original.curso_tipo}
          </Flex>
        </Flex>
        <Flex gap="16px" alignItems="center">
          <Text>Progresso da Turma</Text>
          <Progress
            w="400px"
            rounded="4px"
            colorScheme={
              original.data_cancelamento ? "red" : isProgress ? "blue" : "gray"
            }
            value={30}
            max={100}
          />
          <Text>30%</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TurmaItem;
