import React, { useState, useMemo } from "react";
import { Icon, IconButton } from "rsuite";

import PageHeader from "ui/components/Layout/PageHeader";
import Table from "ui/components/Table";
import useCategorias from "data/categoria/useCategorias";

import HeaderTable from "./components/HeaderTable";
import ModalCategoria from "./components/ModalCategoria";
import { showConfirm } from "infra/alerta";

function CategoriasListagemPage() {
  const {
    isLoading,
    data,
    params,
    setParams,
    handleSave,
    isLoadingForm,
    isLoadingExcluir,
    handleExcluir,
  } = useCategorias();

  const [openCadastro, setOpenCadastro] = useState(false);
  const [indexEdit, setIndexEdit] = useState<Number | null>(null);

  const columns = useMemo(
    () => [
      {
        Header: "Categoria",
        accessor: "nome",
      },
      {
        accessor: "id",
        Cell: ({ row: { index, original } }: any) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setIndexEdit(index);
                  setOpenCadastro(true);
                }}
                style={{ marginRight: 16 }}
                icon={<Icon icon="edit" />}
              />
              <IconButton
                onClick={() => {
                  showConfirm().then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      return handleExcluir(original.id);
                    }
                  });
                }}
                icon={<Icon icon="trash-o" />}
              />
            </div>
          );
        },
      },
    ],
    [handleExcluir]
  );

  return (
    <div>
      <ModalCategoria
        value={indexEdit !== null ? data.data[indexEdit as any] : null}
        open={openCadastro}
        isLoading={isLoading || isLoadingForm}
        onClose={() => setOpenCadastro(false)}
        onSave={async (v: any) => {
          const result = await handleSave(v);
          if (result) {
            setOpenCadastro(false);
            setIndexEdit(null);
          }
        }}
      />

      <PageHeader>
        <PageHeader.Left
          title="Categorias"
          icon={<i className="mdi mdi-2x mdi-tag" />}
        />
      </PageHeader>

      <div>
        <br />
        <br />
        <Table
          loading={isLoading || isLoadingExcluir}
          header={() => (
            <HeaderTable
              value={params.nome}
              onSearch={(v: string) =>
                setParams((prev: any) => ({
                  ...prev,
                  nome: v,
                  page: 1,
                }))
              }
              onOpenModal={() => setOpenCadastro(true)}
            />
          )}
          columns={columns}
          data={data?.data ?? []}
          total={data?.total || 0}
          page={params?.page || 0}
          perPage={params?.per_page ?? 0}
          onChange={(values: any) =>
            setParams((prev: any) => ({
              ...prev,
              page: values.page,
              per_page: values.per_page,
            }))
          }
        />
        <br />
        <br />
      </div>
    </div>
  );
}

export default CategoriasListagemPage;
