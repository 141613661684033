import styled from "styled-components";
import colors from "../../../../infra/resources/theme/colors";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const ContainerClean = styled.div`
  display: flex;
  flex: 0.6;
  background-color: ${colors.primary};
  background-image: url("https://preview.keenthemes.com/metronic/theme/html/demo5/dist/assets/media/svg/illustrations/login-visual-2.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
  align-items: start;
  justify-content: center;

  img {
    max-width: 120px;
    margin-top: 64px;
  }
`;

export const ContainerLogin = styled.div`
  flex: 1;
  display: flex;
`;

export const CardLogin = styled.div`
  max-width: 400px;
  width: 100%;
  margin: auto;

  .rs-input {
    height: 50px;
  }
`;
