import { useEffect, useMemo, useState } from "react";
import { Button, Form } from "rsuite";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Alert } from "antd";
import cloneDeep from "lodash.clonedeep";

import PageContainer from "ui/components/Layout/PageContainer";
import PageHeader from "ui/components/Layout/PageHeader";
import Tabs from "ui/components/Tabs";

import InformacoesBasicas from "./components/InformacoesBasicas";
import Questoes from "./components/Questoes";
import { ProblemaForm } from "data/problema/types";
import useProblema from "data/problema/useProblema";

function ProblemaFormularioPage() {
  const history = useHistory();
  const params = useParams<any>();

  const [indexTab, setIndexTab] = useState(0);
  const { handleSave, data, isLoading } = useProblema(params.id);

  const { control, handleSubmit, reset, watch, errors } = useForm<ProblemaForm>(
    {
      defaultValues: {
        arquivos: [],
      },
    }
  );

  const form = watch();

  console.log("form", form);

  useEffect(() => {
    (() => {
      if (data) {
        reset(cloneDeep({ ...data }));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const listErrors = useMemo(() => {
    const response = [];

    for (const prop in errors) {
      response.push((errors as any)[prop].message);
    }

    return response;
  }, [errors]);

  return (
    <div style={{ marginBottom: 100 }}>
      <Form onSubmit={(_v, ev: any) => handleSubmit(handleSave)(ev)}>
        <PageHeader>
          <PageHeader.Left
            title="Problemas"
            onClickTitle={() => {
              history.push({
                pathname: `/admin/administracao/problemas`,
                search: window.location.search,
              });
            }}
            subtitle={`${
              params.id
                ? `Editar ${watch("titulo")}`
                : "Adicionar Novo Problema"
            }`}
            icon={<i className="mdi mdi-forum" />}
          />
          <PageHeader.Right>
            <div>
              <Button
                color="blue"
                disabled={isLoading}
                appearance="ghost"
                componentClass="a"
                style={{ marginRight: 16 }}
                onClick={() => {
                  history.push({
                    pathname: "/admin/administracao/problemas",
                    search: window.location.search,
                  });
                }}
              >
                CANCELAR
              </Button>
              <Button
                type="submit"
                disabled={isLoading}
                loading={isLoading}
                color="blue"
              >
                SALVAR
              </Button>
            </div>
          </PageHeader.Right>
        </PageHeader>

        {listErrors.length ? (
          <Alert
            message="Avisos"
            description={
              <div>
                {listErrors.map((error) => (
                  <div>{error}</div>
                ))}
              </div>
            }
            type="error"
            closable
          />
        ) : null}

        <PageContainer style={{ paddingTop: 16 }}>
          <Tabs index={indexTab} onChange={setIndexTab}>
            <Tabs.TabItem
              title="Informações Básicas"
              component={<InformacoesBasicas control={control} />}
            />

            <Tabs.TabItem
              title="Questões Vinculadas"
              component={
                <Controller name="questoes" as={Questoes} control={control} />
              }
            />
          </Tabs>
        </PageContainer>
      </Form>
    </div>
  );
}

export default ProblemaFormularioPage;
