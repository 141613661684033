import PERMISSOES from "infra/resources/enums/permissoes";
import { Switch } from "react-router-dom";
import RouteAuthorize from "ui/components/RouteAuthorize";
import UsuarioListagemPage from "./Listagem";

function UsuariosPages() {
  return (
    <Switch>
      <RouteAuthorize
        permissoes={[PERMISSOES.USUARIO.VISUALIZAR]}
        path="/admin/configuracao/usuarios"
        component={UsuarioListagemPage}
      />
    </Switch>
  );
}

export default UsuariosPages;
