import { useState } from "react";
import { Col, Row } from "rsuite";

import { showConfirm, showError } from "infra/alerta";

import EmptyList from "./EmptyList";
import ModalModulo from "./ModalModulo";
import ModuloAssuntos from "./ModuloAssuntos";

import SortableList3 from "ui/components/SortableList3";
import { SortableItem } from "ui/components/SortableItem";
import { MdDeleteOutline, MdEdit } from "react-icons/md";

function Modulos({ value, onChange }: any) {
  const [selected, setSelected] = useState<any>();
  const [openModalModulo, setOpenModalModulo] = useState(false);

  return (
    <div>
      <ModalModulo
        open={openModalModulo}
        value={selected}
        onClose={() => setOpenModalModulo(false)}
        onDelete={(v: any) => {
          if (v.id) {
            value = value.filter((item: any) => item.id !== v.id);

            onChange([...value]);
          }

          setOpenModalModulo(false);
        }}
        onSave={(v: any) => {
          if (!v.nome) {
            showError("Informe o nome do módulo");
            return;
          }

          if (v.id) {
            value.forEach((item: any) => {
              if (item.id === v.id) {
                item.nome = v.nome;
              }
            });

            onChange([...value]);
          } else {
            const items = value ?? [];

            onChange([
              ...items,
              { ...v, id: +new Date(), new: true, ordem: items.length },
            ]);
          }

          setOpenModalModulo(false);
        }}
      />

      <Row gutter={32}>
        <Col sm={12} style={{ borderRight: "1px solid #eee" }}>
          {!value?.length ? (
            <EmptyList onClick={() => setOpenModalModulo(true)} />
          ) : null}

          <SortableList3
            value={value ?? []}
            onChange={onChange}
            renderItem={(item: any, index: number) => (
              <div
                style={{
                  marginBottom: 16,
                  borderStyle: "solid",
                  borderRadius: 7,
                }}
                onClick={() => {
                  if (item.id === selected?.id) {
                    setSelected(null);
                  } else {
                    setSelected(item);
                  }
                }}
              >
                <SortableItem
                  id={item.id}
                  selected={selected?.id === item.id}
                  index={index}
                  title={`MÓDULO ${index + 1}`}
                  subtitle={item?.nome}
                  menus={[
                    {
                      title: "Editar Módulo",
                      icon: <MdEdit />,
                      onClick: () => {
                        setSelected(item);
                        setOpenModalModulo(true);
                      },
                    },
                    {
                      title: "Excluir Módulo",
                      icon: <MdDeleteOutline />,
                      onClick: () => {
                        setSelected(item);

                        showConfirm("Deseja realmente excluir ?").then(
                          (result) => {
                            if (result.isConfirmed) {
                              setSelected(null);
                              onChange([
                                ...value.filter(
                                  (_item: any) => _item.id !== item.id
                                ),
                              ]);
                            }
                          }
                        );
                      },
                    },
                  ]}
                />
              </div>
            )}
          />
        </Col>

        <Col sm={12}>
          <ModuloAssuntos
            modulo={selected}
            value={selected?.assuntos ?? []}
            onChange={(newAssuntos: any[]) => {
              value?.forEach((item: any) => {
                if (item.id === selected.id) {
                  item.assuntos = newAssuntos;
                }
              });

              onChange([...value]);
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Modulos;
