import React, { useState, useEffect } from "react";
import axios from "infra/axios";
import { Tree, Spin } from "antd";

function SelectPermissao({ value, onChange, error }: any) {
  const [isLoading, setIsLoading] = useState(false);

  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const { data } = await axios.get("api/permissao");

        setTreeData(data.data);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <>
      {isLoading ? <Spin /> : null}

      <Tree
        checkable
        onCheck={onChange}
        checkedKeys={value}
        // onSelect={onChange}
        treeData={treeData}
      />
    </>
  );
}

export default SelectPermissao;
