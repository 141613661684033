export function getDayName(dayNumber: number) {
  if (dayNumber === 0) {
    return "Domingo";
  }
  if (dayNumber === 1) {
    return "Segunda-Feira";
  }
  if (dayNumber === 2) {
    return "Terça-Feira";
  }
  if (dayNumber === 3) {
    return "Quarta-feira";
  }
  if (dayNumber === 4) {
    return "Quinta-feira";
  }
  if (dayNumber === 5) {
    return "Sexta-feira";
  }
  if (dayNumber === 6) {
    return "Sábado";
  }
}
