import Axios, { AxiosError } from "axios";
import AuthClient from "data/auth/authClient";
import { BASE_URL_API } from "../config";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const http = Axios.create({
  baseURL: BASE_URL_API,
});

const refreshAuthLogic = async (failedRequest: AxiosError) => {
  try {
    await AuthClient.refreshToken();

    // if (failedRequest?.response?.config?.headers) {
    //   failedRequest.response.config.headers["Authorization"] =
    //     "Bearer " + data.access_token;
    // }
  } catch (error) {
    await AuthClient.revoke();
    window.location.reload();
  } finally {
  }

  return Promise.resolve();
};

createAuthRefreshInterceptor(http, refreshAuthLogic);

http.interceptors.request.use((config) => {
  if (AuthClient.getAccessToken()) {
    config.headers["authorization"] = "Bearer " + AuthClient.getAccessToken();
  }
  return config;
});

export default http;
