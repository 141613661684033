import { useEffect, useState } from "react";
import axios from "infra/axios";
import { parse } from "query-string";
import { useQueryParams } from "use-query-params";

export default function useProblemas(
  defaultParams = {},
  ignoreQueryString = false
) {
  const [data, setData] = useState<any>(null);

  const [params, setParams] = useState<any>(() => {
    if (ignoreQueryString) {
      return {
        ...defaultParams,
      };
    }

    const p: any = parse(window.location.search);

    return {
      ...p,
      ...defaultParams,
      page: Number(p.page) || 1,
      per_page: Number(p.per_page) || 15,
    };
  });

  const [, setQuery] = useQueryParams({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      let filtros = { ...params };
      if (filtros.situacao && !Array.isArray(filtros.situacao)) {
        const sArr = [];
        sArr.push(filtros.situacao);
        filtros.situacao = sArr;
      }
      try {
        const { data } = await axios.get("/api/problema", {
          params: filtros,
        });

        setData(data.data);

        if (!ignoreQueryString) {
          setQuery(filtros);
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
      // TODO ...
    })();
  }, [params, ignoreQueryString, setQuery]);

  return {
    isLoading,
    data,
    params,
    setParams,
  };
}
