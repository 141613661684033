import { Spin } from "antd";
import React, { useCallback, useMemo } from "react";
import { Collapse } from "react-collapse";
import { Checkbox, Tag } from "rsuite";
import colors from "../../../infra/resources/theme/colors";
import {
  ListItem,
  Coluna,
  Linha,
  ContainerScroll,
  ContainerScrollInner,
  Title,
} from "./styles";

const TOTAL_ROWS = 5;
const SIZE_COL = 450;

function ContentScroll({
  title,
  openTitle,
  onCloseTitle,
  data,
  onChange,
  value,
  isLoading,
  countCheckeds,
}: any) {
  const result = [];

  let temp = [];

  for (const item of data) {
    temp.push(item);

    if (temp.length === TOTAL_ROWS) {
      result.push(temp);
      temp = [];
    }
  }

  if (temp.length) {
    result.push(temp);
  }

  const isChecked = useMemo(
    () => (item: any) => {
      return value?.find((x: any) => x.id === item.id);
    },
    [value]
  );

  const check = useCallback(
    (item: any) => {
      if (isChecked(item)) {
        onChange([...value.filter((x: any) => x.id !== item.id)]);
      } else {
        onChange([...value, item]);
      }
    },
    [isChecked, value, onChange]
  );

  return (
    <>
      {title ? (
        <Title open={openTitle} onClick={() => onCloseTitle()}>
          {title}

          {countCheckeds ? (
            <Tag
              style={{
                backgroundColor: colors.primary,
                color: "white",
                borderRadius: 12,
                marginLeft: 16,
                top: -2,
                position: "relative",
              }}
            >
              {countCheckeds}
            </Tag>
          ) : null}
        </Title>
      ) : null}

      <Collapse isOpened={!title || openTitle}>
        <ContainerScroll>
          <ContainerScrollInner>
            {isLoading ? <Spin /> : null}
            <Linha width={SIZE_COL * result.length}>
              {result.map((value: any) => (
                <Coluna>
                  {value.map((item: any) => (
                    <ListItem>
                      <span style={{ paddingTop: 10 }}>
                        <Checkbox
                          onChange={() => {
                            check(item);
                          }}
                          checked={isChecked(item)}
                        />
                      </span>
                      <div
                        style={{
                          flex: 1,
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                        onClick={() => check(item)}
                      >
                        {item.nome}
                      </div>
                    </ListItem>
                  ))}
                </Coluna>
              ))}
            </Linha>
          </ContainerScrollInner>
        </ContainerScroll>
      </Collapse>
    </>
  );
}

export default ContentScroll;
